import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Login from '/src/views/Login.vue'
import Signup from '/src/views/Signup.vue'
import Account from '/src/views/Account.vue'
import Distributions from '/src/views/Distributions.vue'
import Messages from '/src/views/Messages.vue'
import MessageCreate from '/src/views/MessageCreate.vue'
import MessagesEdit from '/src/views/MessagesEdit.vue'
import GoogleApi from '/src/views/GoogleApi.vue'
import MessageEdit from '/src/views/MessageEdit.vue'
import AccountMessages from '/src/views/AccountMessages.vue'
import AccountMessage from '/src/views/AccountMessage.vue'
import AccountTopup from '/src/views/AccountTopup.vue'
import FAQ from '/src/views/Help/FAQ.vue'
import Feedback from '/src/views/Help/Feedback.vue'
import Icons from '/src/views/Help/Icons.vue'
import Attachment from '/src/views/Help/Attachment.vue'
import AuthAction from '/src/views/AuthAction.vue'
import Receiver from '/src/views/Receiver.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/authAction',
    name: 'AuthAction',
    component: AuthAction
  },
  {
    path: '/receiver',
    name: 'Receiver',
    component: Receiver
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/attachment',
    name: 'Attachment',
    component: Attachment
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback
  },
  {
    path: '/icons',
    name: 'Icons',
    component: Icons
  },
  {
    path: '/account',
    name: 'Account',
    component: Account
  },
  {
    path: '/account/messages',
    name: 'AccountMessages',
    component: AccountMessages
  },
  {
    path: '/account/distributions',
    name: 'AccountDistributions',
    component: Distributions
  },
  {
    path: '/account/message/:id',
    name: 'AccountMessage',
    component: AccountMessage
  },
  {
    path: '/account/topup',
    name: 'AccountTopup',
    component: AccountTopup
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages
  },
  {
    path: '/message/create',
    name: 'MessageCreate',
    component: MessageCreate
  },
  {
    path: '/messagesedit',
    name: 'MessagesEdit',
    component: MessagesEdit
  },
  {
    path: '/message/:id',
    name: 'MessageEdit',
    component: MessageEdit
  },
  {
    path: '/GoogleApi',
    name: 'GoogleApi',
    component: GoogleApi
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const isAuth = localStorage.getItem('sendamessage_email')

  if (isAuth) {
    if (window.location.search && window.location.search.includes('reminder')) {
      const urlParams = new URLSearchParams(window.location.search)
      const transactionId = urlParams.get('reminder')

      await store.dispatch('transactions/get')

      // Function to check if transactions data is available
      const checkTransactionsData = () => {
        if (store.state.transactions.data) {
          const transactions = store.state.transactions.data
          const transaction = transactions.find(it => it.id === transactionId)

          if (!transaction) {
            store.dispatch('user/signout')
          } else {
            const url = new URL(window.location.href)
            url.searchParams.delete('reminder')
            return window.location.href = url.toString()
          }
        } else {
          setTimeout(checkTransactionsData, 100)
        }
      }

      // Start the check loop
      checkTransactionsData()
    }

    if (to.name === 'Login' || to.name === 'Signup') {
      return next({ name: 'Messages' })
    }

    return next()
  }

  if (to.name === 'Login' || to.name === 'Signup' || to.name === 'AuthAction' || to.name === 'Receiver') {
    return next()
  }

  return next({ name: 'Login' })
})

export default router

<template>
  <div class="messages">
    <strong class="text-primary">Undelivered Messages</strong>
    <div class="d-flex justify-content-between my-3">
      <router-link
        class="btn btn-primary d-flex align-items-center gap-2 rounded-pill fs-12 text-white py-1 justify-content-center"
        :to="{ name: 'MessageCreate' }"
      >
        <svg fill="currentColor" width="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M64 96c-17.7 0-32 14.3-32 32c0 13.3 0 26.6 0 39.9c65.2 47.8 130.4 95.6 195.6 143.4c16.9 12.4 39.9 12.4 56.8 0c25.5-18.7 51-37.4 76.5-56.1c-15.6 18.7-27.4 40.7-34.2 64.8c-7.8 5.7-15.5 11.4-23.3 17.1c-28.2 20.6-66.5 20.6-94.6 0C149.8 293.9 90.9 250.8 32 207.6c0 58.8 0 117.6 0 176.4c0 17.7 14.3 32 32 32c87.6 0 175.1 0 262.6 0c3.2 11.2 7.4 21.9 12.6 32c-91.8 0-183.5 0-275.2 0c-35.3 0-64-28.7-64-64c0-85.4 0-170.7 0-256C0 92.7 28.7 64 64 64c128 0 256 0 384 0c35.3 0 64 28.7 64 64c0 21.6 0 43.1 0 64.7c-5.3-.5-10.6-.7-16-.7c-23.6 0-46.2 4.7-66.7 13.1c16.9-12.4 33.8-24.8 50.7-37.2c0-13.3 0-26.6 0-39.9c0-17.7-14.3-32-32-32c-128 0-256 0-384 0zM496 288c8.8 0 16 7.2 16 16c0 16 0 32 0 48c16 0 32 0 48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16c-16 0-32 0-48 0c0 16 0 32 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-16 0-32 0-48c-16 0-32 0-48 0c-8.8 0-16-7.2-16-16s7.2-16 16-16c16 0 32 0 48 0c0-16 0-32 0-48c0-8.8 7.2-16 16-16zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zM496 480c61.9 0 112-50.1 112-112s-50.1-112-112-112s-112 50.1-112 112s50.1 112 112 112z"/></svg>
        NEW MESSAGE
      </router-link>
    </div>
    <LoadingScreen v-if="!transactions" />
    <template v-else>
      <MessageList :key="transactions" :transactions="transactions" isFilteredSequence />
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import MessageList from '@/components/MessageList.vue'

const store = useStore()
const transactions = computed(() => {
  const trans = store.state.transactions.incompleteTransactions

  return trans && trans.sort((a, b) => {
    return new Date(b.updatedAt) - new Date(a.updatedAt)
  }).slice(0, 20)
})
</script>

import {
  getDatabase,
  ref,
  onValue
} from 'firebase/database'

const db = getDatabase()
const dbRef = ref(db, 'topups')

export default {
  namespaced: true,
  state: () => ({
    data: null
  }),
  getters: {},
  mutations: {
    SET_DATA (state, payload) {
      state.data = payload
    }
  },
  actions: {
    async get ({ commit, rootState }) {
      const accountId = rootState.user.currentUser?.uniqueID || ''

      onValue(dbRef, (snapshot) => {
        const topups = snapshot.val() && Object.values(snapshot.val()).filter(it => it.userId === accountId)
        commit('SET_DATA', topups)
      })
    }
  }
}

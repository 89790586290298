<template>
  <div class="stepper d-flex justify-content-between">
    <div
      v-for="step in steps"
      :key="step"
      :class="[
        'step d-flex flex-column position-relative',
        { 'step--active': isActive(step) },
        { 'step--done': isDone(step) },
      ]"
    >
      <div class="d-flex align-items-center">
        <VTooltip :triggers="['hover', 'click']">
          <div class="step__icon rounded-circle d-flex justify-content-center align-items-center">
            <svg v-if="isDone(step) || isActive(step)" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
            <svg v-else fill="gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M80 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74V320c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7V160c0-70.7-57.3-128-128-128H144C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>
          </div>
          <template #popper>
            <div>
              {{ getTooltip(step) }}
            </div>
          </template>
        </VTooltip>
        <div v-if="step.id !== 3" class="step__line rounded-pill" />
      </div>
      <span :class="`step__label ${'step__label--' + step.label}`">{{ step.label }}</span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'

const props = defineProps(['transaction'])

const steps = ref([
  {
    id: 1,
    label: 'First'
  },
  {
    id: 2,
    label: 'Reminder'
  },
  {
    id: 3,
    label: 'Final'
  }
])

const isActive = (step) => {
  return step.id === props.transaction.sequenceNumber && props.transaction.status !== 5
}

const isDone = (step) => {
  return (step.id <= props.transaction.sequenceNumber && props.transaction.status === 5) || step.id < props.transaction.sequenceNumber
}

const getTooltip = (step) => {
  switch (step.id) {
    case 1: return 'This is the first message in a sequence of three for this template'
    case 2: return 'This is the second message in a sequence of three for this template'
    case 3: return 'This is the third message in a sequence of three for this template'
    default: return ''
  }
}
</script>

<style lang="scss" scoped>
.step {
  &__icon {
    width: 36px;
    height: 36px;
    border: 2px solid gray;

    svg {
      height: 20px;
    }
  }

  &__label {
    font-size: 14px;

    &--Reminder {
      position: relative;
      left: -15px;
    }
  }

  &__line {
    background-color: #6c757d;
    height: 3px;
    width: 100px;
  }

  &--active {
    .step__icon {
      background-color: #0d6efd;
      border: 0;
    }

    .step__line {
      background-color: #0d6efd;
    }
  }

  &--done {
    .step__icon {
      background-color: #198754;
      border: 0;
    }

    .step__line {
      background-color: #198754;
    }
  }
}

@media only screen and (max-width: 767px) {
  .step {
    &__icon {
      width: 22px;
      height: 22px;

      svg {
        height: 14px;
      }
    }

    &__label {
      &--Reminder {
        left: -20px;
      }
    }

    &__line {
      height: 2px;
      width: 50px;
    }
  }
}
</style>

<template>
  <LoadingScreen v-if="isLoading" />
  <div class="modal fade" id="modalEditEmail" tabindex="-1" aria-labelledby="modalEditEmailLabel" aria-hidden="true">
    <div class="modal-dialog">
      <form class="modal-content" @submit.prevent="save">
        <div class="modal-header">
          <h5 class="modal-title" id="modalEditEmailLabel">Update Name</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">Current Email: <strong>{{ currentUser.email }}</strong></div>
          <input
            v-model="newEmail"
            class="form-control mb-3"
            :class="{ 'is-invalid': !isValidEmail }"
            placeholder="Enter New Email"
            required
            type="text"
            @input="checkValid"
          >
          <input
            v-model="password"
            class="form-control mb-3"
            placeholder="Enter Password"
            required
            type="password"
          >
          <div v-if="isError" class="alert alert-danger alert-dismissible fade show mt-3 mb-0" role="alert">
            <strong>Error!</strong> {{ isError }}
          </div>
        </div>
        <div class="modal-footer">
          <button id="modalEditEmailClose" class="btn btn-secondary" data-bs-dismiss="modal" type="button">
            Cancel
          </button>
          <button
            class="btn btn-primary"
            :disabled="newEmail && !isValidEmail"
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const newEmail = ref('')
const password = ref('')
const isValidEmail = ref(true)
const isError = ref(null)
const store = useStore()
const isLoading = ref(false)

const currentUser = computed(() => store.state.user.currentUser)

function checkValid () {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  isValidEmail.value = emailPattern.test(newEmail.value)
}

async function save () {
  isLoading.value = true
  isError.value = null
  checkValid()

  if (!isValidEmail.value) return

  store.dispatch('user/updateEmail', { email: newEmail.value, password: password.value })
    .then((res) => {
      isLoading.value = false

      if (!res.status) {
        isError.value = res.message
      } else {
        document.getElementById('modalEditEmailClose').click()
      }
    })
}
</script>

<template>
  <GoogleMap :api-key="apiKey" style="width: 100%; height: 500px" :center="center" :zoom="13">
    <Marker :options="{ position: center }" />
  </GoogleMap>
</template>

<script setup>
import { computed, defineComponent, defineProps, ref } from 'vue'
import { GoogleMap as GoogleMapV3, Marker as MarkerV3 } from 'vue3-google-map'

const props = defineProps({
  coordinates: {
    default: null,
    type: Object
  }
})

// const center = ref({ lat: 40.689247, lng: -74.044502 })
const GoogleMap = defineComponent(GoogleMapV3)
const Marker = defineComponent(MarkerV3)

const apiKey = ref(process.env.VUE_APP_GOOGLE_API_KEY)

const center = computed(() => {
  return {
    lat: props.coordinates.lat,
    lng: props.coordinates.lng
  }
})
</script>

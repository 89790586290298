<template>
  <div class="modal fade" id="modalConfirmSend" tabindex="-1" aria-labelledby="modalConfirmSendLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalConfirmSendLabel">SendAMessage</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            Cost to SendAMessage: <strong>${{ props.transaction.sellPrice.total }}</strong> (inc GST)
          </div>
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <div class="fs-15">To Pay for this message ONLY</div>
            <button class="btn btn-primary" data-bs-dismiss="modal" data-bs-target="#modalTopupMessageEdit" data-bs-toggle="modal">Pay</button>
          </div>
          <div>
            <template v-if="props.user.credits < props.transaction.sellPrice.total">
              <div class="mb-3 d-flex align-items-center justify-content-between">
                <div class="fs-15">Current balance: <strong>${{ props.user.credits }}</strong></div>
                <button class="btn btn-primary" data-bs-dismiss="modal" type="button" @click="router.push({ name: 'AccountTopup' })">
                  Go to Topup Page
                </button>
              </div>
            </template>
            <template v-else>
              <div class="mb-3 d-flex align-items-center justify-content-between">
                <div class="fs-15">To Pay using your credit balance of <strong>${{ props.user.credits }}</strong></div>
                <button class="btn btn-primary" data-bs-dismiss="modal" type="button" @click="emit('send')">Send</button>
              </div>
            </template>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <!-- <button
            class="btn btn-primary"
            data-bs-dismiss="modal"
            type="button"
            @click="emit('send')"
          >
            Send
          </button> -->
        </div>
      </div>
    </div>
  </div>
  <ModalTopup
    :transaction="props.transaction"
    :user="props.user"
    @send="send"
  />
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'
import { useRouter } from 'vue-router'
import ModalTopup from '@/components/MessageEdit/ModalSingleMessageTopup.vue'

const router = useRouter()

const emit = defineEmits(['send'])
const props = defineProps({
  settings: { required: true, type: Object },
  transaction: { required: true, type: Object },
  user: { required: true, type: Object }
})

function send (isSinglePayment) {
  emit('send', isSinglePayment)
}
</script>

import { createStore } from 'vuex'
import categories from './modules/categories'
import help from './modules/help'
import recaptcha from './modules/recaptcha'
import settings from './modules/settings'
import sequences from './modules/sequences'
import templates from './modules/templates'
import topups from './modules/topups'
import transactions from './modules/transactions'
import user from './modules/user'

const store = createStore({
  modules: {
    categories,
    help,
    recaptcha,
    settings,
    sequences,
    templates,
    topups,
    transactions,
    user
  }
})

export default store

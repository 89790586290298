<template>
  <div class="modal fade" id="modalPreviewAttachment" tabindex="-1" aria-labelledby="modalPreviewAttachmentLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content border border-primary" style="background-color: #F2F6FB;">
        <div class="modal-header">
          <h5 class="modal-title" id="modalPreviewAttachmentLabel">Preview Attachment Image</h5>
          <button id="modalPreviewAttachmentClose" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <template v-if="!isShowLocation">
            <div>{{ props.attachment.name }}</div>
            <img :src="props.attachment.url" class="w-100" alt="">
          </template>
          <template v-else>
            <GoogleMap v-if="props.coordinates" :coordinates="props.coordinates" />
          </template>
        </div>
        <div class="modal-footer justify-content-between">
          <button v-if="coordinates" class="btn btn-primary" @click="isShowLocation = !isShowLocation">
            {{ !isShowLocation ? 'Show Location' : 'Show Attachment' }}
          </button>
          <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import GoogleMap from './GoogleMap.vue'

const props = defineProps({
  attachment: Object,
  coordinates: Object
})

const isShowLocation = ref(false)
</script>

<template>
  <div class="mb-2 fs-4 fw-semibold">
    Image Attachment Feature
  </div>
  <div>
    Once an image has been uploaded, <code>Preview Image</code> button will show.
  </div>
  <div>
    <img :src="PreviewImage" alt="" style="max-width: 600px;">
  </div>
  <div>
    When <code>Preview Image</code> button appears, it means that the image is saved in Cloud Storage.
  </div>
  <div>
    <code>Show Location</code> button also appears if the image uploaded has enabled location tagging, meaning the coordinates of the image is available, then it will also save a screenshot image from google map of the coordinates. After saving to Cloud Storage, the images link will be saved to Database.
  </div>
  <div>
    If you also want to send the map screenshot as attachment, you will need to enable <code>Image Location</code> checkbox.
  </div>
  <div>
    <em>(It is automatically checked if the uploaded image has location tag.)</em>
  </div>
  <div>
    <img :src="ImageLocation" alt="" style="max-width: 600px;">
  </div>
  <div class="my-2 fs-4 fw-semibold">
    How to enable Location Tagging
  </div>
  <div class="my-2 fs-5 fw-semibold">
    Android
  </div>
  <div>
    <ol>
      <li>Turn on Location in your device</li>
      <li>Open the camera app and navigate to the settings</li>
      <li>Once in the camera settings, you'll notice an option like tag locations or geo-tagging. Just enable it and your phone's camera will record the location details of a photograph.</li>
    </ol>
  </div>
  <div>
    <img :src="TagLocAndroid" alt="" style="max-width: 300px;">
  </div>
  <div class="my-2 fs-5 fw-semibold">
    iOS
  </div>
  <div>
    <ol>
      <li class="mb-5">
        <div>
          You won't find the location tagging settings in the camera app on iOS. For this, you'll have to go to the phone's settings and navigate to Privacy.
        </div>
        <div>
          <img :src="TagLocIos" alt="" style="max-width: 300px;">
        </div>
      </li>
      <li class="mb-5">
        <div>
          Here, you’ll find Location Services, under which there are app-specific options for location access.
        </div>
        <div>
          <img :src="TagLocIos2" alt="" style="max-width: 300px;">
        </div>
      </li>
      <li class="mb-5">
        <div>
          Under the app-specific options, you can disable the camera app’s access to your location.
        </div>
        <div>
          <img :src="TagLocIos3" alt="" style="max-width: 300px;">
        </div>
      </li>
    </ol>
  </div>
</template>

<script setup>
const PreviewImage = require('@/assets/images/attachment/preview_image.png')
const ImageLocation = require('@/assets/images/attachment/image_location.png')
const TagLocAndroid = require('@/assets/images/attachment/tag_location_android.png')
const TagLocIos = require('@/assets/images/attachment/tag_location_ios.png')
const TagLocIos2 = require('@/assets/images/attachment/tag_location_ios2.png')
const TagLocIos3 = require('@/assets/images/attachment/tag_location_ios3.png')

</script>

<style lang="scss" scoped>
div {
  margin-bottom: 1rem;
}
code {
  background-color: #F4F5F7;
  color: #172b4d;
  padding: 2px 0.5ch;
}

img {
  width: 100%;
}
</style>

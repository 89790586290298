<template>
  <div class="modal fade" id="modalTerms" tabindex="-1" aria-labelledby="modalTermsLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalTermsLabel">Terms & Conditions</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-html="props.terms.value" />
          <template v-if="signupMode">
            <hr>
            <div class="d-flex gap-2 mb-3">
              <input
                v-model="isTerms"
                id="checkbox-terms"
                class="form-check-input"
                style="flex-shrink: 0;"
                type="checkbox"
              >
              <label for="checkbox-terms" class="fs-12">
                By signing up, you are creating a SendAMessage account, and you agree to SendAMessage's Terms of Use and Privacy Policy
              </label>
            </div>
          </template>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">
            Close
          </button>
          <button class="btn btn-primary" data-bs-dismiss="modal" :disabled="!isTerms" type="button" @click="finishSignup">
            Sign Up
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'

const emit = defineEmits(['signup', 'signinWithGoogle'])
const props = defineProps(['terms', 'signupMode'])

const isTerms = ref(false)

function finishSignup () {
  if (props.signupMode === 1) {
    emit('signup', isTerms.value)
  }

  if (props.signupMode === 2) {
    emit('signinWithGoogle', isTerms.value)
  }
}
</script>

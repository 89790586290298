<template>
  <div>
    <!-- <Tour /> -->
    <GetStarted />

    <div class="mb-4">
      <div class="mb-2 d-flex align-items-center justify-content-between">
        <div class="mb-2 fs-4 fw-semibold">FAQ</div>
        <input v-model="searchInput" class="form-control w-50" type="text" placeholder="Search Title..." @input="onSearch">
      </div>
      <div
        v-for="(faq, faqIdx) in filteredFaqs"
        :key="faq.id"
        :class="[
          'mb-0 border border-primary',
          { 'rounded-top': faqIdx === 0, 'border-top-0': faqIdx > 0, 'rounded-bottom': faqIdx === filteredFaqs.length - 1 }
        ]"
      >
        <div class="accordion" :id="`accordion-${faq.id}`">
          <div class="accordion-item border-0">
            <h2 class="accordion-header" :id="`heading-${faq.id}`">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse-${faq.id}`" aria-expanded="false" :aria-controls="`#collapse-${faq.id}`">
                {{ faq.title || 'Title' }}
              </button>
            </h2>
            <div :id="`collapse-${faq.id}`" :class="['accordion-collapse collapse', { 'show': faqIdx === 0 }]" :aria-labelledby="`heading-${faq.id}`" :data-bs-parent="`#accordion-${faq.id}`">
              <div class="accordion-body">
                {{ faq.body || 'Body' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-2 fs-4 fw-semibold">Contact Support</div>
  </div>
</template>

<script setup>
// import Tour from '@/components/Tour.vue'
import GetStarted from '@/components/GetStarted.vue'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const searchInput = ref('')
const faqs = computed(() => store.state.help.data || [])
const filteredFaqs = ref([])

watch(
  faqs,
  (val) => {
    filteredFaqs.value = val
  },
  { immediate: true }
)

function onSearch (evt) {
  filteredFaqs.value = faqs.value.filter(it => it.title.toLowerCase().includes(evt.target.value.toLowerCase()))
}
</script>

<style lang="scss" scoped>

</style>

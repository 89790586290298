import { getDatabase, ref, onValue } from 'firebase/database'

const db = getDatabase()
const dbRef = ref(db, 'messageTemplates')

export default {
  namespaced: true,
  state: () => ({
    data: null,
    enabledData: null,
    tempData: null
  }),
  getters: {},
  mutations: {
    SET_DATA (state, payload) {
      state.data = payload
    },
    SET_TEMP_DATA (state, payload) {
      state.tempData = payload
    },
    SET_ENABLED_DATA (state, payload) {
      state.enabledData = payload
    }
  },
  actions: {
    get ({ commit, rootState }) {
      onValue(dbRef, (snapshot) => {
        const categories = rootState.categories.data
        const templates = Object.values(snapshot.val()).filter(it => !it.deleted)

        const mutatedTemplates = templates && templates.map((template) => {
          const category = categories.find(cat => cat.id === template.categoryId)

          if (category) {
            const useCase = category.useCases.find(c => c.id === template.useCaseId)

            return {
              ...template,
              category: {
                id: category.id,
                description: category.description,
                name: category.name
              },
              label: `${category.name} - ${(useCase && useCase.name) || ''}`,
              paragraphs: template.paragraphs || [],
              tags: template.tags || '',
              useCase
            }
          }

          return template
        })

        /* START FILTER TEMPLATES WITH ENABLED CATEGORY OR USE CASES */
        const filterEnabledCategories = categories.filter(it => it.enabled)

        const filterTemplatesWithEnabledCategory = mutatedTemplates.filter(temp => {
          return filterEnabledCategories.find(categ => categ.id === temp.categoryId)
        })

        //start
        const enabledUseCases = []

        // First, collect all enabled use case names
        for (const category of filterEnabledCategories) {
          const enabledUseCaseNames = category.useCases
            .filter(useCase => useCase.enabled)
            .map(useCase => useCase.name)

          // Add these useCase names to the enabledUseCases array if they don't exist already.
          for (const useCaseName of enabledUseCaseNames) {
            if (!enabledUseCases.includes(useCaseName)) {
              enabledUseCases.push(useCaseName)
            }
          }
        }

        const templatesWithEnabledUseCases = filterTemplatesWithEnabledCategory.filter(template => {
          // Check if the template's useCase name is in the enabledUseCases array.
          return enabledUseCases.includes(template.useCase.name) && (template.sequenceNumber === undefined || template.sequenceNumber === 1)
        })
        /* END FILTER TEMPLATES WITH ENABLED CATEGORY OR USE CASES */

        const enabledTemplates = templatesWithEnabledUseCases.filter(it => it.enabled)

        commit('SET_DATA', templates)
        commit('SET_ENABLED_DATA', enabledTemplates)
        commit('SET_TEMP_DATA', mutatedTemplates)
      })
    }
  }
}

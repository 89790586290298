<template>
  <div class="text-center mb-5">
    <div class="fs-4 fw-semibold">Your Profile</div>
    <span class="text-muted">Account details used within your messaging</span>
  </div>

  <div
    class="border border-2 border-primary rounded rounded-4 bg-light-blue mb-4 p-3 mx-auto d-flex align-items-center gap-3"
    style="max-width: 600px;"
  >
    <div class="account-base__avatar">
      <label for="avatar">
        <img :src="avatar || defaultAvatar" alt="">
        <div class="account-base__avatar-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"/></svg>
        </div>
      </label>
      <input
        id="avatar"
        class="account-base__avatar-input"
        type="file"
        accept="image/jpeg, image/png, image/jpg"
        @change="uploadAvatar($event)"
      >
    </div>
    <div class="">
      <div
        class="d-flex gap-2 fs-15 fw-bold"
        data-bs-target="#modalEditName"
        data-bs-toggle="modal"
      >
        {{ getName(user) }}
        <span class="d-flex align-items-start gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 512 512"><path fill="#212529" d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"/></svg>
        </span>
      </div>
      <div class="fs-14 mb-2">
        Member Since {{ formatDate(user.createdAt) }}
      </div>
      <button
        v-if="auth.currentUser.providerData[0].providerId === 'password'"
        class="btn btn-danger px-2 py-1 rounded-pill d-flex align-items-center gap-2 text-white fs-12"
        data-bs-target="#modalChangePassword"
        data-bs-toggle="modal"
      >
        <svg fill="currentColor" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M360 224l-32 0c-13.3 0-24-10.7-24-24s10.7-24 24-24c10.7 0 21.3 0 32 0c18 0 36.1 0 54.1 0c-17.4-17.4-34.7-34.7-52.1-52.1C333.8 95.8 295.7 80 256 80c-72.7 0-135.2 44.1-162 107.1c-5.2 12.2-19.3 17.9-31.5 12.7s-17.9-19.3-12.7-31.5C83.9 88.2 163.4 32 256 32c52.5 0 102.8 20.8 139.9 57.9c17.4 17.4 34.7 34.8 52.1 52.2c0-18 0-36.1 0-54.1c0-.1 0-.3 0-.4c0-10.5 0-21.1 0-31.6c0-13.3 10.7-24 24-24s24 10.7 24 24c0 36.2 0 72.4 0 108.6c-30.6 9.1-55.7 30.9-69.2 59.4c-22.3 0-44.5 0-66.8 0zM40 288l112 0c13.3 0 24 10.7 24 24s-10.7 24-24 24c-18 0-36.1 0-54.1 0c17.4 17.4 34.7 34.7 52.1 52.1c28.2 28.1 66.3 43.9 106 43.9c50.4 0 95.9-21.2 128-55.2c0 21 0 42 0 63C347.7 465.2 303.6 480 256 480c-52.5 0-102.8-20.8-139.9-57.9C98.7 404.7 81.4 387.3 64 369.9c0 18 0 36.1 0 54.1c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-37.3 0-74.7 0-112c0-13.3 10.7-24 24-24zm456-16l0 48c21.3 0 42.7 0 64 0c0-16 0-32 0-48c0-17.7-14.3-32-32-32s-32 14.3-32 32zm-48 48l0-48c0-44.2 35.8-80 80-80s80 35.8 80 80c0 16 0 32 0 48c17.7 0 32 14.3 32 32c0 42.7 0 85.3 0 128c0 17.7-14.3 32-32 32c-53.3 0-106.7 0-160 0c-17.7 0-32-14.3-32-32c0-42.7 0-85.3 0-128c0-17.7 14.3-32 32-32z"/></svg>
        Change Password
      </button>
    </div>
  </div>

  <div
    class="border border-2 border-primary rounded rounded-4 bg-light-blue p-3 mb-4 mx-auto"
    style="max-width: 600px;"
  >
    <div class="fs-16 fw-bold mb-3">Personal Information</div>
    <div class="row mb-3">
      <div class="col">
        <span class="text-muted">Email Address</span>
        <div
          class="d-flex"
          data-bs-target="#modalEditEmail"
          data-bs-toggle="modal"
        >
          <span class="d-flex align-items-start gap-2 fs-15 fw-bold">
            {{ user.email }}
            <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 512 512"><path fill="#212529" d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"/></svg>
          </span>
        </div>
      </div>
      <div class="col">
        <span class="text-muted">Country Code</span>
        <div class="fs-15 fw-bold">{{ (props.user.country && props.user.country.dial_code) ?? '+61' }}</div>
      </div>
      <div class="col">
        <span class="text-muted">Gender</span>
        <div
          class="d-flex"
          data-bs-target="#modalEditGender"
          data-bs-toggle="modal"
        >
          <span class="d-flex align-items-start gap-2 text-capitalize fs-15 fw-bold">
            {{ user.gender }}
            <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 512 512"><path fill="#212529" d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"/></svg>
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <span class="text-muted">ABN</span>
        <div
          class="d-flex"
          data-bs-target="#modalEditAbn"
          data-bs-toggle="modal"
        >
          <span class="d-flex align-items-start gap-2 text-capitalize fs-15 fw-bold">
            {{ user.abn }}
            <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 512 512"><path fill="#212529" d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"/></svg>
          </span>
        </div>
      </div>
      <div class="col-4">
        <span class="text-muted">Phone Number</span>
        <div
          class="d-flex"
          data-bs-target="#modalEditPhone"
          data-bs-toggle="modal"
        >
          <span class="d-flex align-items-start gap-2 text-capitalize fs-15 fw-bold">
            {{ user.phone }}
            <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 512 512"><path fill="#212529" d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"/></svg>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="border border-2 border-primary rounded rounded-4 bg-light-blue p-3 mb-4 mx-auto"
    style="max-width: 600px;"
  >
    <div class="fs-16 fw-bold mb-3">Address</div>
    <div v-if="!hasAddress" class="d-grid d-sm-flex gap-3 flex-wrap">
      <button class="btn btn-primary w-100" data-bs-target="#modalSetAddress" data-bs-toggle="modal" type="button" style="flex: 2;">
        Set your Mail "Return to" Address
      </button>
      <router-link :to="{ name: 'AccountDistributions' }" class="btn btn-primary w-100" style="flex: 1;">
        Distribution List
      </router-link>
    </div>
    <template v-else>
      <div class="row mb-3">
        <!-- <div class="col" data-bs-target="#modalEditCountry" data-bs-toggle="modal" style="max-width: 150px;"> -->
        <div class="col" style="max-width: 150px;">
          <span class="text-muted">Country</span>
          <div class="d-flex align-items-start gap-2">
            <span v-if="user.country" class="fs-15 fw-bold">{{ user.country.name ?? '' }}</span>
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 512 512"><path fill="#212529" d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"/></svg> -->
          </div>
        </div>
        <div class="col" data-bs-target="#modalSetAddress" data-bs-toggle="modal">
          <span class="text-muted">Address Line</span>
          <div class="d-flex gap-2">
            <div class="fs-15 fw-bold">{{ user.address.full }}</div>
            <svg style="flex-shrink: 0;" xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 512 512"><path fill="#212529" d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"/></svg>
          </div>
        </div>
      </div>
      <router-link :to="{ name: 'AccountDistributions' }" class="btn btn-primary w-100">
        Distribution List
      </router-link>
    </template>
    <ModalSetAddress
      :user="user"
    />
  </div>

  <div
    class="border border-2 border-primary rounded rounded-4 bg-light-blue p-3 mb-4 mx-auto"
    style="max-width: 600px;"
  >
    <div class="fs-16 fw-bold mb-3">Credit Details</div>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="">
        <span class="text-muted">Remaining Credits</span>
        <div class="fs-15 fw-bold">$ {{ user.credits || 0 }}</div>
      </div>
      <div class="">
        <button class="btn btn-success px-4 rounded-pill d-flex align-items-center gap-2 text-white" @click="router.push({ name: 'AccountTopup' })">
          <svg fill="currentColor" width="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M360 75.9c-64.4-37.2-143.6-37.2-208 0S48 181.7 48 256s39.6 143 104 180.1c57.4 33.1 126.7 36.7 186.7 10.7c7.4 14.7 16.7 28.1 27.7 40.1c-75.7 36.2-164.9 33.1-238.4-9.3C48.8 432 0 347.5 0 256S48.8 80 128 34.3s176.8-45.7 256 0c60.3 34.8 103 92.1 119.9 157.9c-2.6-.1-5.3-.2-7.9-.2c-14 0-27.6 1.6-40.6 4.7c-15-50.3-48.7-93.9-95.4-120.8zm-83.1 57.7c-.1 4.7-.1 9.5-.1 14.2c9.7 1.2 19.4 3.9 29 6.6c1.9 .5 3.7 1 5.6 1.6c11.5 3.2 18.3 15.1 15.1 26.6s-15.1 18.2-26.6 15.1c-1.6-.4-3.1-.9-4.7-1.3c-7-2-14-3.9-21.1-5.3c-13.2-2.5-28.5-1.3-40.8 4c-11 4.8-20.1 16.4-7.6 24.4c9.8 6.3 21.8 9.5 33.2 12.6c2.4 .6 4.7 1.3 7 1.9c15.6 4.4 35.5 10.1 50.4 20.3c12 8.2 20.1 19.6 23.4 32.6c-9.3 17.9-15.7 37.7-18.3 58.6c-5.7 4.9-12.3 8.8-19.3 11.6c-7.8 3.2-16.3 5.2-25.2 6.2l0 15.2c0 11.9-9.7 21.6-21.6 21.6s-21.6-9.7-21.6-21.6c0-5.8 0-11.6 0-17.4c-14.5-3.3-28.7-7.9-42.8-12.5c-11.3-3.7-17.5-16-13.7-27.3s16-17.5 27.3-13.7c2.5 .8 5 1.7 7.5 2.5c11.3 3.8 22.9 7.7 34.5 9.6c17 2.5 30.6 1 39.5-2.6c12-4.8 17.7-19.1 5.9-27.1c-10.1-6.9-22.6-10.3-34.5-13.5c-2.3-.6-4.5-1.2-6.8-1.9c-15.1-4.3-34-9.6-48.2-18.7c-19.5-12.5-29.4-33.3-25.2-56.4c4-21.8 21-36.3 39-44.1c5.5-2.4 11.4-4.3 17.5-5.7c0-5.4 0-10.7 0-16.1c0-11.9 9.7-21.6 21.6-21.6s21.6 9.7 21.6 21.6zM640 368c0 79.5-64.5 144-144 144s-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144zM480 304l0 48c-16 0-32 0-48 0c-8.8 0-16 7.2-16 16s7.2 16 16 16c16 0 32 0 48 0l0 48c0 8.8 7.2 16 16 16s16-7.2 16-16c0-16 0-32 0-48c16 0 32 0 48 0c8.8 0 16-7.2 16-16s-7.2-16-16-16c-16 0-32 0-48 0c0-16 0-32 0-48c0-8.8-7.2-16-16-16s-16 7.2-16 16z"/></svg>
          Top Up
        </button>
      </div>
    </div>
  </div>

  <div
    class="border border-2 border-primary rounded rounded-4 bg-light-blue p-3 mx-auto"
    style="max-width: 600px;"
  >
    <TabMessages :transactions="transactions" />
  </div>
  <ModalChangePassword />
  <ModalEditCountry :user="user" />
  <ModalEditGender :user="user" />
  <ModalEditAbn :user="user" />
  <ModalEditPhone :user="user" />
  <ModalEditName :user="user" />
  <ModalEditEmail />

</template>

<script setup>
import { getAuth } from 'firebase/auth'
import { computed, defineEmits, defineProps, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import TabMessages from './TabMessages.vue'
import ModalChangePassword from './ModalChangePassword.vue'
import ModalEditCountry from './ModalEditCountry.vue'
import ModalEditAbn from './ModalEditAbn.vue'
import ModalEditEmail from './ModalEditEmail.vue'
import ModalEditGender from './ModalEditGender.vue'
import ModalEditName from './ModalEditName.vue'
import ModalEditPhone from './ModalEditPhone.vue'
import ModalSetAddress from '@/components/ModalSetAddress.vue'

const defaultAvatarFemale = require('@/assets/images/avatar-default-female.jpg')
const defaultAvatarMale = require('@/assets/images/avatar-default-male.jpg')

const auth = getAuth()
const emit = defineEmits(['upload'])
const props = defineProps(['user'])
const router = useRouter()
const store = useStore()
const transactions = computed(() => store.state.transactions.data || [])
const avatar = ref(props.user.avatar || '')

const defaultAvatar = computed(() => {
  if (props.user.gender === 'female') {
    return defaultAvatarFemale
  }

  return defaultAvatarMale
})

const hasAddress = computed(() => {
  if (!props.user.address || !props.user.address.full) return false

  return true
})

function formatDate (createdAt) {
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  const date  = new Date(createdAt)
  return date.toLocaleDateString('en-US', options)
}

function getName (user) {
  if (user.firstName || user.lastName) {
    return (user.firstName || '') + ' ' + (user.lastName || '')
  } else if (user.name) {
    return user.name
  }

  return 'No name provided.'
}

function uploadAvatar (e) {
  const file = e.target.files[0]

  if (!file) {
    return
  }

  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = (e) => {
    avatar.value = e.target.result
    emit('upload', file)
  }
}
</script>

<style lang="scss" scoped>
.account-base {
  &__avatar {
    display: inline-block;
    position: relative;

    label {
      border-radius: 50%;
    }

    img {
      width: 90px;
      border-radius: 50%;
    }

    &-icon {
      align-items: center;
      background-color: #6c757d;
      border-radius: 50%;
      bottom: 3px;
      display: flex;
      height: 20px;
      justify-content: center;
      padding: 4px;
      position: absolute;
      right: 2px;
      width: 20px;

      svg {
        path {
          fill: white;
        }
      }
    }

    &-input {
      width: 0;
      opacity: 0;
      position: absolute;
      z-index: 0;
    }
  }
}
</style>

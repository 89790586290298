<template>
  <div class="modal fade" id="modalChangePassword" tabindex="-1" aria-labelledby="modalChangePasswordLabel" aria-hidden="true">
    <div class="modal-dialog">
      <form class="modal-content" @submit.prevent="save">
        <div class="modal-header">
          <h5 class="modal-title" id="modalChangePasswordLabel">Change Password</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <input
            v-model="oldPassword"
            class="form-control mb-3"
            placeholder="Old Password"
            required
            type="password"
          >
          <input
            v-model="newPassword"
            class="form-control"
            placeholder="New Password"
            required
            type="password"
          >
          <div v-if="isError" class="alert alert-danger alert-dismissible fade show mt-3 mb-0" role="alert">
            <strong>Error!</strong> {{ errorMessage }}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
          <div v-if="isSuccess" class="alert alert-success alert-dismissible fade show mt-3 mb-0" role="alert">
            Successfully updated password!
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">
            Close
          </button>
          <button class="btn btn-primary" :disabled="isSaving" type="submit">
            {{ isSaving ? 'Saving...' : 'Save' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'

const errorMessage = ref('')
const isError = ref(false)
const isSaving = ref(false)
const isSuccess = ref(false)
const newPassword = ref('')
const oldPassword = ref('')
const store = useStore()

async function save () {
  isSaving.value = true

  await store.dispatch('user/updatePassword', {
    newPassword: newPassword.value,
    oldPassword: oldPassword.value
  })
    .then((res) => {
      if (!res.status) {
        isError.value = true
        errorMessage.value = res.message

        setTimeout(() => {
          isError.value = false
        }, 5000)
      } else {
        isSuccess.value = res.status
        newPassword.value = ''
        oldPassword.value = ''

        setTimeout(() => {
          isSuccess.value = false
        }, 5000)
      }

      isSaving.value = false
    })
}
</script>

<template>
  <LoadingScreen v-if="!currentUser || isLoading" />
  <div v-else class="topup">
    <div class="mb-2 fs-4 fw-semibold">Credit Details</div>
    <div class="mb-3">
      Current Balance: <strong>${{ currentUser.credits }}</strong>
    </div>
    <hr>
    <div class="mb-2 fs-4 fw-semibold">TopUp</div>
    <div class="d-flex gap-2 gap-md-5 mb-4 flex-wrap">
      <button
        v-for="val in [5, 10, 25]" :key="val"
        class="text-decoration-none btn border-primary d-flex flex-column justify-content-center align-items-center py-4" style="flex: 1; max-width: 180px;"
        data-bs-target="#modalTopup"
        data-bs-toggle="modal"
        @click="clickCredits(val)"
      >
        Topup ${{val}} Credits
      </button>
      <button
        class="text-decoration-none btn border-primary d-flex flex-column justify-content-center align-items-center py-4" style="flex: 1; max-width: 180px;"
        data-bs-target="#modalTopup"
        data-bs-toggle="modal"
        @click="clickCustom"
      >
        Topup $ Custom
      </button>
    </div>
    <small>
      Explore for now.
      <button class="btn btn-primary"  @click="router.push({ name: 'Messages' })">Topup later.</button>
    </small>
  </div>
  <div v-if="isError" class="alert alert-danger alert-dismissible fade show" role="alert">
    <strong>Error!</strong> {{ errorMsg }}
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
  <div v-if="isSuccess" class="alert alert-success alert-dismissible fade show" role="alert" style="width: fit-content">
    You have successfully added <strong>${{ parseFloat(selectedCredits).toFixed(2) }}</strong> credit to your account making your current account balance <strong>${{ parseFloat(currentUser.credits).toFixed(2) }}</strong>
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
  <div v-if="isSuccess" class="alert alert-success alert-dismissible fade show" role="alert" style="width: fit-content">
    If you would like a receipt sent to your account email address please tap
      <button class="btn btn-success" :disabled="isReceiptSending" @click="requestReceipt">Receipt</button>
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
  <div v-if="isReceiptSent === 'success'" class="alert alert-success alert-dismissible fade show" role="alert" style="width: fit-content">
    Receipt has been sent to your email
  </div>
  <div v-if="isReceiptSent === 'error'" class="alert alert-danger alert-dismissible fade show" role="alert" style="width: fit-content">
    There has been an error sending the receipt to your email
  </div>
  <ModalTopup
    :selectedCredits="selectedCredits"
    :isCustomCredits="isCustomCredits"
    :minimumTopupAmount="minimumTopupAmount"
    @setCustom="setCustom"
  />
  <hr>
  <SpendHistory />
</template>

<script setup>
/* eslint-disable */
import { getFunctions, httpsCallable } from 'firebase/functions'
import { computed, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ModalTopup from '@/components/AccountTopup/ModalTopup.vue'
import SpendHistory from '@/components/AccountTopup/SpendHistory.vue'

const router = useRouter()
const store = useStore()
const errorMsg = ref('')
const isError = ref(false)
const isLoading = ref(false)
const isSuccess = ref(false)
const selectedCredits = ref(null)
const isCustomCredits = ref(false)
const isReceiptSent = ref(null)
const isReceiptSending = ref(false)
const topupResponse = ref(null)
const functions = getFunctions()


const currentUser = computed(() => store.state.user.currentUser)
const settings = computed(() => store.state.settings.data)
const minimumTopupAmount = computed(() => {
  const minAmount = settings.value && settings.value.find(it => it.label === 'Minimum Topup Amount')
  return minAmount && parseFloat(minAmount.value)
})

function processTopup (token) {
  if (isCustomCredits.value && parseFloat(selectedCredits.value) < parseFloat(minimumTopupAmount.value)) {
    isError.value = true
    isLoading.value = false
    errorMsg.value = `Minimum amount is $${minimumTopupAmount.value}`
    return
  }

  const topupCredits = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_topupCredits`)

  const params = {
    ...currentUser.value,
    token,
    selectedCredits: parseFloat(selectedCredits.value)
  }

  return topupCredits(params).then((res) => {
    topupResponse.value = res.data
    return res.data
  })
}

function requestReceipt () {
  if (isReceiptSending.value) {
    return
  }

  isReceiptSending.value = true
  const requestReceipt = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_requestReceipt`)

  return requestReceipt({...topupResponse.value, user: currentUser.value}).then(res => {
    console.log('res: ', res.data)
    isReceiptSent.value = res.data.status

    if (isReceiptSent.value === 'success') {
      setTimeout(() => {
        isReceiptSent.value = null
        isSuccess.value = false
        isReceiptSending.value = false
      }, 3000)
    } else {
      isReceiptSending.value = false
    }
  })
}

function clickCredits (val) {
  selectedCredits.value = val
  isCustomCredits.value = false
  isError.value = false
  isSuccess.value = false
}

function clickCustom () {
  selectedCredits.value = null
  isCustomCredits.value = true
  isSuccess.value = false
  isError.value = false
}

function setCustom (val) {
  selectedCredits.value = val
}

function errorCode () {
  // switch (code) {
  //   case 'customer':
  //     return 'Invalid customer.'
  //   case 'payment':
  //     return 'Invalid payment.'
  //   default:
  //     return 'Something went wrong.'
  // }
  return 'Unable to process payment. Try again!'
}

onMounted(() => {  
  const script = document.createElement('script')
  script.src = process.env.VUE_APP_SQUARE_CDN
  document.head.appendChild(script)

  script.onload = () => {
    if (localStorage.getItem('reloaded')) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem('reloaded');
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem('reloaded', '1');
      location.reload();
    }

    const appId = process.env.VUE_APP_SQUARE_APP_ID
    const locationId = process.env.VUE_APP_SQUARE_LOCATION_ID

    async function initializeCard(payments) {
      const card = await payments.card()
      await card.attach('#card-container')
      return card
    }

    (async function () {
      
      if (!window.Square) {
        throw new Error('Square.js failed to load properly')
      }

      const payments = window.Square.payments(appId, locationId)
      let card
      try {
        errorMsg.value = ''
        card = await initializeCard(payments)

        async function eventHandler (event) {
          if (!selectedCredits.value) {
            isError.value = true
            return errorMsg.value = 'Invalid Topup Amount.'
          }

          // if (isCustomCredits.value) {
          //   selectedCredits.value = parseInt(selectedCredits.value.replace('.', ''))
          // } else {
          //   selectedCredits.value = parseInt(selectedCredits.value * 100)
          // }

          isLoading.value = true
          isError.value = false
          isSuccess.value = false
          errorMsg.value = errorCode()

          event.preventDefault()
          try {
            const tokenizeCard = await card.tokenize()
            if (tokenizeCard.status === 'OK') {
              const payment = await processTopup(tokenizeCard.token)
              console.log('payment: ', payment)

              if (payment.status === 'success') {
                isSuccess.value = true
                isLoading.value = false
                errorMsg.value = errorCode()
                // await card.destroy()
              } else {
                isError.value = true
                isLoading.value = false
                errorMsg.value = errorCode()
              }
            } else {
              isError.value = true
              isLoading.value = false
              errorMsg.value = errorCode()
            }
          } catch (e) {
            console.error(e)
          }
        }

        const cardButton = document.getElementById('card-button')
        cardButton.addEventListener('click', eventHandler)
      } catch (e) {
        console.error('Initializing Card failed', e)
        return
      }
    })()
  }

  script.onerror = (error) => {
    console.error('Failed to load Square.js:', error)
    errorMsg.value = 'Failed to load payment gateway. Try refreshing browser or contact support'
    isError.value = true
  }

  document.head.appendChild(script)
})
</script>

<template>
  <div class="modal fade" id="modalVariables" tabindex="-1" aria-labelledby="modalVariablesLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content border border-primary" style="background-color: #F2F6FB;">
        <div class="modal-header">
          <h5 class="modal-title" id="modalVariablesLabel">Select Variable Option</h5>
          <button id="modalVariablesClose" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-for="variable in props.variables" :key="variable.id">
            <div class="mb-2">
              <strong>{{ variable.name }}</strong>
            </div>
            <div v-if="isDate(variable.name)" class="mb-3 position-relative">
              <input class="modal-variables__date form-control border border-primary" :value="variable.selected" type="text">
              <input
                class="modal-variables__date modal-variables__date--hidden"
                type="date"
                @change="selectOption(variable, $event.target.value)"
              >
            </div>
            <div v-else-if="isDuration(variable.name)" class="mb-3 position-relative">
              <select :value="variable.selected" class="form-control border border-primary" @change="selectOption(variable, $event.target.value)">
                <option v-for="i in 60" :key="i" :value="i">
                  {{ i }} minutes
                </option>
              </select>
            </div>
            <div v-else-if="isTime(variable.name)" class="mb-3 position-relative">
              <date-picker
                v-model:value="variable.selected"
                :time-picker-options="{
                  start: '00:00',
                  step: '00:30',
                  end: '23:30',
                }"
                value-type="format"
                format="hh:mm a"
                type="time"
                placeholder="hh:mm a"
                @change="selectOption(variable, variable.selected)"
              ></date-picker>
            </div>
            <select v-else class="form-select mb-3 border border-primary" @change="selectOption(variable, $event.target.value)">
              <option
                disabled
                value=""
                :selected="!variable.options.split(/\n/g).filter(v => v).includes(variable.selected)"
              >
                Select Option
              </option>
              <option
                v-for="(option, optionIdx) in variable.options.split(/\n/g).filter(v => v)"
                :key="optionIdx"
                :selected="option === variable.selected"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">
            Close
          </button>
          <button
            class="btn btn-primary"
            type="button"
            @click="saveVars"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, watch } from 'vue'

const props = defineProps({
  variables: Object
})

const emit = defineEmits(['selectVariableOption', 'saveVariables'])

const refVars = ref(null)

watch(() => props.variables, (newVars) => {
  refVars.value = newVars
})

function formatDate (val) {
  let getDate = new Date(val)

  if (!(getDate instanceof Date && isFinite(getDate))) {
    return val
  }

  const yyyy = getDate.getFullYear()
  let mm = getDate.getMonth() + 1
  let dd = getDate.getDate()

  if (dd < 10) dd = '0' + dd
  if (mm < 10) mm = '0' + mm

  return dd + '-' + mm + '-' + yyyy
}

function isDate (varName) {
  return varName.substring(0, 4).toLowerCase() === 'date'
}

function isDuration (varName) {
  return varName.substring(0, 8).toLowerCase() === 'duration'
}

function isTime (varName) {
  return varName.substring(0, 4).toLowerCase() === 'time'
}

function saveVars () {
  refVars.value.forEach((it) => {
    emit('selectVariableOption', { id: it.id, selected: it.selected || `&lt;&lt;${it.name}&gt;&gt;` })
  })

  emit('saveVariables')
}

function selectOption (variable, value) {
  if (isDate(variable.name)) {
    value = formatDate(value)
  }

  const selectedIndex = props.variables.findIndex(it => it.id === variable.id)
  refVars.value[selectedIndex].selected = value
}
</script>

<style lang="scss" scoped>
.modal-variables {
  &__date {
    appearance: none;
    background-image: url('data:image/svg+xml, <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 6L0 0H8L4 6Z" fill="%23737D96"/></svg>') !important;
    background-position: 95% center !important;
    background-repeat: no-repeat !important;
    width: 100%;

    &--hidden {
      bottom: 0;
      opacity: 0;
      position: absolute;
      top: 0;

      &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        width: 98.5%;
        height: 30px;
      }
    }
  }

}
  :deep(.mx-datepicker) {
    width: 100%;
    .mx-input {
      border: 2px solid #0d6efd;
      border-radius: 0.375rem;
      font-size: 16px;
      padding: 18px 12px;
    }
  }
</style>

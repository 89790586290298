<template>
  <div class="messages">
    <div class="d-flex">
      <div class="d-flex align-items-center gap-3">
        <div class="mb-2 fs-4 fw-semibold">Dashboard</div>
        <div class="mb-2 fs-5">Welcome back, {{ currentUser && currentUser.firstName }}</div>
      </div>
    </div>
    <!-- <template v-if="currentUser && !currentUser.isHiddenTour">
      <Tour hasHideButton />
    </template> -->
    <template v-if="currentUser && !currentUser.isHiddenGetStarted">
      <GetStarted hasHideButton />
    </template>
    <div class="mb-4 rounded border border-primary">
      <div id="carouselTemplates" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner px-5">
          <div v-for="(item, idx) in carouselItems" :key="idx" :class="{ 'carousel-item': true, 'active': idx === 0 }">
            <div class="carousel-item__wrap d-flex align-items-center justify-content-around">
              <div class="carousel-item__text d-block">
                <h5 class="fw-semibold">{{ item.category.name }} | {{ item.useCase.name }}</h5>
                <p class="fs-14">
                  {{ item.useCase.description }}
                </p>
                <div class="d-flex gap-3 align-items-center justify-content-center justify-content-lg-start">
                  <template v-for="temp in item.templates" :key="temp.id">
                    <VTooltip :triggers="['hover', 'click']">
                      <div
                        class="btn btn-primary rounded-pill d-flex align-items-center gap-2 fs-14"
                        @click="setTransaction(item, temp)"
                      >
                        {{ temp.format }} <img :src="temp.icon" alt="">
                      </div>
                      <template #popper>
                        Create {{ temp.format }}
                      </template>
                    </VTooltip>
                  </template>
                </div>
              </div>
              <img :src="item.useCase.image" class="carousel-image d-block" alt="..." height="290">
            </div>
          </div>
        </div>
        <button class="carousel-control-prev justify-content-start" type="button" data-bs-target="#carouselTemplates" data-bs-slide="prev" style="width: unset;">
          <span class="carousel-control-prev-icon" aria-hidden="true" style="background-image: unset;">
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 1L1 7L7 13" stroke="#106CFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next justify-content-end" type="button" data-bs-target="#carouselTemplates" data-bs-slide="next" style="width: unset;">
          <span class="carousel-control-next-icon" aria-hidden="true" style="background-image: unset;">
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7 7L1 13" stroke="#106CFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>

    <div class="d-flex gap-2 gap-md-5 mb-4">
      <router-link class="text-decoration-none btn border-primary d-flex flex-column justify-content-center align-items-center py-2" style="flex: 1; max-width: 180px;" :to="{ name: 'MessageCreate' }">
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 7.10006C9.5 7.00006 9.9 6.40006 9.7 5.90006C9.6 5.40006 9 5.00006 8.5 5.20006C5.8 5.80006 4 8.20006 4 11.0001C4 14.3001 6.7 17.0001 10 17.0001C13.1 17.0001 15.7 14.6001 16 11.5001C16.1 11.0001 15.6 10.5001 15.1 10.4001C14.6 10.3001 14.1 10.8001 14 11.3001C13.8 13.3001 12 15.0001 10 15.0001C7.8 15.0001 6 13.2001 6 11.0001C6 9.10006 7.2 7.50006 9 7.10006Z" fill="#106CFC"/>
          <path d="M19 9.99969C18.4 9.99969 18 10.3997 18 10.9997C18 15.3997 14.4 18.9997 10 18.9997C5.6 18.9997 2 15.3997 2 10.9997C2 6.79969 5.3 3.29969 9.5 2.99969C10.1 2.99969 10.5 2.49969 10.4 1.89969C10.4 1.29969 9.9 0.899689 9.3 0.999689C4.1 1.39969 0 5.69969 0 10.9997C0 16.4997 4.5 20.9997 10 20.9997C15.5 20.9997 20 16.4997 20 10.9997C20 10.3997 19.5 9.99969 19 9.99969Z" fill="#106CFC"/>
          <path d="M11.9 7.69961L9.3 10.2996C8.9 10.6996 8.9 11.2996 9.3 11.6996C9.5 11.8996 9.8 11.9996 10 11.9996C10.2 11.9996 10.5 11.8996 10.7 11.6996L13.3 9.09961L15 9.59961C15.1 9.59961 15.2 9.59961 15.3 9.59961C15.6 9.59961 15.8 9.49961 16 9.29961L19.9 5.29961C20.1 5.09961 20.2 4.69961 20.2 4.39961C20.2 4.09961 19.9 3.79961 19.6 3.69961L17.8 3.09961L17.2 1.29961C17.1 0.999609 16.8 0.699609 16.5 0.699609C16.2 0.599609 15.8 0.699609 15.6 0.999609L11.7 4.99961C11.4 5.29961 11.3 5.69961 11.5 5.99961L11.9 7.69961ZM15.8 3.59961L16.1 4.29961C16.2 4.59961 16.4 4.79961 16.7 4.89961L17.4 5.19961L15 7.59961L13.8 7.19961L13.4 5.99961L15.8 3.59961Z" fill="#106CFC"/>
        </svg>
        <span>New</span>
      </router-link>
      <router-link class="text-decoration-none btn border-primary d-flex flex-column justify-content-center align-items-center py-2" style="flex: 1; max-width: 180px;" :to="{ name: 'MessagesEdit' }">
        <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.5703 1.46875H19.0078C19.132 1.46843 19.2545 1.49774 19.3652 1.55424C19.4758 1.61074 19.5714 1.69281 19.6439 1.79364C19.7165 1.89447 19.764 2.01114 19.7824 2.13399C19.8009 2.25684 19.7898 2.38231 19.75 2.5L15.5859 15C15.5335 15.1552 15.4337 15.29 15.3005 15.3853C15.1673 15.4806 15.0075 15.5317 14.8438 15.5313H16.4062C16.57 15.5317 16.7298 15.4806 16.863 15.3853C16.9962 15.29 17.096 15.1552 17.1484 15L21.3125 2.5C21.3523 2.38231 21.3634 2.25684 21.3449 2.13399C21.3265 2.01114 21.279 1.89447 21.2064 1.79364C21.1339 1.69281 21.0383 1.61074 20.9277 1.55424C20.817 1.49774 20.6945 1.46843 20.5703 1.46875Z" fill="#106CFC"/>
          <path d="M9.15626 7.71851C9.0194 7.71946 8.8847 7.68443 8.76563 7.61695L3.29688 4.49195C3.11662 4.38835 2.98489 4.21738 2.93068 4.01666C2.87647 3.81594 2.90422 3.6019 3.00782 3.42164C3.11142 3.24137 3.28239 3.10965 3.48311 3.05544C3.68383 3.00123 3.89787 3.02898 4.07813 3.13258L9.22657 6.07789L17.4844 3.07789C17.6775 3.01415 17.8879 3.02824 18.0708 3.11715C18.2537 3.20606 18.3947 3.3628 18.4639 3.55405C18.5331 3.7453 18.5249 3.95598 18.4412 4.14133C18.3575 4.32668 18.2048 4.47208 18.0156 4.54664L9.42188 7.67164C9.33673 7.70254 9.24685 7.71841 9.15626 7.71851Z" fill="#106CFC"/>
          <path d="M7.59375 13.9688H2.90625C2.69905 13.9688 2.50034 13.8864 2.35382 13.7399C2.20731 13.5934 2.125 13.3947 2.125 13.1875C2.125 12.9803 2.20731 12.7816 2.35382 12.6351C2.50034 12.4886 2.69905 12.4062 2.90625 12.4062H7.59375C7.80095 12.4062 7.99966 12.4886 8.14618 12.6351C8.29269 12.7816 8.375 12.9803 8.375 13.1875C8.375 13.3947 8.29269 13.5934 8.14618 13.7399C7.99966 13.8864 7.80095 13.9688 7.59375 13.9688Z" fill="#106CFC"/>
          <path d="M6.8125 10.8438H1.34375C1.13655 10.8438 0.937836 10.7614 0.791323 10.6149C0.64481 10.4684 0.5625 10.2697 0.5625 10.0625C0.5625 9.8553 0.64481 9.65659 0.791323 9.51007C0.937836 9.36356 1.13655 9.28125 1.34375 9.28125H6.8125C7.0197 9.28125 7.21841 9.36356 7.36493 9.51007C7.51144 9.65659 7.59375 9.8553 7.59375 10.0625C7.59375 10.2697 7.51144 10.4684 7.36493 10.6149C7.21841 10.7614 7.0197 10.8438 6.8125 10.8438Z" fill="#106CFC"/>
          <path d="M4.46875 7.71875H2.125C1.9178 7.71875 1.71909 7.63644 1.57257 7.48993C1.42606 7.34341 1.34375 7.1447 1.34375 6.9375C1.34375 6.7303 1.42606 6.53159 1.57257 6.38507C1.71909 6.23856 1.9178 6.15625 2.125 6.15625H4.46875C4.67595 6.15625 4.87466 6.23856 5.02118 6.38507C5.16769 6.53159 5.25 6.7303 5.25 6.9375C5.25 7.1447 5.16769 7.34341 5.02118 7.48993C4.87466 7.63644 4.67595 7.71875 4.46875 7.71875Z" fill="#106CFC"/>
          <path d="M16.4063 16.3125H2.90625C2.69905 16.3125 2.50034 16.2302 2.35382 16.0837C2.20731 15.9372 2.125 15.7385 2.125 15.5312C2.125 15.324 2.20731 15.1253 2.35382 14.9788C2.50034 14.8323 2.69905 14.75 2.90625 14.75H16.4063L20.5703 2.25H1.34375C1.13655 2.25 0.937836 2.16769 0.791323 2.02118C0.64481 1.87466 0.5625 1.67595 0.5625 1.46875C0.5625 1.26155 0.64481 1.06284 0.791323 0.916324C0.937836 0.769811 1.13655 0.687501 1.34375 0.687501H20.5703C20.8178 0.687276 21.0619 0.745863 21.2823 0.858436C21.5028 0.97101 21.6933 1.13435 21.8383 1.335C21.9832 1.53564 22.0785 1.76786 22.1161 2.01251C22.1537 2.25717 22.1327 2.50726 22.0547 2.74219L17.8906 15.2422C17.7871 15.554 17.588 15.8252 17.3215 16.0174C17.055 16.2095 16.7348 16.3128 16.4063 16.3125Z" fill="#106CFC"/>
        </svg>
        <span>Undelivered</span>
      </router-link>
      <router-link class="text-decoration-none btn border-primary d-flex flex-column justify-content-center align-items-center py-2" style="flex: 1; max-width: 180px;" :to="{ name: 'AccountMessages' }">
        <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.7812 17.4062H0.71875V1.59375H22.2812V8.78125" stroke="#106CFC" stroke-width="2" stroke-linejoin="round"/>
          <path d="M0.71875 1.59375L11.5 9.5L22.2812 1.59375" stroke="#106CFC" stroke-width="2" stroke-linejoin="round"/>
          <path d="M14.375 13.8125L16.5312 15.9688L21.5625 10.9375" stroke="#106CFC" stroke-width="2" stroke-linejoin="round"/>
        </svg>
        <span>History</span>
      </router-link>
      <router-link class="text-decoration-none btn border-primary d-flex flex-column justify-content-center align-items-center py-2" style="flex: 1; max-width: 180px;" :to="{ name: 'Account' }">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 21.3125C16.6954 21.3125 21.3125 16.6954 21.3125 11C21.3125 5.30456 16.6954 0.6875 11 0.6875C5.30456 0.6875 0.6875 5.30456 0.6875 11C0.6875 16.6954 5.30456 21.3125 11 21.3125Z" stroke="#106CFC" stroke-width="2" stroke-miterlimit="10" stroke-linejoin="round"/>
          <path d="M17.875 18.5625C17.875 14.7654 14.7971 11.6875 11 11.6875C7.20294 11.6875 4.125 14.7654 4.125 18.5625" stroke="#106CFC" stroke-width="2" stroke-miterlimit="10" stroke-linejoin="round"/>
          <path d="M11 11.6875C13.2782 11.6875 15.125 9.84067 15.125 7.5625C15.125 5.28433 13.2782 3.4375 11 3.4375C8.72183 3.4375 6.875 5.28433 6.875 7.5625C6.875 9.84067 8.72183 11.6875 11 11.6875Z" stroke="#106CFC" stroke-width="2" stroke-miterlimit="10" stroke-linejoin="round"/>
        </svg>
        <span>Account</span>
      </router-link>
    </div>

    <LoadingScreen v-if="!transactions" />
    <template v-else>
      <div class="mb-2 fs-18 fw-semibold">
        Your recent Messages
      </div>
      <MessageList :key="transactions" :transactions="transactions" isFilters isFilteredSequence />
    </template>
  </div>
</template>

<script setup>
// import { VTooltip } from 'floating-vue'
import * as bootstrap from 'bootstrap'
import IconMail from '@/assets/icons/delivery-mail-white.svg'
import IconEmail from '@/assets/icons/delivery-email-white.svg'
import IconSms from '@/assets/icons/delivery-sms-white.svg'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
// import Tour from '@/components/Tour.vue'
import GetStarted from '@/components/GetStarted.vue'
import MessageList from '@/components/MessageList.vue'

const store = useStore()
const transactions = computed(() => {
  const trans = store.state.transactions.tempData

  return trans && trans.sort((a, b) => {
    return new Date(b.updatedAt) - new Date(a.updatedAt)
  })//.slice(0, 20)
})

const currentUser = computed(() => store.state.user.currentUser)
const templates = computed(() => store.state.templates.data)

const carouselItems = computed(() => {
  const settings = store.state.settings.data
  const categories = store.state.categories.data
  const carousels = settings && settings.filter(it => /carousel/i.test(it.label))

  // get specific template
  function getTemplate (templateId) {
    return templates.value && templates.value.find(it => it.id === templateId)
  }

  const formatIcons = {
    SMS: IconSms,
    Email: IconEmail,
    Mail: IconMail,
  }

  // get all delivery methods of template
  function getTemplates (categoryId, useCaseId) {
    return templates.value
      .filter(temp => temp.categoryId === categoryId && temp.useCaseId === useCaseId && (!temp.sequenceNumber || temp.sequenceNumber === 1) && temp.enabled)
      .sort((a, b) => {
        const formatOrder = { 'SMS': 1, 'Email': 2, 'Mail': 3 }

        const formatA = a.format
        const formatB = b.format

        return formatOrder[formatA] - formatOrder[formatB]
      })
      .map(({ format, id }) => ({ format, id, icon: formatIcons[format] }))
  }

  const carouselItems = carousels && carousels.map(it => {
    return getTemplate(it.value)
  }).filter(it => it !== undefined && it !== null)

  // Function to get the category name based on categoryId
  function getCategory (categoryId) {
    return categories.find((cat) => cat.id === categoryId)
  }

  // Function to get the useCase name based on useCaseId
  function getUseCase (categoryId, useCaseId) {
    const category = categories.find((cat) => cat.id === categoryId)
    if (category) {
      return category.useCases.find((uc) => uc.id === useCaseId)
    }
  }

  if (carouselItems) {
    // Iterate over templates and get category and useCase names
    return carouselItems && carouselItems.map((it) => {
      return it && {
        id: it.id,
        category: {
          ...getCategory(it.categoryId),
          useCases: []
        },
        useCase: { ...getUseCase(it.categoryId, it.useCaseId) },
        templates: getTemplates(it.categoryId, it.useCaseId)
      }
    })
  }

  return null
})

function setTransaction (carouselItem, template) {
  const getTemplate = templates.value.find((it) => it.id === template.id)

  const transaction = {
    accountId: currentUser.value.uniqueID,
    deliveryMethod: getTemplate.format,
    footer: getTemplate.footer || { content: '', show: false },
    header: getTemplate.header || { content: '', show: false },
    messageTemplateId: getTemplate.id,
    name: '',
    paragraphs: getTemplate.paragraphs || [],
    sequenceNumber: 1,
    status: 1,
    updatedAt: new Date().toISOString(),
  }

  store.dispatch('transactions/create', {
    ...transaction,
    category: carouselItem.category.name,
    useCase: carouselItem.useCase.name
  })
}

onMounted(() => {
  var myCarousel = document.getElementById('carouselTemplates')
  new bootstrap.Carousel(myCarousel, {
    interval: 3500
  })
})
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
  .carousel {
    &-inner {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &-item {
      &__wrap {
        flex-direction: column-reverse !important;
      }

      &__text {
        padding: 1rem 1.5rem;
        text-align: center;

        h5, p {
          text-align: center;
        }
      }

      img {
        width: 50% !important;
        height: 100% !important;
      }
    }
  }
}
</style>

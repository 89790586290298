<template>
  <div class="modal fade" id="modalPreview" tabindex="-1" aria-labelledby="modalPreviewLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalPreviewLabel">Preview Mail</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <template v-if="failedMessage">
            {{ failedMessage }}
          </template>
          <VuePdfEmbed ref="pdfEmbed" :source="props.file" @rendered="rendered" @rendering-failed="failed" @loading-failed="failed" width="550" />
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import VuePdfEmbed from 'vue-pdf-embed'
import { ref, defineProps } from 'vue'

const props = defineProps(['file'])
const pdfEmbed = ref(null)
const failedMessage = ref(null)

const rendered = () => {
  const canvas = pdfEmbed.value.$el.querySelector('canvas')

  if (!canvas) {
    failedMessage.value = 'Failed loading the pdf. Try refreshing the page.'
  }
}

const failed = (err) => {
  console.log('failed: ', err)
  failedMessage.value = 'Failed loading the pdf. Try refreshing the page.'
}
</script>

<style lang="scss" scoped>
.modal {
  &-dialog {
    max-width: 600px;
  }

  &-body {
    background-color: #ccc;
    max-height: 70vh;
    overflow: auto;
  }
}

#modalPreview ::v-deep(.vue-pdf-embed) > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
</style>

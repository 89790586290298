<template>
  <LoadingScreen v-if="isLoading" />
  <div class="modal fade" id="modalTopupMessageEdit" tabindex="-1" aria-labelledby="modalTopupMessageEditLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalTopupMessageEditLabel">Purchase Credit</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            Message Cost: <strong>${{ props.transaction?.sellPrice?.total }}</strong>
          </div>
          <div id="card-container-messageEdit"></div>
          <button
            id="card-button"
            class="btn btn-primary rounded-pill w-100 mb-3"
            :disabled="!props.transaction?.sellPrice?.total"
            type="button"
          >
            Pay and Send
          </button>
          <div v-if="isError" class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>Error!</strong> {{ errorMsg }}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
          <div v-if="isSuccess" class="alert alert-success alert-dismissible fade show" role="alert" style="width: fit-content">
            You have successfully added <strong>${{ parseFloat(topupResponse.result.payment.amountMoney.amount / 100).toFixed(2) }}</strong> credit to your account making your current account balance <strong>${{ parseFloat(user.credits).toFixed(2) }}</strong>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
        <div class="modal-footer">
          <button id="modalTopupMessageEditClose" class="btn btn-secondary" data-bs-dismiss="modal" type="button">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, onMounted, ref } from 'vue'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useStore } from 'vuex'

const functions = getFunctions()
const emit = defineEmits(['send'])
const props = defineProps(['transaction', 'user'])
const store = useStore()

const errorMsg = ref('')
const isError = ref(false)
const isLoading = ref(false)
const isSuccess = ref(false)
const topupResponse = ref(null)

const user = computed(() => store.state.user.currentUser)

async function processTopup (token) {
  return store.dispatch('user/updateProfile', {
    ...props.user,
    isSending: true
  }).then(async () => {
    const topupCredits = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_topupCredits`)

    const params = {
      ...props.user,
      token,
      selectedCredits: Math.round(props.transaction?.sellPrice?.total * 100) / 100
    }

    return topupCredits(params).then((res) => {
      topupResponse.value = res.data

      if (res.data.status === 'success') {
        emit('send', true)
      }
      return res.data
    })
  })
}

onMounted(() => {
  const script = document.createElement('script')
  script.src = process.env.VUE_APP_SQUARE_CDN
  document.head.appendChild(script)

  script.onload = () => {
    const appId = process.env.VUE_APP_SQUARE_APP_ID
    const locationId = process.env.VUE_APP_SQUARE_LOCATION_ID

    async function initializeCard (payments) {
      const card = await payments.card()
      await card.attach('#card-container-messageEdit')
      return card
    }

    (async function () {
      if (!window.Square) {
        throw new Error('Square.js failed to load properly')
      }

      const payments = window.Square.payments(appId, locationId)
      let card
      try {
        errorMsg.value = ''
        card = await initializeCard(payments)

        // eslint-disable-next-line no-inner-declarations
        async function eventHandler (event) {
          isLoading.value = true
          isError.value = false
          isSuccess.value = false
          errorMsg.value = 'Unable to process payment. Try again!'

          event.preventDefault()
          try {
            const tokenizeCard = await card.tokenize()
            if (tokenizeCard.status === 'OK') {
              const payment = await processTopup(tokenizeCard.token)

              if (payment.status === 'success') {
                isSuccess.value = true
                isLoading.value = false

                // close modal
                document.getElementById('modalTopupMessageEditClose').click()

                // await card.destroy()
              } else {
                isError.value = true
                isLoading.value = false
                errorMsg.value = 'Unable to process payment. Try again!'
              }
            } else {
              isError.value = true
              isLoading.value = false
              errorMsg.value = 'Unable to process payment. Try again!'
            }
          } catch (e) {
            console.error(e)
          }
        }

        const cardButton = document.getElementById('card-button')
        cardButton.addEventListener('click', eventHandler)
      } catch (e) {
        console.error('Initializing Card failed', e)
        return
      }
    })()
  }

  script.onerror = (error) => {
    console.error('Failed to load Square.js:', error)
    errorMsg.value = 'Failed to load payment gateway. Try refreshing browser or contact support'
    isError.value = true
  }

  document.head.appendChild(script)
})
</script>

<template>
  <LoadingScreen v-if="!user || isSaving" />
  <div v-if="user">
    <div class="text-center mb-5">
      <div class="fs-4 fw-semibold">
        Distribution List
      </div>
      <span class="text-muted">Distribution List for multiple receivers</span>
    </div>
    <button
      class="btn btn-primary mb-3"
      data-bs-target="#modalDistNew"
      data-bs-toggle="modal"
    >
      Create Distribution List
    </button>
    <div v-if="user.distributions" class="accordion">
      <div v-for="(dist, distIdx) in user.distributions" :key="distIdx" class="accordion-item">
        <h2 class="accordion-header" :id="'panelsStayOpen-heading' + distIdx">
          <button
            :data-bs-target="'#panelsStayOpen-collapse' + distIdx"
            aria-expanded="false"
            class="accordion-button collapsed d-flex gap-2"
            data-bs-toggle="collapse"
            type="button"
          >
            <VTooltip :triggers="['hover', 'click']">
              <img :src="dist.method === 'sms' ? IconSms : IconEmail" alt="">
              <template #popper>
                {{ dist.method === 'sms' ? 'SMS' : 'Email' }}
              </template>
            </VTooltip>
            <strong>{{ dist.name }}</strong>
            <VTooltip :triggers="['hover', 'click']">
              <strong>- {{ dist.recipients.length }}</strong>
              <template #popper>
                {{ dist.recipients.length }} Receivers
              </template>
            </VTooltip>
            <div
              class="text-secondary"
              data-bs-target="#modalDistUpdate"
              data-bs-toggle="modal"
              style="cursor: pointer;"
              @click="selectedDist = dist"
            >
              <VTooltip :triggers="['hover']">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="20" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
                <template #popper>
                  Edit Distribution List
                </template>
              </VTooltip>
            </div>
            <div
              class="text-danger"
              style="cursor: pointer; position: absolute; right: 50px;"
              @click="deleteDist(dist)"
            >
              <VTooltip :triggers="['hover']">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="20" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                <template #popper>
                  Delete Distribution List
                </template>
              </VTooltip>
            </div>
          </button>
        </h2>
        <div
          :id="'panelsStayOpen-collapse' + distIdx"
          :aria-labelledby="'panelsStayOpen-heading' + distIdx"
          class="accordion-collapse collapse"
        >
          <div class="accordion-body bg-light bg-gradient">
            <div style="max-height: 250px; overflow: auto;">
              <div v-for="(recipient, recipientIdx) in dist.recipients" :key="recipientIdx">
                <strong>{{ recipientIdx + 1 }}.</strong> {{ recipient }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalNewDist :user="user" @saveNewDist="saveNewDist" />
    <ModalEditDist :user="user" :selectedDist="selectedDist" @updateDist="updateDist" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import ModalNewDist from '@/components/AccountDistributions/ModalNewDist.vue'
import ModalEditDist from '@/components/AccountDistributions/ModalEditDist.vue'
import IconEmail from '@/assets/icons/delivery-email.svg'
import IconSms from '@/assets/icons/delivery-sms.svg'

const store = useStore()
const isSaving = ref(false)
const selectedDist = ref(null)
const user = computed(() => store.state.user.currentUser)

async function deleteDist (dist) {
  if (confirm(`Are you sure you want to delete ${dist.name}?`)) {
    isSaving.value = true
    const currentDist = user.value.distributions ? Object.values(user.value.distributions) : []

    const index = currentDist.findIndex(it => it.id === dist.id)
    if (index !== -1) {
      currentDist.splice(index, 1)
    }

    try {
      await store.dispatch('user/updateProfile', {
        ...user.value,
        distributions: currentDist
      })
    } catch (error) {
      console.error('Error updating profile:', error)
    } finally {
      isSaving.value = false
    }
  }
}

async function saveNewDist (dist) {
  isSaving.value = true
  document.getElementById('modalNewDistClose').click()
  const currentDist = user.value.distributions ? Object.values(user.value.distributions) : []
  currentDist.push(dist)

  try {
    await store.dispatch('user/updateProfile', {
      ...user.value,
      distributions: currentDist
    })
  } catch (error) {
    console.error('Error updating profile:', error)
  } finally {
    isSaving.value = false
  }
}

async function updateDist (dist) {
  isSaving.value = true
  document.getElementById('modalEditDistClose').click()
  const currentDist = user.value.distributions ? Object.values(user.value.distributions) : []

  const updatedDist = currentDist.map(it => {
    if (it.id === dist.id) {
      return dist
    }

    return it
  })

  try {
    await store.dispatch('user/updateProfile', {
      ...user.value,
      distributions: updatedDist
    })
  } catch (error) {
    console.error('Error updating profile:', error)
  } finally {
    isSaving.value = false
  }
}
</script>

<template>
  <div>
    <div class="mb-2 fs-4 fw-semibold">Status Icons</div>
    <div v-for="icon in icons" class="d-flex gap-3 align-items-center mb-3" :key="icon">
      <img :src="icon.icon" width="48">
      <span class="fw-bold">{{ icon.label }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import IconMail from '@/assets/icons/delivery-mail.svg'
import IconEmail from '@/assets/icons/delivery-email.svg'
import IconSms from '@/assets/icons/delivery-sms.svg'
import IconDelivered from '@/assets/icons/status-delivered.svg'
import IconFailed from '@/assets/icons/status-failed.svg'
import IconIncomplete from '@/assets/icons/status-incomplete.svg'
import IconProcessing from '@/assets/icons/status-processing.svg'
import IconQueue from '@/assets/icons/status-queue.svg'
import IconReadyToSend from '@/assets/icons/status-readyToSend.svg'
import IconSending from '@/assets/icons/status-sending.svg'
import IconDistribution from '@/assets/icons/distribution-list.svg'

const icons = ref([
  { icon: IconMail, label: 'Mail' },
  { icon: IconEmail, label: 'Email' },
  { icon: IconSms, label: 'SMS' },
  { icon: IconDelivered, label: 'Delivered / Completed' },
  { icon: IconFailed, label: 'Failed' },
  { icon: IconIncomplete, label: 'Incomplete Details' },
  { icon: IconProcessing, label: 'Processing Mail' },
  { icon: IconQueue, label: 'In Queue to be sent to receiver' },
  { icon: IconReadyToSend, label: 'Ready To Send' },
  { icon: IconSending, label: 'Sending' },
  { icon: IconDistribution, label: 'Distribution List' }
])
</script>

<style lang="scss" scoped>

</style>

<template>
  <Menu v-if="user && currentRouteRef !== 'AuthAction'" />
  <div :class="{'container py-3 px-4': currentRouteRef !== 'Login' && currentRouteRef !== 'Signup'}">
    <router-view />
  </div>
</template>

<script setup>
import '@/firebase'
import { useRouter } from 'vue-router'
import { computed, onMounted, watch, ref } from 'vue'
import { useStore } from 'vuex'
import Menu from '@/components/Menu.vue'
import useGoogleRecaptcha from '@/utils/useGoogleRecaptcha' // Import the composition function
const { recaptchaResponse, loadRecaptcha, executeRecaptcha } = useGoogleRecaptcha()

const router = useRouter()
const store = useStore()
const user = computed(() => store.state.user.currentUser)
const currentRouteRef = ref(null)

watch(
  router.currentRoute,
  (route) => {
    currentRouteRef.value = route.name
  },
  { deep: true, immediate: true }
)

onMounted (async () => {
  // test code
  await store.dispatch('user/getCurrentUser')
  loadRecaptcha()

  setTimeout(async () => {
    await executeRecaptcha()
    await store.dispatch('recaptcha/set', recaptchaResponse.value)
  }, 500)
})
</script>

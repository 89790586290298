<template>
  <div class="modal fade" id="modalEditName" tabindex="-1" aria-labelledby="modalEditNameLabel" aria-hidden="true">
    <div class="modal-dialog">
      <form class="modal-content" @submit.prevent="save">
        <div class="modal-header">
          <h5 class="modal-title" id="modalEditNameLabel">Update Name</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <input
            v-model="firstName"
            class="form-control mb-3"
            placeholder="First Name"
            required
            type="text"
          >
          <input
            v-model="lastName"
            class="form-control mb-1"
            placeholder="Last Name"
            required
            type="text"
          >
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">
            Cancel
          </button>
          <button
            class="btn btn-primary"
            :disabled="!firstName || !lastName"
            :data-bs-dismiss="firstName && lastName ? 'modal' : null"
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { useStore } from 'vuex'

const props = defineProps(['user'])
const firstName = ref(props.user.firstName || '')
const lastName = ref(props.user.lastName || '')
const store = useStore()

async function save () {
  store.dispatch('user/updateProfile', {
    ...props.user,
    firstName: firstName.value,
    lastName: lastName.value
  })
}
</script>

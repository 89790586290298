<template>
  <div class="modal fade" id="modalEditCountry" tabindex="-1" aria-labelledby="modalEditCountryLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalEditCountryLabel">Update Country</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <v-select
            v-model="country"
            :options="countries"
            label="name"
          />
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">
            Cancel
          </button>
          <button
            class="btn btn-primary"
            data-bs-dismiss="modal"
            :disabled="props.user.country.name === country.name"
            type="button"
            @click="save"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import countries from '@/utils/CountryCodes.json'
import { defineProps, ref } from 'vue'
import { useStore } from 'vuex'

const props = defineProps(['user'])
const country = ref(props.user.country ?? {
  name: 'Australia',
  code: 'AU',
  dialCode: '+61'
})
const store = useStore()

async function save () {
  store.dispatch('user/updateProfile', {
    ...props.user,
    country: country.value
  })
}
</script>

<template>
  <form @submit.prevent="submitForm">
    <label for="textareaFeedback" class="form-label mb-2 fs-4 fw-semibold">Feedback and Suggestions</label>
    <textarea v-model="feedback" required class="form-control mb-3" id="textareaFeedback" rows="10"></textarea>
    <button class="btn btn-primary" :disabled="isSending">Submit</button>
  </form>
  <div v-if="isSuccess" class="alert alert-success alert-dismissible fade show mt-4" role="alert">
    Successfully submitted feedback!
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
  <div v-if="isFail" class="alert alert-danger alert-dismissible mt-4" role="alert">
    Something went wrong.
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { getFunctions, httpsCallable } from 'firebase/functions'

const functions = getFunctions()
const store = useStore()
const feedback = ref('')
const isSending = ref(false)
const isSuccess = ref(false)
const isFail = ref(false)

const user = computed(() => store.state.user.currentUser)

const submitForm = () => {
  if (!feedback.value) return

  isSending.value = true
  const feedbackSave = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_feedbackSave`)

  feedbackSave({ accountId: user.value.uniqueID, feedback: feedback.value }).then(res => {
    if (res.data) {
      isSuccess.value = true
      isSending.value = false
      feedback.value = ''
    } else {
      isFail.value = true
    }
  })
}
</script>

import { computed } from 'vue'
import store from '../store/index.js'
import { DateTime, Duration } from 'luxon'

const categories = computed(() => store.state.categories.data)
const templates = computed(() => store.state.templates.data)
const user = computed(() => store.state.user.currentUser)

export function convertStatus (status) {
  switch (status) {
    case 1:
      return 'Incomplete Details'
    case 2:
      return 'Processing Message'
    case 3:
      return 'Ready To Send'
    case 4:
      return 'Sending'
    case 5:
      return 'Delivered'
    case 6:
      return 'Failed'
    case 7:
      return 'Marked as Spam'
    case 8:
      return 'Marked as Spam'
    case 9:
      return 'In Queue'
    default:
      return '-'
  }
}

export function getCategoryAndUseCase (transaction) {
  const template = templates.value.find((it) => it.id === transaction.messageTemplateId)

  if (template) {
    const category = categories.value.find((it) => it.id === template.categoryId)
    const useCase = category.useCases.find(it => it.id === template.useCaseId)

    return category.name + ' - ' + (useCase.name || '')
  }

  return 'No category'
}

export function getTimePassed (time) {
  const start = DateTime.fromISO(time)
  const end = DateTime.now()
  var diff = end.diff(start, 'seconds')
  const seconds = diff.toObject().seconds

  const duration = Duration.fromObject({
    seconds: seconds
  })

  const daysAgo = Math.ceil(duration.shiftTo('days').toObject().days)

  // if (daysAgo === 1) {
  //   return 'Today'
  // } else {
  //   return daysAgo + ' days ago'
  // }
  return daysAgo
}

// true = equal, false = not equal
export function compareDeliveryDetails (obj1, obj2) {
  const keys1 = Object.keys(obj1).filter(key => key !== 'placeId')
  const keys2 = Object.keys(obj2).filter(key => key !== 'placeId')

  if (keys1.length !== keys2.length) {
    return false
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }

  return true
}

export async function createNextSequence (transaction) {
  // get the template of the nextSequence
  const currentTemplate = templates.value.find(it => it.id === transaction.messageTemplateId)
  const nextTemplate = templates.value.find(it => {
    return it.categoryId === currentTemplate.categoryId &&
      it.useCaseId === currentTemplate.useCaseId &&
      it.format === currentTemplate.format &&
      it.sequenceNumber === transaction.sequenceNumber + 1
  })

  // create the transaction
  const newTransaction = {
    accountId: user.value.uniqueID,
    category: transaction.category,
    deliveryDetails: transaction.deliveryDetails || null,
    deliveryDistribution: transaction.deliveryDistribution || null,
    deliveryMethod: nextTemplate.format,
    footer: nextTemplate.footer || null,
    header: nextTemplate.header || null,
    messageTemplateId: nextTemplate.id,
    name: '', //blank
    paragraphs: nextTemplate.paragraphs || [],
    sequenceNumber: transaction.sequenceNumber + 1,
    status: 1,
    updatedAt: new Date().toISOString(),
    useCase: transaction.useCase,
  }

  await store.dispatch('transactions/create', newTransaction)
}

// true = converted, false = not converted
export async function convertToNextSequence (origTransaction, duplicateTransactionId) {
  // get the template of the nextSequence
  const currentTemplate = templates.value.find(it => it.id === origTransaction.messageTemplateId)
  const nextTemplate = templates.value.find(it => {
    return it.categoryId === currentTemplate.categoryId &&
      it.useCaseId === currentTemplate.useCaseId &&
      it.format === currentTemplate.format &&
      it.sequenceNumber === origTransaction.sequenceNumber + 1
  })

  if (nextTemplate) {
    // create the transaction
    const newTransaction = {
      accountId: user.value.uniqueID,
      category: origTransaction.category,
      deliveryDetails: origTransaction.deliveryDetails || null,
      deliveryDistribution: origTransaction.deliveryDistribution || null,
      deliveryMethod: nextTemplate.format,
      footer: nextTemplate.footer || null,
      header: nextTemplate.header || null,
      id: duplicateTransactionId,
      messageTemplateId: nextTemplate.id,
      name: '', //blank
      paragraphs: nextTemplate.paragraphs || [],
      sequenceNumber: origTransaction.sequenceNumber + 1,
      status: 1,
      updatedAt: new Date().toISOString(),
      useCase: origTransaction.useCase,
    }

    if (currentTemplate.format === 'Mail') {
      document.getElementById('modalDeliveryMailClose').click()
    }
    await store.dispatch('transactions/update', newTransaction)
    return true
  }

  return false
}

<template>
  <LoadingScreen v-if="isLoading" />
  <div class="signup d-flex">
    <div class="signup-form w-50 px-5 d-flex flex-column justify-content-center">
      <div class="fs-1 mx-auto position-relative mb-3 d-table">
        <span class="text-primary">sendamessage</span>.com.au
        <div class="text-muted d-flex justify-content-end" style="font-size: 12px; position: absolute; right: 0; bottom: -10px;">
          <span class="text-primary">Don't say it,&nbsp;</span>send it
        </div>
      </div>
      <div class="fs-20 text-center fw-bold mb-3">
        Create your account in seconds!
      </div>
      <!-- <p class="fs-14 text-muted text-center mb-4">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
      </p> -->

      <form @submit.prevent="">
        <div v-if="isError" class="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Error!</strong> {{ errorMessage }}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <!-- <div class="mb-3">
          <label class="text-danger mb-2" for="firstName">Country</label>
          <country-select
            v-model="country"
            className="form-control"
            :country="country"
            :countryName="true"
            :disablePlaceholder="true"
          />
        </div> -->
        <div class="mb-3">
          <input
            v-model="firstName"
            autocomplete="off"
            class="form-control py-2"
            id="firstName"
            placeholder="First Name"
            required
            type="text"
          >
        </div>
        <div class="mb-3">
          <input
            v-model="lastName"
            autocomplete="off"
            class="form-control py-2"
            id="lastName"
            placeholder="Last Name"
            required
            type="text"
          >
        </div>
        <div class="mb-3">
          <input
            v-model="phone"
            autocomplete="off"
            class="form-control py-2"
            id="phone"
            placeholder="Phone Number"
            required
            type="text"
          >
        </div>
        <div class="mb-3">
          <select v-model="gender" id="gender" class="form-select py-2" required>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>
        <!-- <div class="mb-3">
          <v-select
            v-model="country"
            :options="countries"
            label="name"
          />
        </div> -->
        <div class="mb-3">
          <input
            v-model="email"
            autocomplete="off"
            class="form-control py-2"
            id="email"
            placeholder="Email"
            required
            type="email"
          >
        </div>
        <div class="mb-3 position-relative">
          <input
            v-model="password"
            id="password"
            class="form-control py-2"
            placeholder="Password"
            required
            type="password"
          >
        </div>
        <button
          class="btn btn-primary fw-bold py-2 w-100 mb-4"
          data-bs-target="#modalTerms" :data-bs-toggle="isFormDone ? 'modal' : ''"
          @click="signupMode = 1"
        >
          Create an Account
        </button>
        <div class="text-muted text-center position-relative mb-4">
          <span class="bg-white position-relative px-5" style="z-index: 1;">OR</span>
          <hr class="position-absolute w-100" style="top: -4px;">
        </div>
        <button
          class="mb-4 btn btn-outline-primary d-flex align-items-center justify-content-center py-2 gap-2 w-100 text-dark"
          data-bs-target="#modalTerms" data-bs-toggle="modal"
          type="button"
          @click="signupMode = 2"
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99999 4.16667C11.3473 4.16667 12.5844 4.62794 13.5719 5.39505L16.6029 2.50341C14.8406 0.949961 12.5339 0 9.99999 0C6.16032 0 2.83055 2.1666 1.15483 5.34154L4.52519 8.00232C5.34164 5.76614 7.48123 4.16667 9.99999 4.16667Z" fill="#F44336"/>
            <path d="M19.9134 11.2519C19.9655 10.8422 20 10.4242 20 10.0003C20 9.28545 19.9219 8.58935 19.7794 7.91699H10V12.0837H15.4052C14.9679 13.2201 14.1894 14.1818 13.1983 14.8499L16.5812 17.5206C18.3745 15.9465 19.6016 13.7423 19.9134 11.2519Z" fill="#2196F3"/>
            <path d="M4.16667 10.0003C4.16667 9.29738 4.29738 8.62661 4.5252 8.00257L1.15484 5.3418C0.420278 6.7336 0 8.31706 0 10.0003C0 11.6646 0.412547 13.2305 1.13185 14.6111L4.50648 11.9469C4.29036 11.3373 4.16667 10.6839 4.16667 10.0003Z" fill="#FFC107"/>
            <path d="M10 15.833C7.46206 15.833 5.30869 14.2093 4.50648 11.9463L1.13185 14.6105C2.79938 17.8109 6.14151 19.9997 10 19.9997C12.5231 19.9997 14.824 19.0623 16.5812 17.5199L13.1983 14.8492C12.2843 15.4654 11.1903 15.833 10 15.833Z" fill="#00B060"/>
            <path opacity="0.1" d="M10 19.7915C7.05694 19.7915 4.41061 18.5772 2.53963 16.6426C4.37114 18.698 7.03039 19.9998 10 19.9998C12.9422 19.9998 15.5794 18.7237 17.4068 16.7005C15.5413 18.6037 12.9151 19.7915 10 19.7915Z" fill="black"/>
            <path opacity="0.1" d="M10 11.875V12.0833H15.4052L15.4896 11.875H10Z" fill="black"/>
            <path d="M19.9954 10.1229C19.9962 10.0818 20.0009 10.0415 20.0009 10.0004C20.0009 9.98871 19.9988 9.97747 19.9987 9.96582C19.998 10.0183 19.9948 10.0702 19.9954 10.1229Z" fill="#E6E6E6"/>
            <path opacity="0.2" d="M10 7.91699V8.12533H19.8213C19.8082 8.05656 19.7939 7.98525 19.7794 7.91699H10Z" fill="white"/>
            <path d="M19.7794 7.91667H10V12.0833H15.4052C14.5646 14.268 12.481 15.8333 10 15.8333C6.77836 15.8333 4.16667 13.2216 4.16667 10C4.16667 6.77831 6.77836 4.16667 10 4.16667C11.1682 4.16667 12.2449 4.52555 13.1571 5.11724C13.2967 5.20798 13.4408 5.29317 13.5719 5.39505L16.6029 2.50341L16.5345 2.45082C14.7808 0.930888 12.5029 0 10 0C4.47713 0 0 4.47713 0 10C0 15.5228 4.47713 20 10 20C15.0981 20 19.2962 16.1823 19.9134 11.2515C19.9655 10.8418 20 10.4239 20 10C20 9.28513 19.9219 8.58902 19.7794 7.91667Z" fill="url(#paint0_linear_265_28)"/>
            <path opacity="0.1" d="M13.1571 4.90843C12.2449 4.31674 11.1682 3.95786 10 3.95786C6.77837 3.95786 4.16667 6.5695 4.16667 9.79119C4.16667 9.82633 4.16714 9.8538 4.16775 9.88884C4.22396 6.71578 6.81351 4.16619 10 4.16619C11.1682 4.16619 12.2449 4.52508 13.1571 5.11676C13.2967 5.2075 13.4408 5.29269 13.5719 5.39457L16.6029 2.50293L13.5719 5.18624C13.4408 5.08436 13.2967 4.99917 13.1571 4.90843Z" fill="black"/>
            <path opacity="0.2" d="M10 0.208333C12.4792 0.208333 14.7358 1.12366 16.4827 2.618L16.6029 2.50341L16.5112 2.42356C14.7575 0.903633 12.5029 0 10 0C4.47713 0 0 4.47713 0 10C0 10.0351 0.00488283 10.0691 0.00523883 10.1042C0.0616963 4.62987 4.51228 0.208333 10 0.208333Z" fill="white"/>
            <defs>
            <linearGradient id="paint0_linear_265_28" x1="0" y1="10" x2="20" y2="10" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" stop-opacity="0.2"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
            </linearGradient>
            </defs>
          </svg>
          Sign up with Google
        </button>
        <div class="mb-3 fs-12 text-center">
          By signing up, you are creating a SendAMessage account, and you agree to SendAMessage's
          <span
            class="text-primary"
            data-bs-target="#modalTerms"
            data-bs-toggle="modal"
            style="cursor: pointer;"
            @click="signupMode = null"
          >
            Terms of Use and Privacy Policy
          </span>
        </div>
        <!-- <div class="mb-4"> -->
          <!-- <label class="text-danger mb-2"><small>*Optional on Sign up but required for sending Mail</small></label>
          <div class="d-flex justify-content-between">
            <button class="btn btn-success w-100" data-bs-target="#modalAddressFinder" data-bs-toggle="modal" type="button">
              Set Address
            </button>
          </div> -->
          <!-- <ModalAddressFinder
            :address="address"
            @setAddress="setAddress"
          /> -->
        <!-- </div> -->
        <router-link class="fw-normal text-decoration-none text-muted d-block text-center" to="/">
          Already have an account?
          <strong class="text-primary">Log In</strong>
        </router-link>
      </form>
      <ModalTerms :signupMode="signupMode" :terms="terms" @signup="signup" @signinWithGoogle="signinWithGoogle"/>
    </div>

    <div class="signup-cover w-50">
      <img class="img-fluid w-100" :src="SignupImg" alt="" style="height: 100vh;">
    </div>
  </div>
</template>

<script setup>
// import countries from '@/utils/CountryCodes.json'
import SignupImg from '@/assets/images/signup-cover.jpg'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
// import ModalAddressFinder from '@/components/ModalAddressFinder.vue'
import ModalTerms from '@/components/ModalTerms.vue'
import { getFunctions, httpsCallable } from 'firebase/functions'

// const address = ref(null)
// const country = ref({
//   name: '',
//   dialCode: '',
//   code: ''
// })

const email = ref('')
const errorMessage = ref('')
const isError = ref(false)
const isLoading = ref(false)
const firstName = ref('')
const gender = ref('male')
const lastName = ref('')
const password = ref('')
const phone = ref('')
const signupMode = ref(null)
const router = useRouter()
const store = useStore()

// function setAddress (enteredAddress) {
//   address.value = enteredAddress
// }
const isFormDone = computed(() => {
  return firstName.value && lastName.value && email.value && password.value
})

const terms = computed(() => {
  if (!store.state.settings.data) return []

  return store.state.settings.data.find(it => it.label === 'Terms and Conditions')
})

const recaptchaToken = computed(() => {
  return store.state.recaptcha.data
})

async function signup (isTerms) {
  if (!isTerms) return

  isLoading.value = true
  isError.value = false
  const userDetails = {
    // country: country.value,
    // addressData: {
    //   addressLine1: (address.value && address.value.addressLine1 )|| '',
    //   addressLine2: (address.value && address.value.addressLine2) || '',
    //   city: (address.value && address.value.city) || '',
    //   full: (address.value && address.value.full) || '',
    //   manual: (address.value && address.value.manual),
    //   state: (address.value && address.value.state) || '',
    //   postcode: (address.value && address.value.postcode) || ''
    // },
    country: {
      name: 'Australia',
      code: 'AU',
      dial_code: '+61'
    },
    email: email.value,
    firstName: firstName.value,
    gender: gender.value,
    lastName: lastName.value,
    name: firstName.value + ' ' + lastName.value,
    password: password.value,
    phone: phone.value,
    token: recaptchaToken.value,
    termsAccepted: isTerms,
    termsAcceptedDate: new Date().toISOString(),
  }

  const functions = getFunctions()
  const callSignup = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_signup`)
  callSignup(userDetails).then(async (res) => {
    if (!res.data.status) {
      isError.value = true
      errorMessage.value = handleError(res.data.code)
      isLoading.value = false
      return
    }

    await store.dispatch('user/signin', {
      email: email.value,
      password: password.value
    }).then((res) => {
      isLoading.value = false

      if (!res.status) {
        isError.value = true
        errorMessage.value = handleError(res.code)
        return
      }

      router.push({ name: 'Messages' })
    })
  })
}

function handleError (code) {
  switch (code) {
    case 'auth/invalid-email':
      return 'Invalid email.'
    case 'auth/weak-password':
      return 'Password should be at least 6 characters.'
    case 'auth/email-already-exists':
      return 'Email already in use.'
    case 'not-human':
      return 'Error validating you as a user.'
    default:
      return 'Something went wrong.'
  }
}

async function signinWithGoogle (isTerms) {
  if (!isTerms) return
  isError.value = false

  await store.dispatch('user/signinWithGoogle')
    .then((res) => {
      if (!res.status) {
        isError.value = true
        errorMessage.value = handleError(res.code)
        return
      }
    })
}

onMounted(async () => {
  await store.dispatch('settings/get', null, { root: true })
})
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
  .signup {
    &-cover {
      display: none;
    }

    &-form {
      padding: 1rem !important;
      width: 100% !important;
    }
  }
}
</style>

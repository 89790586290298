<template>
  <LoadingScreen v-if="isLoading" />
  <div class="d-flex justify-content-between mb-3">
    <button
      v-if="props.isFilters"
      class="btn btn-secondary bg-white text-dark-blue d-flex align-items-center gap-2 rounded-pill fs-12 text-white py-1 px-4 justify-content-center"
      data-bs-target="#modalSearchFilters"
      data-bs-toggle="modal"
    >
      FILTERS
      <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" viewBox="0 0 512 512"><path d="M0 416c0 8.8 7.2 16 16 16l65.6 0c7.4 36.5 39.7 64 78.4 64s71-27.5 78.4-64L496 432c8.8 0 16-7.2 16-16s-7.2-16-16-16l-257.6 0c-7.4-36.5-39.7-64-78.4-64s-71 27.5-78.4 64L16 400c-8.8 0-16 7.2-16 16zm112 0a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM304 256a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm48-80c-38.7 0-71 27.5-78.4 64L16 240c-8.8 0-16 7.2-16 16s7.2 16 16 16l257.6 0c7.4 36.5 39.7 64 78.4 64s71-27.5 78.4-64l65.6 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-65.6 0c-7.4-36.5-39.7-64-78.4-64zM192 144a48 48 0 1 1 0-96 48 48 0 1 1 0 96zm78.4-64C263 43.5 230.7 16 192 16s-71 27.5-78.4 64L16 80C7.2 80 0 87.2 0 96s7.2 16 16 16l97.6 0c7.4 36.5 39.7 64 78.4 64s71-27.5 78.4-64L496 112c8.8 0 16-7.2 16-16s-7.2-16-16-16L270.4 80z"/></svg>
    </button>
    <small class="text-muted mb-3">
      {{ filteredTransactions.length }} Items
    </small>
  </div>
  <div class="mb-1 d-flex gap-1 flex-wrap">
    <div v-if="filters.deliveryMethod" class="badge bg-info text-dark fw-normal d-flex align-items-center">
      Delivery Method: {{ filters.deliveryMethod }}
    </div>
    <div v-if="filters.category" class="badge bg-info text-dark fw-normal d-flex align-items-center">
      Category: {{ filters.category }}
    </div>
    <div v-if="filters.useCase" class="badge bg-info text-dark fw-normal d-flex align-items-center">
      Use Case: {{ filters.useCase }}
    </div>
    <div v-if="filters.status" class="badge bg-info text-dark fw-normal d-flex align-items-center">
      Status: {{ filters.status }}
    </div>
    <div v-if="filters.days" class="badge bg-info text-dark fw-normal d-flex align-items-center">
      {{ filters.days }} days past
    </div>
    <div
      v-if="filters.deliveryMethod || filters.category || filters.useCase || filters.status || filters.days"
      class="badge bg-white text-dark border d-flex align-items-center gap-2"
      style="cursor: pointer;"
      @click="clearFilters"
    >
      <span>Clear Filters</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
    </div>
  </div>
  <div class="message-list align-content-start d-grid pe-2">
    <router-link
      :to="{ name: 'MessageEdit', params: { id: transaction.id } }"
      v-for="transaction in filteredTransactions"
      :key="transaction.id"
      class="py-4 ps-3 shadow-sm border border-light position-relative d-flex justify-content-between align-items-center text-dark"
      style="border-radius: 10px; text-decoration: none; max-height: 80px;"
    >
      <div class="d-flex align-items-center gap-3">
        <VTooltip :triggers="['hover', 'click']">
          <div
            class="position-relative d-flex align-items-center"
            :style="`${transaction.deliveryMethod === 'SMS' ? 'height: 30px': 'height: 28px'}`"
          >
            <img :src="getDeliveryMethodIcon(transaction.deliveryMethod)">
            <img
              class="position-absolute"
              :src="getStatusIcon(transaction.status)"
              :style="`right: -5px; ${transaction.deliveryMethod === 'SMS' ? 'bottom: -4px;': 'bottom: -8px;'}`"
              width="18"
            >
          </div>
          <template #popper>
            <div>
              {{ transaction.deliveryMethod }} - {{ transaction.status }}
            </div>
          </template>
        </VTooltip>
        <div style="line-height: 1;">
          <small class="fw-bold text-truncate d-block mb-1" style="max-width: 180px;">
            {{ getMessageLabel(transaction) }}
          </small>
          <small class="text-capitalize text-truncate d-block fs-12" style="max-width: 180px;">
            {{ getCategoryAndUseCase(transaction) }}
          </small>
          <small class="fs-12">
            Sequence No: {{ transaction.sequenceNumber || 1 }}
          </small>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="text-muted fs-12">
          <template v-if="getTimePassed(transaction.updatedAt) === 1">
            Today
          </template>
          <template v-else>
            <div>{{ getTimePassed(transaction.updatedAt) }} days</div>
            <div>ago</div>
          </template>
        </div>
        <div class="dropdown">
          <div class="p-3" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style="cursor: pointer;">
            <svg xmlns="http://www.w3.org/2000/svg" width="5" viewBox="0 0 128 512" fill="#6C757D"><path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"/></svg>
          </div>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <router-link class="dropdown-item d-flex align-items-center gap-2" :to="{ name: 'MessageEdit', params: { id: transaction.id } }">
                <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
                {{ transaction.status === 'Delivered' ? 'View' : 'Edit' }}
              </router-link>
            </li>
            <li v-if="transaction.status !== 'Delivered'">
              <a class="dropdown-item text-danger d-flex align-items-center gap-2" href="#" @click.prevent="deleteTransaction(transaction.id)">
                <svg width="18" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M164.2 39.5L148.9 64H299.1L283.8 39.5c-2.9-4.7-8.1-7.5-13.6-7.5H177.7c-5.5 0-10.6 2.8-13.6 7.5zM311 22.6L336.9 64H384h32 16c8.8 0 16 7.2 16 16s-7.2 16-16 16H416V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V96H16C7.2 96 0 88.8 0 80s7.2-16 16-16H32 64h47.1L137 22.6C145.8 8.5 161.2 0 177.7 0h92.5c16.6 0 31.9 8.5 40.7 22.6zM64 96V432c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V96H64zm80 80V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16z"/></svg>
                Delete
              </a>
            </li>
          </ul>
        </div>
      </div>
    </router-link>
  </div>
  <ModalSearchFilters :filters="filters" :key="filters" @setFilter="setFilter" />
</template>

<script setup>
import { defineProps, ref, watch } from 'vue'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getCategoryAndUseCase, getTimePassed } from '@/utils'
import ModalSearchFilters from '@/components/Messages/ModalSearchFilters.vue'
import { useStore } from 'vuex'
const store = useStore()

import IconMail from '@/assets/icons/delivery-mail.svg'
import IconEmail from '@/assets/icons/delivery-email.svg'
import IconSms from '@/assets/icons/delivery-sms.svg'
import IconDelivered from '@/assets/icons/status-delivered.svg'
import IconFailed from '@/assets/icons/status-failed.svg'
import IconIncomplete from '@/assets/icons/status-incomplete.svg'
import IconProcessing from '@/assets/icons/status-processing.svg'
import IconQueue from '@/assets/icons/status-queue.svg'
import IconReadyToSend from '@/assets/icons/status-readyToSend.svg'
import IconSending from '@/assets/icons/status-sending.svg'

const props = defineProps({
  isFilteredSequence: {
    default: false,
    type: Boolean
  },
  isFilters: {
    default: false,
    type: Boolean
  },
  transactions: {
    required: true,
    type: Array
  }
})

const functions = getFunctions()
const filteredTransactions = ref([])
const isLoading = ref(false)

const filters = ref({
  deliveryMethod: null,
  category: '',
  days: null,
  status: null,
  useCase: null
})

watch(
  props.transactions,
  (trans) => {
    // if (!props.isFilteredSequence) {
    //   // only show new transaction of the sequence, instead of showing all of the sequence
    //   filteredTransactions.value = trans.filter((it) => {
    //     if (it.sequenceNumber === undefined) {
    //       it.sequenceNumber = 1
    //       return true
    //     }

    //     const filteredData = trans.filter((item) =>
    //       item.category.id === it.category.id &&
    //       item.useCase.id === it.useCase.id &&
    //       item.deliveryMethod === it.deliveryMethod &&
    //       (
    //         (JSON.stringify(item.deliveryDetails) === JSON.stringify(it.deliveryDetails)) ||
    //         (JSON.stringify(item.deliveryDistribution) === JSON.stringify(it.deliveryDistribution))
    //       )
    //     )

    //     const highestSequenceNumber = Math.max(...filteredData.map((item) => item.sequenceNumber))

    //     return it.sequenceNumber === highestSequenceNumber
    //   })
    // } else {
    //   filteredTransactions.value = trans
    // }
    filteredTransactions.value = trans
  },
  { immediate: true }
)

function getMessageLabel (transaction) {
  const placeholder = 'Message Label'

  if (transaction.messageLabel) {
    return transaction.messageLabel
  }

  if (transaction.deliveryDetails) {
    if (transaction.deliveryMethod === 'Mail') {
      return transaction.deliveryDetails.full
    }

    return transaction.deliveryDetails
  }

  if (transaction.deliveryDistribution) {
    return 'DL: ' + transaction.deliveryDistribution.name
  }

  return placeholder
}

function clearFilters () {
  filteredTransactions.value = props.transactions

  filters.value = {
    deliveryMethod: null,
    category: '',
    days: null,
    status: null,
    useCase: null
  }
}

async function deleteTransaction (id) {
  isLoading.value = true
  const confirmDelete = confirm ('Are you sure you want to delete this message? This cannot be undone!')

  if (confirmDelete) {
    const transactionDelete = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_transactionDelete`)
    transactionDelete(id).then(() => {
      store.dispatch('transactions/get')
      isLoading.value = false
    })
  }
}

function getDeliveryMethodIcon (method) {
  switch (method) {
    case 'Mail':
      return IconMail
    case 'Email':
      return IconEmail
    default:
      return IconSms
  }
}

function getStatusIcon (status) {
  switch (status) {
    case 'Delivered':
      return IconDelivered
    case 'Failed':
      return IconFailed
    case 'Incomplete Details':
      return IconIncomplete
    case 'Processing Message':
      return IconProcessing
    case 'In Queue':
      return IconQueue
    case 'Ready To Send':
      return IconReadyToSend
    case 'Sending':
      return IconSending
    default:
      return IconFailed
  }
}

function setFilter (filter) {
  filters.value = filter

  const filterCategory = (trans) => {
    if (!filters.value.category) return trans

    return trans &&
      trans.filter(transaction => transaction.category.name &&
      !transaction.category.name.indexOf(filters.value.category))
  }

  const filterDays = (trans) => {
    if (!filters.value.days) return trans

      const date = new Date()
      const subtractDate = date.setDate(date.getDate() - (filters.value.days))
      const fromDate = new Date(subtractDate).toISOString()

      return trans &&
        trans.filter((it) => it.createdAt >= fromDate && it.createdAt <= new Date().toISOString())
  }

  const filterDeliveryMethod = (trans) => {
    if (!filters.value.deliveryMethod) return trans

    return trans &&
      trans.filter(transaction => !transaction.deliveryMethod.indexOf(filters.value.deliveryMethod))
  }

  const filterStatus = (trans) => {
    if (!filters.value.status) return trans

    return trans &&
      trans.filter(transaction => !transaction.status.indexOf(filters.value.status))
  }

  const filterUseCase = (trans) => {
    if (!filters.value.useCase) return trans

    return trans &&
      trans.filter(transaction => transaction.useCase.name &&
      !transaction.useCase.name.indexOf(filters.value.useCase))
  }

  filteredTransactions.value = filterDays(filterStatus(filterUseCase(filterCategory(filterDeliveryMethod(props.transactions)))))
}
</script>

<style lang="scss" scoped>
.message-list {
  max-height: calc(100vh - 300px);
  overflow: auto;
}
</style>

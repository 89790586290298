<template>
  <LoadingScreen v-if="!currentUser" />
  <div v-else>
    <div
      v-if="uploadStatus !== null"
      :class="{
        'alert alert-dismissible mt-3': true,
        'alert-success': uploadStatus === true,
        'alert-danger': uploadStatus === false,
      }"
      role="alert"
    >
      {{ uploadStatus ? 'Upload successful.' : 'Upload failed.' }}
    </div>
    <AccountBase :user="currentUser" @upload="upload" />
  </div>
</template>

<script setup>
import { app } from '@/firebase'
import { getStorage, ref as sRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage'

import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import AccountBase from '@/components/Account/Base.vue'

const store = useStore()
const currentUser = computed(() => store.state.user.currentUser)
const uploadStatus = ref(null)

function upload (file) {
  const storage = getStorage(app)
  const avatarsRef = sRef(storage, `avatars/${currentUser.value.uniqueID}`)

  const uploadTask = uploadBytesResumable(avatarsRef, file)
  return uploadTask.on('state_changed',
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      console.log('Upload is ' + progress + '% done')
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused')
          break
        case 'running':
          console.log('Upload is running')
          break
      }
    },
    (error) => {
      // Handle unsuccessful uploads
      console.log('error upload: ', error)
      uploadStatus.value = false

      setTimeout(() => {
        uploadStatus.value = null
      }, 3000)
    },
    () => {
      // Handle successful uploads on complete
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        store.dispatch('user/updateProfile', {
          ...currentUser.value,
          avatar: downloadURL
        })
      })

      uploadStatus.value = true

      setTimeout(() => {
        uploadStatus.value = null
      }, 3000)
    }
  )
}
</script>

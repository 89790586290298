<template>
  <div class="modal fade" id="modalDeliveryDistributions" tabindex="-1" aria-labelledby="modalDeliveryDistributionsLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalDeliveryDistributionsLabel">
            Select Distribution List
          </h5>
        </div>
        <div class="modal-body" style="max-height: 360px; overflow: auto;">
          <!-- <div class="mb-3">
            <span data-bs-dismiss="modal">
              <router-link :to="{ name: 'AccountDistributions' }">
                Click here
              </router-link>
            </span> to create or update distributions.
          </div> -->
          <div v-if="distributions">
            <ul class="list-group">
              <li
                v-for="dist in distributions"
                :key="dist.id"
                class="list-group-item"
                :class="{ 'active': selectedDist?.id === dist.id }"
                style="cursor: pointer;"
                @click="selectDistribution(dist)"
              >
                {{ dist.name }} - {{ dist.recipients.length }} items
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">
            Close
          </button>
          <button class="btn btn-primary" :disabled="props.transaction.status === 5" data-bs-dismiss="modal" type="button" @click="save">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue'

const props = defineProps(['transaction', 'user'])
const emit = defineEmits(['setRecipientsWithDistribution'])

const selectedDist = ref(props.transaction.deliveryDistribution || null)

const distributions = computed(() => {
  const dists = props.user && props.user.distributions
  const deliveryMethod = props.transaction && props.transaction.deliveryMethod
  return dists?.filter(it => it.method.toLowerCase() === deliveryMethod.toLowerCase())
})

function save () {
  emit('setRecipientsWithDistribution', selectedDist.value)
}

function selectDistribution (dist) {
  if (props.transaction.status === 5) return

  selectedDist.value = dist
}
</script>

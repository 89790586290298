<template>
  <div class="modal fade" id="modalEditPhone" tabindex="-1" aria-labelledby="modalEditPhoneLabel" aria-hidden="true">
    <div class="modal-dialog">
      <form class="modal-content" @submit.prevent="save">
        <div class="modal-header">
          <h5 class="modal-title" id="modalEditPhoneLabel">Update Phone Number</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label class="form-label">Phone Number</label>
            <input
              v-model="phone"
              autocomplete="off"
              class="form-control"
              type="text"
            >
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">
            Cancel
          </button>
          <button
            class="btn btn-primary"
            :disabled="!phone"
            :data-bs-dismiss="phone ? 'modal' : null"
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { useStore } from 'vuex'

const props = defineProps(['user'])
const phone = ref(props.user.phone || '')
const store = useStore()

async function save () {
  store.dispatch('user/updateProfile', {
    ...props.user,
    phone: phone.value
  })
}
</script>

<template>
  <form @submit.prevent="save">
    <LoadingScreen v-if="isLoading" />
    <div class="modal fade" id="modalDeliveryDetails" tabindex="-1" aria-labelledby="modalDeliveryDetailsLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalDeliveryDetailsLabel">
              Mail Addressing Details
            </h5>
            <button id="modalDeliveryMailClose" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="form-check mb-3">
              <input
                v-model="addressObject.manual"
                id="isManualCheckbox~"
                :checked="addressObject.manual"
                class="form-check-input"
                :disabled="transaction.status === 5 || props.transaction.sequenceNumber > 1"
                type="checkbox"
              >
              <label class="form-check-label" for="isManualCheckbox~">
                Manual Address Input
              </label>
            </div>
            <div v-if="addressObject.manual" class="mb-3">
              <div class="mb-3">
                <label for="addressLine1" class="form-label">Address Line 1</label>
                <input v-model="addressObject.addressLine1" :disabled="transaction.status === 5 || props.transaction.sequenceNumber > 1" id="addressLine1" class="form-control" placeholder="Address Line 1" type="text" required>
              </div>
              <div class="mb-3">
                <label for="city" class="form-label">City</label>
                <input v-model="addressObject.city" id="city" :disabled="transaction.status === 5 || props.transaction.sequenceNumber > 1" class="form-control" placeholder="City" type="text">
              </div>
              <div class="mb-3">
                <label for="state" class="form-label">State</label>
                <input v-model="addressObject.state" :disabled="transaction.status === 5 || props.transaction.sequenceNumber > 1" id="state" class="form-control" placeholder="State" type="text">
              </div>
              <div class="mb-3">
                <label for="postcode" class="form-label">Postcode</label>
                <input v-model="addressObject.postcode" :disabled="transaction.status === 5 || props.transaction.sequenceNumber > 1" id="postcode" class="form-control" placeholder="Postcode" type="text">
              </div>
              <div class="mb-3">
                <label for="regionCode" class="form-label">Country/Region</label>
                <input v-model="addressObject.regionCode" disabled id="regionCode" class="form-control" placeholder="Country/Region" type="text">
              </div>
            </div>
            <div v-if="!addressObject.manual" class="mb-3">
              <label class="form-label">Start typing a Mail address</label>
              <v-select
                v-model="autocompleteInput"
                :clearSearchOnBlur="() => false"
                :options="suggestions"
                :disabled="transaction.status === 5"
                @option:selecting="onSelectAddress"
                @search="onFetchSuggestions"
              >
                <template v-slot:no-options>
                  No address found...
                </template>
              </v-select>
            </div>
            <div v-if="isInvalidAddress" class="alert alert-danger">
              {{ isInvalidAddress }}
            </div>
            <div>
              <div class="my-3 d-flex gap-2">
                <input
                  v-model="addressObject.isOrganization"
                  id="isOrganizationCheckbox"
                  :checked="addressObject.isOrganization"
                  class="form-check-input"
                  type="checkbox"
                >
                <label class="form-check-label" for="isOrganizationCheckbox">
                  Include Organization Name
                </label>
              </div>
              <div v-if="addressObject.isOrganization" class="mb-3">
                <VTooltip :triggers="['hover', 'click']">
                  <label for="organization" class="form-label">Organization</label>
                  <input v-model="addressObject.organization" maxlength="20" :disabled="transaction.status === 5 || props.transaction.sequenceNumber > 1" id="organization" class="form-control" placeholder="Organization" type="text" required>
                  <template #popper>
                    Enter the name of the organisation the Mail message will be sent to
                  </template>
                </VTooltip>
              </div>
              <div class="mb-3">
                <VTooltip :triggers="['hover', 'click']">
                  <label for="addressName" class="form-label">Role / Receiver / Person name</label>
                  <input v-model="addressObject.addressName" maxlength="20" :disabled="transaction.status === 5 || props.transaction.sequenceNumber > 1" id="addressName" class="form-control" placeholder="Role / Receiver / Person Name" type="text" required>
                  <template #popper>
                    Enter the role or name or receiver the Mail message is intended for
                  </template>
                </VTooltip>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">
              Cancel
            </button>
            <button
              class="btn btn-primary"
              type="submit"
              :disabled="transaction.status === 5 || props.transaction.sequenceNumber > 1 || isValidating"
            >
              {{ isValidating ? 'Validating...' : 'Save' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { compareDeliveryDetails, convertToNextSequence } from '@/utils'
import { useStore } from 'vuex'
import axios from 'axios'
import { computed, defineProps, ref } from 'vue'
import _ from 'lodash'
import { getFunctions, httpsCallable } from 'firebase/functions'

const functions = getFunctions()
const props = defineProps(['transaction'])
const store = useStore()

const isLoading = ref(false)

const transactions = computed(() => store.state.transactions.data)

const deliveryDetails = computed(() => {
  if (props.transaction.deliveryDetails) {
    return props.transaction.deliveryDetails
  }

  return { addressLine1: '', addressLine2: '', city: '', state: '', postcode: '' }
})

const addressObject = ref({
  addressLine1: ref(deliveryDetails.value.addressLine1 || ''),
  addressLine2: ref(deliveryDetails.value.addressLine2 || ''),
  addressName: ref(deliveryDetails.value.addressName || ''),
  city: ref(deliveryDetails.value.city || ''),
  full: ref(deliveryDetails.value.full || ''),
  isOrganization: ref(deliveryDetails.value.isOrganization || ''),
  manual: ref(deliveryDetails.value.manual || false),
  organization: ref(deliveryDetails.value.organization || ''),
  postcode: ref(deliveryDetails.value.postcode || ''),
  regionCode: ref('AU'),
  state: ref(deliveryDetails.value.state || '')
})

const autocompleteAddressPrediction = ref(null)
const autocompleteInput = ref(deliveryDetails.value.full || '')
const autocompleteMinChars = ref(3)
const isNotSpam = ref(false)
const isInvalidAddress = ref(null)
const isValidating = ref(false)
const suggestions = ref([])

function onFetchSuggestions (searchInput, loading) {
  if (searchInput.length < autocompleteMinChars.value) return

  if (searchInput.length) {
    loading(true)
    fetchSuggestions(loading, searchInput)
  }
}

const fetchSuggestions = _.debounce(async (loading, searchInput) =>  {
  const url = `https://places.googleapis.com/v1/places:autocomplete?key=${process.env.VUE_APP_GOOGLE_API_KEY}`

  const options = {
    input: searchInput,
    includedRegionCodes: ['au']
  }

  const res = await axios.post(url, options)

  if (res.status === 200) {
    suggestions.value = res.data.suggestions && res.data.suggestions.map(suggestion => ({
      label: suggestion.placePrediction.text.text,
      value: suggestion
    }))
    loading(false)
  }
}, 500)

async function onSelectAddress (address) {
  autocompleteAddressPrediction.value = address.value.placePrediction
}

async function validateAddress (place) {
  isValidating.value = true
  let addressComponents = null

  if (!place) {
    addressComponents = {
      addressLines: [addressObject.value.addressLine1, addressObject.value.addressLine2],
      locality: addressObject.value.city,
      administrativeArea: addressObject.value.state,
      postalCode: addressObject.value.postalCode,
      regionCode: addressObject.value.regionCode
    }
  }

  if (place) {
    addressComponents = {
      addressLines: place.text.text
    }
  }

  try {
    const url = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.VUE_APP_GOOGLE_API_KEY}`

    const options = {
      address: addressComponents
    }

    const res = await axios.post(url, options)

    if (res.status === 200) {
      isValidating.value = false

      if (res.data.result.verdict.addressComplete) {
        isInvalidAddress.value = null
        setAddress(res.data.result)
        return true
      } else {
        const missingComponents = res.data.result.address.missingComponentTypes

        if (missingComponents && missingComponents.length > 0) {
          isInvalidAddress.value = `Invalid Address. Invalid or missing components: ${missingComponents.join(', ')}`
        } else {
          isInvalidAddress.value = 'Invalid Address.'
        }

        return false
      }
    }
  } catch (err) {
    isInvalidAddress.value = 'Something went wrong in Validating the address.'
    isValidating.value = false
    console.log('validate address error: ', err)
    return false
  }
}

function setAddress (val) {
  const { formattedAddress, postalAddress } = val.address

  addressObject.value = {
    ...addressObject.value,
    addressLine1: postalAddress.addressLines && postalAddress.addressLines.length > 0 ? postalAddress.addressLines[0] : '',
    addressLine2: postalAddress.addressLines && postalAddress.addressLines.length > 1 ? postalAddress.addressLines[1] : '',
    city: postalAddress.locality || '',
    full: formattedAddress || '',
    manual: addressObject.value.manual,
    placeId: val.geocode.placeId || '',
    postcode: postalAddress.postalCode || '',
    state: postalAddress.administrativeArea || ''
  }
}

async function save () {
  isLoading.value = true
  // if new value is the same with old, then no need to save
  if (
    !autocompleteAddressPrediction.value &&
    props.transaction.deliveryDetails &&
    addressObject.value &&
    compareDeliveryDetails(props.transaction.deliveryDetails, addressObject.value)
  ) {
    document.getElementById('modalDeliveryMailClose').click()
    isNotSpam.value = true
    isLoading.value = false
    return
  }

  let validated = false
  if (addressObject.value.manual) {
    validated = await validateAddress()
  } else {
    validated = await validateAddress(autocompleteAddressPrediction.value)
  }

  if (validated) {
    const spamChecker = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_spamChecker`)
    isNotSpam.value = await spamChecker({ ...props.transaction, deliveryDetails: addressObject.value }).then(res => res.data)

    // create next sequence if the original is sent
    if (!isNotSpam.value) {
      const matchingTransactions = transactions.value.filter(it => {
        if (it.deliveryDetails && addressObject.value) {
          // return (it.deliveryDetails && it.deliveryDetails.full) === (props.transaction.deliveryDetails && props.transaction.deliveryDetails.full) &&
          return compareDeliveryDetails(it.deliveryDetails, addressObject.value) &&
            it.deliveryMethod === props.transaction.deliveryMethod &&
            it.category === props.transaction.category &&
            it.useCase === props.transaction.useCase
        }
      })

      const filteredMatchTransactions = matchingTransactions && matchingTransactions.filter(it => it.id !== props.transaction.id)

      // condition for converting to next sequence
      let highestSequenceNumber = -Infinity
      let latestTransaction = null

      for (const trans of filteredMatchTransactions) {
        if (trans.sequenceNumber > highestSequenceNumber) {
          highestSequenceNumber = trans.sequenceNumber
          latestTransaction = trans
        }
      }

      if (latestTransaction && latestTransaction.status === 5) {
        const isConverted = await convertToNextSequence(latestTransaction, props.transaction.id)

        if (isConverted) {
          document.getElementById('modalDeliveryMailClose').click()
          isLoading.value = false
          return
        }
      }

      document.getElementById('modalDeliveryMailClose').click()
      isLoading.value = false

      // if latestTransaction status !== 5 or UNDELIVERED, then set status to 8 or INVALID RECIPIENT
      await store.dispatch('transactions/update', {
        ...props.transaction,
        deliveryDetails: { ...addressObject.value },
        deliveryDistribution: null,
        status: 8,
        updatedAt: new Date().toISOString()
      })

      return
    }

    isLoading.value = false
    document.getElementById('modalDeliveryMailClose').click()

    await store.dispatch('transactions/update', {
      ...props.transaction,
      deliveryDetails: { ...addressObject.value },
      deliveryDistribution: null,
      status: 3,
      updatedAt: new Date().toISOString()
    })
  } else {
    isLoading.value = false
  }
}
</script>

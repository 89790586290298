<template>
  <div class="fs-14">
    <strong class="mb-3 text-primary">Message Translate</strong>
    <div v-if="!transaction">Loading...</div>
    <template v-else>
      <hr>
      <div>
        <v-select
          v-model="language"
          :options="languages"
        />
      </div>
      <button :disabled="!language || isLoadingText || isLoadingSpeech" class="btn btn-primary" @click="translate">Translate</button>
      <div v-if="isLoadingText"><strong>Translating Text...</strong></div>
      <div v-html="translatedMessage" />
      <div v-if="isLoadingSpeech"><strong>Translating Audio...</strong></div>
      <audio v-if="translatedSpeech" :src="translatedSpeech" controls />
      <hr>
      <div v-html="transaction.message.body" />
    </template>
  </div>
</template>

<script setup>
/*eslint-disable*/
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import { getFunctions, httpsCallable } from 'firebase/functions'

const store = useStore()
const isLoadingText = ref(false)
const isLoadingSpeech = ref(false)
const language = ref('')
const languages = [
  {
    code: 'tl',
    label: 'Filipino',
    voiceCode: 'fil-PH'
  },
  {
    code: 'ru',
    label: 'Russia',
    voiceCode: 'ru-RU'
  }
]

const translatedMessage = ref('')
const translatedSpeech = ref(null)

const messageId = computed(() => {
  const params = new URLSearchParams(window.location.search)
  return params.get('messageId')
})

const transaction = computed(() => {
  return store.state.transactions.itemById
})

function translate () {
  isLoadingText.value = true
  isLoadingSpeech.value = true
  const functions = getFunctions()
  const textTranslate = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_textTranslate`)

  textTranslate({ language: language.value, message: transaction.value.message.body })
    .then(textRes => {
      isLoadingText.value = false
      translatedMessage.value = textRes.data
      const trimmedMessage = textRes.data.replace(/<\/?[^>]+(>|$)|&nbsp;/g, '').replace(/&#39;/g, '\'')
      const textToSpeech = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_textToSpeech`)

      textToSpeech({ language: language.value, message: trimmedMessage })
        .then(speechRes => {
          translatedSpeech.value = `data:audio/mp3;base64,${speechRes.data}`
          isLoadingSpeech.value = false
        })
    })
}

onMounted(() => {
  store.dispatch('transactions/getItemById', messageId.value)
})
</script>

<template>
  <LoadingScreen v-if="!transactions" />
  <div v-else>
    <div class="mb-2 fs-4 fw-semibold">Spend history</div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Description</th>
          <th scope="col">Cost</th>
          <th scope="col">Date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <template v-if="item.deliveryMethod">
            <td>{{ item.deliveryMethod }} message transaction</td>
            <td>${{ item.sellPrice ? item.sellPrice.total : parseFloat(item.square.amount.amount / 100).toFixed(2) }}</td>
            <td>{{ item.sentAt ? formatDate(item.sentAt) : formatDate(item.createdAt) }}</td>
          </template>
          <template v-else>
            <td><strong>Topup Credits</strong></td>
            <td><strong>${{ item.sellPrice ? item.sellPrice.total : parseFloat(item.square.amount.amount / 100).toFixed(2) }}</strong></td>
            <td><strong>{{ item.sentAt ? formatDate(item.sentAt) : formatDate(item.createdAt) }}</strong></td>
          </template>
        </tr>
        <tr>
          <td colspan="3"><strong>Total:</strong> ${{ parseFloat(totalCost).toFixed(2) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()

const topups = computed(() => {
  const topups = store.state.topups.data
  return topups
})

const transactions = computed(() => {
  const trans = store.state.transactions.tempData

  return trans && trans.filter(it => it.status === 'Delivered')
})

const items = computed(() => {
  return [...transactions.value, ...topups.value]
    .sort((a, b) => {
      const dateA = a.createdAt || a.sentAt
      const dateB = b.createdAt || b.sentAt
      return new Date(dateB) - new Date(dateA)
    })
})

const totalCost = computed(() => {
  if (!items.value) return 0

  let total = 0
  for (const item of items.value) {
    const amount = item.sellPrice ? item.sellPrice.total : item.square.amount.amount / 100
    total += parseFloat(amount)
  }

  return total
})

function formatDate (createdAt) {
    const date = new Date(createdAt)

    const month = date.toLocaleDateString('en-US', { month: 'short' })
    const day = date.getDate()
    const year = date.getFullYear()
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const period = (date.getHours() < 12) ? 'AM' : 'PM'
    const formattedHour = (date.getHours() % 12) || 12

    return `${month}. ${day < 10 ? '0' + day : day}, ${year} ${formattedHour}:${minutes} ${period}`
}
</script>

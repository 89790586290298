<template>
  <div class="mb-4 rounded border border-primary">
    <div class="accordion" id="accordionExample">
      <div class="accordion-item border-0">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            Get started
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div class="accordion-body p-0">
            <div class="px-4 py-3" style="width: fit-content">
              <VTooltip :triggers="['hover', 'click']">
                <button class="btn btn-primary me-3" @click="router.push({ name: 'Account' })">Go</button>
                <label class="form-check-label" for="getStarted1">
                    <span>Complete your Account Details</span>
                </label>
                <template #popper>
                  Tap to finish updating your Account details
                </template>
              </VTooltip>
            </div>
            <div class="px-4 py-3" style="width: fit-content">
              <VTooltip :triggers="['hover', 'click']">
                <button class="btn btn-primary me-3" @click="router.push({ name: 'AccountTopup' })">Go</button>
                <label class="form-check-label" for="getStarted1">
                  <span>Add Credits to your Account</span>
                </label>
                <template #popper>
                    Tap to add Credits to your account to allow you send your message
                </template>
              </VTooltip>
            </div>
            <div class="px-4 py-3" style="width: fit-content">
              <VTooltip :triggers="['hover', 'click']">
                <button class="btn btn-primary me-3" @click="router.push({ name: 'MessageCreate' })">Go</button>
                <label class="form-check-label" for="getStarted1">
                  <span>Create your first message</span>
                </label>
                <template #popper>
                    Tap to Create your first Message - you will need Credits to send your message
                </template>
              </VTooltip>
            </div>
            <div v-if="props.hasHideButton" class="d-flex py-3 px-4 gap-2 border-top border-primary align-items-center" @click="hideGetStarted">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9.003 14L16.073 6.929L14.659 5.515L9.003 11.172L6.174 8.343L4.76 9.757L9.003 14Z" fill="#3BBE8F"/>
              </svg>
              <div>
                <VTooltip :triggers="['hover', 'click']">
                  <span>No, Thanks!</span>
                  <template #popper>
                    Tap to proceed
                  </template>
                </VTooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const router = useRouter()
const store = useStore()
const currentUser = computed(() => store.state.user.currentUser)
const props = defineProps({
  hasHideButton: {
    default: false,
    type: Boolean
  }
})

function hideGetStarted () {
  console.log('currentUser: ', currentUser.value)
  store.dispatch('user/updateProfile', {
    ...currentUser.value,
    isHiddenGetStarted: true
  })
}
</script>

<style>

</style>

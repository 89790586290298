import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

import './main.scss'
import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'
import FloatingVue from 'floating-vue'
import DatePicker from 'vue-datepicker-next'

import LoadingScreen from '@/components/LoadingScreen.vue'

createApp(App)
  .component('LoadingScreen', LoadingScreen)
  .component('v-select', vSelect)
  .use(store)
  .use(router)
  .use(FloatingVue)
  .use(DatePicker)
  .mount('#app')

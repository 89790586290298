<template>
  <div class="loading-screen">
    <div class="loading-screen__spinners">
      <div
        v-for="spinner in spinners"
        :key="spinner"
        class="spinner-grow text-primary"
        :style="`animation-delay: ${spinner}ms;`"
        role="status"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const spinners = ref([0, 100, 200, 300, 400])
</script>

<style lang="scss" scoped>
.loading-screen {
  align-items: center;
  background-color: rgba(95, 95, 95, 0.3);
  bottom: 0;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 9999;

  &__spinners {
    display: flex;
    gap: 4px;

    .spinner-grow {
      width: 20px;
      height: 20px;
    }
  }
}
</style>

<template>
  <div class="modal fade" id="modalContactDetails" tabindex="-1" aria-labelledby="modalContactDetailsLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalContactDetailsLabel">
            Select Contact Details To Attach
          </h5>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-between w-50 mb-2">
            <template v-if="user && user.phone">
              <label class="form-check-label text-dark-blue fs-14 fw-bold" for="contact-details-mobile">
                My Mobile Number
              </label>
              <input
                v-model="contactDetails.mobile"
                id="contact-details-mobile"
                class="form-check-input"
                type="checkbox"
              >
            </template>
            <button v-else class="btn btn-primary" data-bs-dismiss="modal" @click="router.push({ name: 'Account' })">
              Set your phone number
            </button>
          </div>
          <div v-if="transaction.deliveryMethod !== 'SMS'" class="d-flex justify-content-between w-50 mb-2">
            <template v-if="user && user.address">
              <label class="form-check-label text-dark-blue fs-14 fw-bold" for="contact-details-address">
                My Address
              </label>
              <input
                v-model="contactDetails.address"
                id="contact-details-address"
                class="form-check-input"
                type="checkbox"
              >
            </template>
            <button v-else class="btn btn-primary" data-bs-dismiss="modal" @click="router.push({ name: 'Account' })">
              Set your address
            </button>
          </div>
          <div v-if="transaction.deliveryMethod !== 'SMS'" class="d-flex justify-content-between w-50 mb-2">
            <label class="form-check-label text-dark-blue fs-14 fw-bold" for="contact-details-email">
              My Email
            </label>
            <input
              v-model="contactDetails.email"
              id="contact-details-email"
              class="form-check-input"
              type="checkbox"
            >
          </div>
          <div class="d-flex justify-content-between w-50 mb-2">
            <label class="form-check-label text-dark-blue fs-14 fw-bold" for="contact-details-name">
              My Name
            </label>
            <input
              v-model="contactDetails.name"
              id="contact-details-name"
              class="form-check-input"
              type="checkbox"
            >
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">
            Close
          </button>
          <button class="btn btn-primary" data-bs-dismiss="modal" type="button" @click="updateContactDetails()">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { defineEmits, defineProps, ref } from 'vue'

const router = useRouter()
const emit = defineEmits(['updateTransactionContactDetails'])

const props = defineProps({
  transaction: {
    required: true,
    type: Object
  },
  user: {
    required: true,
    type: Object
  }
})

const contactDetails = ref({
  address: props.transaction.contactDetails?.address || false,
  email: props.transaction.contactDetails?.email || false,
  mobile: props.transaction.contactDetails?.mobile || false,
  name: props.transaction.contactDetails?.name || false
})

function updateContactDetails () {
  emit('updateTransactionContactDetails', contactDetails.value)
}
</script>

<template>
  <form @submit.prevent="save">
    <LoadingScreen v-if="isLoading" />
    <div class="modal fade" id="modalSetAddress" tabindex="-1" aria-labelledby="modalSetAddressLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalDeliveryDetailsLabel">
              Set Address
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-check mb-3">
              <input
                v-model="addressObject.manual"
                id="isManualCheckbox~"
                :checked="addressObject.manual"
                class="form-check-input"
                type="checkbox"
              >
              <label class="form-check-label" for="isManualCheckbox~">
                Manual Address Input
              </label>
            </div>

            <div v-if="addressObject.manual" class="mb-3">
              <div class="mb-3">
                <label for="addressLine1" class="form-label">Address Line 1</label>
                <input v-model="addressObject.addressLine1" id="addressLine1" class="form-control" placeholder="Address Line 1" type="text" required>
              </div>
              <div class="mb-3">
                <label for="addressLine2" class="form-label">Address Line 2</label>
                <input v-model="addressObject.addressLine2" id="addressLine2" class="form-control" placeholder="Address Line 2" type="text">
              </div>
              <div class="mb-3">
                <label for="city" class="form-label">City</label>
                <input v-model="addressObject.city" id="city" class="form-control" placeholder="City" type="text">
              </div>
              <div class="mb-3">
                <label for="state" class="form-label">State</label>
                <input v-model="addressObject.state" id="state" class="form-control" placeholder="State" type="text">
              </div>
              <div class="mb-3">
                <label for="postcode" class="form-label">Postcode</label>
                <input v-model="addressObject.postcode" id="postcode" class="form-control" placeholder="Postcode" type="text">
              </div>
              <div class="mb-3">
                <label for="regionCode" class="form-label">Country/Region</label>
                <input v-model="addressObject.regionCode" disabled id="regionCode" class="form-control" placeholder="Country/Region" type="text">
              </div>
            </div>

            <div v-if="!addressObject.manual" class="mb-3">
              <label class="form-label">Start typing a Mail address</label>
              <v-select
                v-model="autocompleteInput"
                :clearSearchOnBlur="() => false"
                :options="suggestions"
                @option:selecting="onSelectAddress"
                @search="onFetchSuggestions"
              >
                <template v-slot:no-options>
                  No address found...
                </template>
              </v-select>
            </div>
            <div v-if="isInvalidAddress" class="alert alert-danger">
              {{ isInvalidAddress }}
            </div>
          </div>
          <div class="modal-footer">
            <button id="modalSetAddressClose" class="btn btn-secondary" data-bs-dismiss="modal" type="button">
              Cancel
            </button>
            <button
              class="btn btn-primary"
              type="submit"
              :disabled="isValidating"
            >
              {{ isValidating ? 'Validating...' : 'Save' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import axios from 'axios'
import { computed, defineProps, ref } from 'vue'
import _ from 'lodash'
import { getFunctions, httpsCallable } from 'firebase/functions'

const props = defineProps(['user'])

const address = computed(() => {
  if (props.user?.address) {
    return props.user.address
  }

  return { addressLine1: '', addressLine2: '', city: '', state: '', postcode: '' }
})

const addressObject = ref({
  addressLine1: ref(address.value.addressLine1 || ''),
  addressLine2: ref(address.value.addressLine2 || ''),
  city: ref(address.value.city || ''),
  full: ref(address.value.full || ''),
  manual: ref(address.value.manual || false),
  postcode: ref(address.value.postcode || ''),
  regionCode: ref('AU'),
  state: ref(address.value.state || '')
})

const autocompleteAddressPrediction = ref(null)
const autocompleteInput = ref(address.value.full || '')
const autocompleteMinChars = ref(3)
const isInvalidAddress = ref(null)
const isLoading = ref(false)
const isValidating = ref(false)
const suggestions = ref([])

function onFetchSuggestions (searchInput, loading) {
  if (searchInput.length < autocompleteMinChars.value) return

  if (searchInput.length) {
    loading(true)
    fetchSuggestions(loading, searchInput)
  }
}

const fetchSuggestions = _.debounce(async (loading, searchInput) =>  {
  const url = `https://places.googleapis.com/v1/places:autocomplete?key=${process.env.VUE_APP_GOOGLE_API_KEY}`

  const options = {
    input: searchInput,
    includedRegionCodes: ['au']
  }

  const res = await axios.post(url, options)

  if (res.status === 200) {
    suggestions.value = res.data.suggestions && res.data.suggestions.map(suggestion => ({
      label: suggestion.placePrediction.text.text,
      value: suggestion
    }))
    loading(false)
  }
}, 500)

async function onSelectAddress (address) {
  autocompleteAddressPrediction.value = address.value.placePrediction
}

async function validateAddress (place) {
  isValidating.value = true
  let addressComponents = null

  if (!place) {
    addressComponents = {
      addressLines: [addressObject.value.addressLine1, addressObject.value.addressLine2],
      locality: addressObject.value.city,
      administrativeArea: addressObject.value.state,
      postalCode: addressObject.value.postalCode,
      regionCode: addressObject.value.regionCode
    }
  }

  if (place) {
    addressComponents = {
      addressLines: place.text.text
    }
  }

  try {
    const url = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.VUE_APP_GOOGLE_API_KEY}`

    const options = {
      address: addressComponents
    }

    const res = await axios.post(url, options)

    if (res.status === 200) {
      isValidating.value = false

      if (res.data.result.verdict.addressComplete) {
        isInvalidAddress.value = null
        setAddress(res.data.result)
        return true
      } else {
        const missingComponents = res.data.result.address.missingComponentTypes

        if (missingComponents && missingComponents.length > 0) {
          isInvalidAddress.value = `Invalid Address. Invalid or missing components: ${missingComponents.join(', ')}`
        } else {
          isInvalidAddress.value = 'Invalid Address.'
        }

        return false
      }
    }
  } catch (err) {
    isInvalidAddress.value = 'Something went wrong in Validating the address.'
    isValidating.value = false
    console.log('validate address error: ', err)
    return false
  }
}

function setAddress (val) {
  const { formattedAddress, postalAddress } = val.address

  addressObject.value = {
    ...addressObject.value,
    addressLine1: postalAddress.addressLines && postalAddress.addressLines.length > 0 ? postalAddress.addressLines[0] : '',
    addressLine2: postalAddress.addressLines && postalAddress.addressLines.length > 1 ? postalAddress.addressLines[1] : '',
    city: postalAddress.locality || '',
    full: formattedAddress || '',
    manual: addressObject.value.manual,
    placeId: val.geocode.placeId || '',
    postcode: postalAddress.postalCode || '',
    state: postalAddress.administrativeArea || ''
  }
}

async function save () {
  isLoading.value = true
  let validated = false
  if (addressObject.value.manual) {
    validated = await validateAddress()
  } else {
    validated = await validateAddress(autocompleteAddressPrediction.value)
  }

  try {
    const functions = getFunctions()
    const updateAddressToClicksend = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_updateAddress`)
    const newAddressToClicksend = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_newAddress`)
    console.log('validated: ', validated)
    console.log('addressObject: ', addressObject.value)
    console.log('props.address: ', props.user.address)

    if (!props.user?.address) {
      newAddressToClicksend({ ...props.user, address: addressObject.value })
    } else {
      updateAddressToClicksend({ ...props.user, address: addressObject.value })
    }

    document.getElementById('modalSetAddressClose').click()
    isLoading.value = false
  } catch (err) {
    console.log('error: ', err)
  }
}
</script>

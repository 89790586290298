<template>
  <div class="modal fade" id="modalResetPassword" tabindex="-1" aria-labelledby="modalResetPasswordLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalResetPasswordLabel">Reset Password</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <input
            v-model="email"
            class="form-control mb-1"
            placeholder="Enter Email..."
            type="text"
          >
          <small v-if="isSuccess" class="text-success">
            Reset password link is on your way.
          </small>
          <small v-if="isError" class="text-danger">
            {{ isError }}
          </small>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">
            Close
          </button>
          <button class="btn btn-primary" :disabled="isSending" type="button" @click="send">
            {{ isSending ? 'Sending...' : 'Send' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'

const email = ref('')
const isError = ref(null)
const isSending = ref(false)
const isSuccess = ref(null)
const store = useStore()

async function send () {
  isSending.value = true
  isError.value = null
  isSuccess.value = null

  await store.dispatch('user/resetPassword', email.value)
    .then((res) => {
      isSending.value = false
      if (res.success) {
        isSuccess.value = true
      } else {
        switch (res.code) {
          case 'auth/invalid-email':
            isError.value = 'Invalid Email.'
            break
          case 'auth/user-not-found':
            isError.value = 'User not found.'
            break
          default:
            isError.value = 'Something went wrong.'
            break
        }
      }
    })
}
</script>

import { getDatabase, ref, onValue } from 'firebase/database'

const db = getDatabase()
const dbRef = ref(db, 'settings')

export default {
  namespaced: true,
  state: () => ({
    data: null
  }),
  getters: {},
  mutations: {
    SET_DATA (state, payload) {
      state.data = payload
    }
  },
  actions: {
    get ({ commit }) {
      onValue(dbRef, (snapshot) => {
        commit('SET_DATA', Object.values(snapshot.val()))
      })
    }
  }
}

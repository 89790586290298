export default {
  namespaced: true,
  state: () => ({
    data: null
  }),
  mutations: {
    SET_DATA (state, payload) {
      state.data = payload
    }
  },
  actions: {
    set ({ commit }, recaptcha) {
      commit('SET_DATA', recaptcha)
    }
  }
}

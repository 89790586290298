<template>
  <LoadingScreen v-if="isLoading" />
  <div class="mb-1 text-dark-blue d-flex justify-content-between align-items-center flex-wrap">
    <VTooltip :triggers="['hover', 'click']">
      <small class="text-capitalize fw-bold">
        {{ category.name }} | {{ category.useCase.name }} |
        {{ transactionRef.deliveryMethod }} | {{ transactionRef.sequenceNumber || 1 }}
        <img
          :src="getStatusIcon(transaction.status)"
          width="20"
        >
      </small>
      <template #popper>
        {{ category.useCase.description }}
      </template>
    </VTooltip>
    <div v-if="!isMobile">
      <Stepper :transaction="transactionRef"/>
    </div>
  </div>

  <div class="mb-1 d-flex align-items-center gap-2">
    <VTooltip :triggers="['hover', 'click']">
      <label for="deliveryDetailsInput" class="text-dark-blue">
        <small class="text-capitalize fw-bold text-nowrap">
          {{ transactionRef.deliveryMethod === 'Mail' && 'Delivery Address:' ||
          transactionRef.deliveryMethod === 'SMS' && 'Mobile Number:' ||
          transactionRef.deliveryMethod === 'Email' && 'Email Address:' }}
        </small>
      </label>
      <template #popper>
        {{ transactionRef.deliveryMethod === 'Mail' && 'This is the address your letter will be delivered to' ||
          transactionRef.deliveryMethod === 'SMS' && 'This is the mobile phone number your SMS message will be delivered to' ||
          transactionRef.deliveryMethod === 'Email' && 'This is the email your message will be delivered to' }}
      </template>
    </VTooltip>
    <div v-if="transactionRef.deliveryMethod === 'Mail'" :class="`p-0 border-0 bg-transparent d-flex align-items-center gap-2 ${ !transactionRef.deliveryDetails ? 'text-primary' : 'text-success'}`" data-bs-target="#modalDeliveryDetails" data-bs-toggle="modal" style="outline: 0; cursor: pointer;">
      <template v-if="!transactionRef.deliveryDetails">
        <button class="btn btn-primary fs-12 px-1 px-lg-2 py-1">
          Set Mail Address
        </button>
        <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
      </template>
      <template v-else>
        <button class="btn btn-success fs-12 px-1 px-lg-2 py-1">
          Update Mail Address
        </button>
        <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
      </template>
    </div>
    <div v-else class="d-flex align-items-center gap-2">
      <span v-if="transactionRef.deliveryMethod === 'SMS'" class="fs-14 fw-semibold">{{ countryCode }}</span>
      <div v-if="!isEditRecipient" class="text-truncate fs-14" style="max-width: 180px;">
        {{ transactionRef.deliveryDetails }}
      </div>
      <div v-if="transactionRef.deliveryDistribution">
        DL - {{ transactionRef.deliveryDistribution.name }}
      </div>
      <input
        v-if="isEditRecipient"
        v-model="transactionRef.deliveryDetails"
        id="deliveryDetailsInput"
        :class="['form-control fw-semibold fs-14 p-1', { 'invalid-email': !isValidEmail }]"
        :disabled="transactionRef.status === 5 || transactionRef.sequenceNumber > 1 || transactionRef.status === 9"
        :placeholder="transactionRef.deliveryMethod === 'SMS' ? 'Phone Number' : 'Email Address'"
        :maxlength="transactionRef.deliveryMethod === 'SMS' ? 10 : 40"
        required
        type="text"
        @blur="onBlurDeliveryDetails"
      >
      <label
        v-if="transactionRef.sequenceNumber === 1 && transactionRef.status !== 5"
        for="deliveryDetailsInput"
        class="m-0 mb-1 text-primary"
        style="cursor: pointer;"
        @click="setRecipient"
      >
        <VTooltip v-if="!isEditRecipient" :triggers="['hover']">
          <svg  width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
          <template #popper>
            Set single recipient
          </template>
        </VTooltip>
        <div v-else class="d-flex gap-2">
          <VTooltip :triggers="['hover']">
            <svg width="18" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
            <template #popper>
              Set single recipient
            </template>
          </VTooltip>
        </div>
      </label>
      <VTooltip :triggers="['hover']">
        <div
          v-if="!(transactionRef.status === 5 || transactionRef.sequenceNumber > 1 || transactionRef.status === 9)"
          :class="transactionRef.deliveryDistribution ? 'text-success' : 'text-primary'"
          data-bs-target="#modalDeliveryDistributions"
          data-bs-toggle="modal"
          style="cursor: pointer;"
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="20" fill="currentColor" viewBox="0 0 640 512"><path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"/></svg>
        </div>
        <template #popper>
          Select distribution list
        </template>
      </VTooltip>
      <ModalDeliveryDistributions :user="user" :transaction="transactionRef" @setRecipientsWithDistribution="setRecipientsWithDistribution" />
    </div>
  </div>

  <div v-if="!isValidEmail" class="text-danger">Invalid Email.</div>

  <div class="mb-1 d-flex gap-2 align-items-center">
    <VTooltip :triggers="['hover', 'click']">
      <label for="dateScheduleInput" class="form-label m-0 text-dark-blue fw-bold fs-14">
        Delivery Schedule: {{ computedSchedule ? new Date(computedSchedule).toLocaleString() : '' }}
      </label>
      <template #popper>
        This is the date/time your message will be delivered
      </template>
    </VTooltip>
    <div class="fs-12 position-relative">
      <span
        class="position-absolute text-primary"
        :style="[
          'top: 3px; left: 2px;',
          props.transaction.deliveryDetails ? '' : 'filter: none; opacity: 0.5;'
        ]"
      >
        <svg fill="currentColor" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z"/></svg>
      </span>
      <VTooltip :triggers="['hover', 'click']">
        <div v-if="!props.transaction.deliveryDetails" class="position-absolute" style="height: 100%; width: 22px; opacity: 0; z-index: 999;" />
        <template #popper>
          Please save
          {{ props.transaction.deliveryMethod === 'Mail' ? 'delivery address' :
            props.transaction.deliveryMethod === 'SMS' ? 'phone number' : 'email address' }}
          first by tapping blue tick.
        </template>
      </VTooltip>
      <input
        v-model="computedSchedule"
        :disabled="transactionRef.status === 5 || !props.transaction.deliveryDetails || transactionRef.status === 9"
        class="form-control p-0 position-relative"
        id="dateScheduleInput"
        type="datetime-local"
        style="width: 22px; opacity: 0; z-index: 998;"
      />
    </div>
  </div>

  <div class="d-flex gap-3">
    <div v-if="(transactionRef.deliveryMethod === 'Mail')" class="d-flex gap-2">
      <VTooltip :triggers="['hover', 'click']">
        <label class="form-check-label text-dark-blue fs-14 fw-bold" for="priorityCheckbox">
          Priority
        </label>
        <template #popper>
          For an additional cost you can send your MAIL by Priority Post
        </template>
      </VTooltip>
      <input
        v-model="transactionRef.priority"
        id="priorityCheckbox"
        :checked="transactionRef.priority"
        :disabled="transactionRef.status === 5"
        class="form-check-input"
        type="checkbox"
        :false-value="0"
        :true-value="1"
        @change="saveTransaction(transactionRef)"
      >
    </div>
  </div>

  <div class="mb-1 d-flex align-items-center gap-2">
    <ModalContactDetails :user="user" :transaction="transactionRef" @updateTransactionContactDetails="updateTransactionContactDetails" />
    <VTooltip :triggers="['hover', 'click']">
      <button
        v-if="transactionRef.status !== 5"
        :disabled="(transactionRef.deliveryMethod === 'Email' && !isValidEmail && !transactionRef.deliveryDistribution) || (!transactionRef.deliveryDetails && !transactionRef.deliveryDistribution)"
        class="btn btn-primary fs-12 px-1 px-lg-2 py-1" data-bs-target="#modalContactDetails" data-bs-toggle="modal"
      >
        Attach My Contact Details
      </button>
      <template #popper>
        Tap here to attach the Contact Details you would like to share with the Receiver
      </template>
    </VTooltip>
    <div v-if="transaction.contactDetails && Object.values(transaction.contactDetails).some(value => value === true)" class="fs-12 d-flex gap-1">
      <VTooltip :triggers="['hover', 'click']">
        <span v-if="transaction.contactDetails.name" class="border border-primary rounded px-1 text-white bg-primary">
          <svg height="14" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
        </span>
        <template #popper>
          Sending your profile Name to the receiver
        </template>
      </VTooltip>
      <VTooltip :triggers="['hover', 'click']">
        <span v-if="transaction.contactDetails.email" class="border border-primary rounded px-1 text-white bg-primary">
          <svg height="14" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256v32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32v80 32c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"/></svg>
        </span>
        <template #popper>
          Sending your profile Mail Address to the receiver
        </template>
      </VTooltip>
      <VTooltip :triggers="['hover', 'click']">
        <span v-if="transaction.contactDetails.mobile" class="border border-primary rounded px-1 text-white bg-primary">
          <svg height="14" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
        </span>
        <template #popper>
          Sending your profile Mobile Phone Number to the receiver
        </template>
      </VTooltip>
      <VTooltip :triggers="['hover', 'click']">
        <span v-if="transaction.contactDetails.address" class="border border-primary rounded px-1 text-white bg-primary">
          <svg height="14" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
        </span>
        <template #popper>
          Sending your profile Email Address to the receiver
        </template>
      </VTooltip>
    </div>
  </div>

  <template v-if="isMobile">
    <hr>
    <div class="text-dark-blue d-flex justify-content-between align-items-center flex-wrap">
      <Stepper :transaction="transactionRef"/>
    </div>
  </template>

  <div v-if="transactionRef.deliveryMethod === 'Email' && category.useCase.attachment" class="mt-1 edit-base__attachment">
    <div class="d-flex align-items-center gap-2">
      <VTooltip :triggers="['hover', 'click']">
        <router-link :to="{ name: 'Attachment' }">
          <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512" fill="#0d6efd"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
        </router-link>
        <template #popper>
          <div class="fs-12">Click icon for more information.</div>
        </template>
      </VTooltip>
      <VTooltip :triggers="['hover', 'click']">
        <label for="attachment" class="form-check-label text-dark-blue fs-14 fw-bold d-flex align-items-center gap-2">
          Attach Image:
          <div class="edit-base__attachment-icon">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"/></svg>
          </div>
          <div class="text-dark-blue fw-normal fs-14">{{ transactionRef.attachment && transactionRef.attachment.name }}</div>
        </label>
        <template #popper>
          <div class="fs-12">Add an Image to be attached to this message.</div>
        </template>
      </VTooltip>
      <template v-if="transactionRef.attachment && transactionRef.attachment.url">
        <button class="btn btn-primary fs-12 py-1 px-2" data-bs-target="#modalPreviewAttachment" data-bs-toggle="modal">
          Preview Image
        </button>
        <ModalPreviewAttachment :attachment="transactionRef.attachment" :coordinates="attachmentCoordinates" />
      </template>
    </div>
    <div v-if="transactionRef.attachment && transactionRef.attachment.url && attachmentCoordinates" class="ps-4 d-flex align-items-center gap-2">
      <label for="imageLocation" class="form-check-label text-dark-blue fs-14 fw-bold">
        Image Location:
      </label>
      <input
        v-model="transactionRef.attachment.isMap"
        id="imageLocation"
        class="form-check-input mt-0"
        type="checkbox"
        @change="saveTransaction(transactionRef)"
      >
    </div>
    <input
      id="attachment"
      class="edit-base__attachment-input"
      :disabled="transactionRef.status === 5 || (transactionRef.deliveryMethod === 'Email' && !isValidEmail && !transactionRef.deliveryDistribution) || (!transactionRef.deliveryDetails && !transactionRef.deliveryDistribution)"
      type="file"
      accept="image/jpeg, image/png, image/jpg"
      @change="uploadAttachment"
    >
    <div
      v-if="uploadStatus"
      :class="{
        'alert alert-dismissible fade show mt-3': true,
        'alert-primary': uploadStatus === 'uploading',
        'alert-success': uploadStatus === 'done',
        'alert-danger': uploadStatus === 'failed',
        'alert-danger': uploadStatus === 'failed. Exceeds 15MB.'
      }"
      role="alert"
    >
      {{ uploadStatus === 'uploading' ? uploadStatus : 'Upload ' + uploadStatus }}
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  </div>

  <VTooltip :disabled="Boolean(props.transaction.deliveryDetails || props.transaction.deliveryDistribution)" :triggers="['hover', 'click']">
    <div class="my-3 border-top border-bottom border-primary" @click="onClickParagraphs">
      <div v-for="(paragraph, paragraphIdx) in transactionRef.paragraphs" :key="paragraph.id">
        <div v-if="paragraph.isOptional" class="d-flex justify-content-end">
          <VTooltip :triggers="['hover', 'click']" class="d-flex align-items-center gap-1">
            <input
              v-model="paragraph.isIncluded"
              :id="`isIncludedCheckbox${paragraph.id}`"
              :disabled="transactionRef.status === 5 || transactionRef.sequenceNumber > 1
                || !props.transaction.deliveryDetails || transactionRef.status === 9
                || (transactionRef.deliveryMethod === 'Mail' && transactionRef.status === 3)"
              class="form-check-input mb-1"
              style="margin-left: -12px;"
              type="checkbox"
              @change="saveTransaction(transactionRef)"
            >
            <label :for="`isIncludedCheckbox${paragraph.id}`" class="form-check-label text-dark-blue fs-14 fw-bold">
              Include
            </label>
            <template #popper>
              This paragraph is optional, tick box to include paragraph in your message
            </template>
          </VTooltip>
        </div>
        <div
          :id="`panelsStayOpen-collapseOne-${paragraph.id}`"
          :aria-labelledby="`panelsStayOpen-heading-${paragraph.id}`"
        >
          <div
            class="lh-sm py-1"
            :data-bs-target="transactionRef.status === 5 || !paragraph.vars || !isValidEmail || (!transactionRef.deliveryDetails && !transactionRef.deliveryDistribution) ? '' : '#modalVariables'"
            :data-bs-toggle="transactionRef.status === 5 || !paragraph.vars || !isValidEmail || (!transactionRef.deliveryDetails && !transactionRef.deliveryDistribution) ? '' : 'modal'"
            @click="selectParagraph(paragraph)"
          >
            <small
              v-if="transactionRef.deliveryMethod !== 'SMS'"
              class="m-0 edit-message__paragraph"
              style="word-spacing: 8px;"
              v-html="addVarStyling(paragraph.preview || paragraph.text)"
            />
            <small
              v-else
              class="m-0 edit-message__paragraph"
              style="word-spacing: 8px;"
              v-html="addVarStyling(displayVarSMS(paragraph.preview || paragraph.text))"
            />
          </div>
        </div>
        <hr v-if="paragraphIdx < transactionRef.paragraphs.length - 1">
      </div>
    </div>
    <template #popper>
      Please save
      {{ props.transaction.deliveryMethod === 'Mail' ? 'delivery address' :
        props.transaction.deliveryMethod === 'SMS' ? 'phone number' : 'email address' }}
      first by tapping blue tick.
    </template>
  </VTooltip>

  <ModalVariables
    :variables="selectedParagraph.vars"
    @saveVariables="saveVariables"
    @selectVariableOption="selectVariableOption"
  />

  <ModalDeliveryMail
    v-if="transactionRef.deliveryMethod ==='Mail'"
    :deliveryDetails="deliveryDetails"
    :transaction="transaction"
    />
</template>

<script setup>
import axios from 'axios'
import Exif from 'exif-js'
import { app } from '@/firebase'
import { getStorage, ref as sRef, uploadBytes, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { computed, defineEmits, defineProps, onMounted, onUnmounted, ref } from 'vue'
// import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { convertToNextSequence } from '@/utils'
import ModalContactDetails from '@/components/MessageEdit/ModalContactDetails.vue'
import ModalVariables from '@/components/MessageEdit/ModalVariables.vue'
import ModalDeliveryMail from '@/components/MessageEdit/ModalDeliveryMail.vue'
import ModalPreviewAttachment from '@/components/MessageEdit/ModalPreviewAttachment.vue'
import ModalDeliveryDistributions from '@/components/MessageEdit/ModalDeliveryDistributions.vue'
import Stepper from '@/components/Stepper.vue'

import { convertStatus } from '@/utils'
import IconDelivered from '@/assets/icons/status-delivered.svg'
import IconFailed from '@/assets/icons/status-failed.svg'
import IconIncomplete from '@/assets/icons/status-incomplete.svg'
import IconProcessing from '@/assets/icons/status-processing.svg'
import IconQueue from '@/assets/icons/status-queue.svg'
import IconReadyToSend from '@/assets/icons/status-readyToSend.svg'
import IconSending from '@/assets/icons/status-sending.svg'

const emit = defineEmits(['saveTransaction'])
const props = defineProps({ transaction: { required: true, type: Object } })
// const router = useRouter()
const store = useStore()
const functions = getFunctions()
const storage = getStorage(app)
const spamChecker = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_spamChecker`)

const categories = computed(() => store.state.categories.data)
const templates = computed(() => store.state.templates.data)
const transactions = computed(() => store.state.transactions.data)
const user = computed(() => store.state.user.currentUser)

const deliveryDetails = ref(props.transaction.deliveryDetails)
const isNotSpam = ref(true)
const isEditRecipient = ref(Boolean(!props.transaction.deliveryDetails && !props.transaction.deliveryDistribution))
const isLoading = ref(false)
const isValidEmail = ref(true)
const selectedParagraph = ref({})
const transactionRef = ref(JSON.parse(JSON.stringify(props.transaction)))
const uploadStatus = ref(null)
const windowWidth = ref(window.innerWidth)

const attachmentCoordinates = computed({
  get: () => {
    return transactionRef.value && transactionRef.value.attachment && transactionRef.value.attachment.coordinates
  },
  set: (val) => {
    transactionRef.value.attachment.coordinates = val
  }
})

const category = computed(() => {
  const template = templates.value.find((it) => it.id === props.transaction.messageTemplateId)

  if (template) {
    const category = categories.value.find((it) => it.id === template.categoryId)
    const useCase = category.useCases.find(it => it.id === template.useCaseId)
    return { name: category.name, useCase: useCase }
  }

  return { name: 'No category', useCase: 'No use case' }
})

const computedSchedule = computed({
  get: () => {
    if (!transactionRef.value.schedule) return ''

    const d = new Date(transactionRef.value.schedule * 1000)
    const year = d.getFullYear()
    const month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1
    const date = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
    const hour = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()
    const min = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
    const fullDate = year + '-' + month + '-' + date + ' ' + hour + ':' + min + ':00'
    return fullDate
  },
  set: (val) => {
    transactionRef.value.schedule = new Date(val).getTime() / 1000
    saveTransaction(transactionRef.value)
  }
})

const countryCode = computed(() => {
  return (user.value && user.value.country.dial_code) || '+61'
})

// const hasAddress = computed(() => {
//   if (!user.value.address || !user.value.address.full) return false

//   return true
// })

const isMobile = computed(() => {
  return windowWidth.value < 1024
})

function addVarStyling (paragraph) {
  return paragraph.replaceAll('&lt;&lt;', '<span class="edit-message__paragraph-var">&lt;&lt;').replaceAll('gt;&gt;','gt;&gt;</span>')
}

function addVarSelectStyling (paragraph) {
  if (transactionRef.value.deliveryMethod === 'SMS') {
    return paragraph
    .replaceAll('<<', '<span class="edit-message__paragraph-var edit-message__paragraph-var--selected"><<').replaceAll('>>','>></span>')
  }

  return paragraph
    .replaceAll('&lt;&lt;', '<span class="edit-message__paragraph-var edit-message__paragraph-var--selected">&lt;&lt;')
    .replaceAll('gt;&gt;','gt;&gt;</span>')
}

function displayVarSMS (paragraph) {
  return paragraph.replaceAll('<<', '&lt;&lt;').replaceAll('>>', '&gt;&gt;')
}

function getStatusIcon (status) {
  switch (convertStatus(status)) {
    case 'Delivered':
      return IconDelivered
    case 'Failed':
      return IconFailed
    case 'Incomplete Details':
      return IconIncomplete
    case 'Processing Message':
      return IconProcessing
    case 'In Queue':
      return IconQueue
    case 'Ready To Send':
      return IconReadyToSend
    case 'Sending':
      return IconSending
    default:
      return ''
  }
}

function onBlurDeliveryDetails () {
  if (transactionRef.value.deliveryMethod === 'Email' && !transactionRef.value.deliveryDistribution) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    isValidEmail.value = emailPattern.test(transactionRef.value.deliveryDetails)
  }
}

function onClickParagraphs () {
  if (!transactionRef.value.deliveryDetails && !transactionRef.value.deliveryDistribution) return
  // isEditRecipient.value = false
  // transactionRef.value.deliveryDetails = props.transaction.deliveryDetails || ''
  // transactionRef.value.deliveryDistribution = props.transaction.deliveryDistribution || null
  // isNotSpam.value = true
}

// set email & sms recipient
async function setRecipient () {
  if (transactionRef.value.status === 5) return false
  isLoading.value = true

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  if (isEditRecipient.value) {
    if (transactionRef.value.deliveryMethod === 'Email') {
      isValidEmail.value = emailPattern.test(transactionRef.value.deliveryDetails)

      if (!isValidEmail.value) {
        isLoading.value = false
        return
      }
    }

    // create next sequence if the original is sent
    const matchingTransactions = transactions.value.filter(it => {
      return it.deliveryDetails === transactionRef.value.deliveryDetails &&
        it.deliveryMethod === props.transaction.deliveryMethod &&
        it.category === transactionRef.value.category &&
        it.useCase === transactionRef.value.useCase
    })

    const filteredMatchTransactions = matchingTransactions && matchingTransactions.filter(it => it.id !== transactionRef.value.id)

    // condition for converting to next sequence
    let highestSequenceNumber = -Infinity
    let latestTransaction = null

    for (const trans of filteredMatchTransactions) {
      if (trans.sequenceNumber > highestSequenceNumber) {
        highestSequenceNumber = trans.sequenceNumber
        latestTransaction = trans
      }
    }

    if (latestTransaction && latestTransaction.status === 5) {
      const isConverted = await convertToNextSequence(latestTransaction, transactionRef.value.id)

      if (isConverted) {
        isLoading.value = false
        return
      }
    }

    isNotSpam.value = await spamChecker(transactionRef.value).then(res => res.data)

    if (!isNotSpam.value) {
      isLoading.value = false

      // if latestTransaction status !== 5 or UNDELIVERED, then set status to 8 or INVALID RECIPIENT
      saveTransaction({
        ...transactionRef.value,
        deliveryDetails: transactionRef.value.deliveryDetails,
        deliveryDistribution: null,
        messageLabel: transactionRef.value.deliveryDetails,
        status: 8
      })

      return
    }
  }

  isLoading.value = false
  isEditRecipient.value = !isEditRecipient.value

  if (!isEditRecipient.value) {
    saveTransaction({
      ...transactionRef.value,
      deliveryDetails: transactionRef.value.deliveryDetails,
      deliveryDistribution: null,
      messageLabel: transactionRef.value.deliveryDetails,
      status: 3
    })
  }
}

async function setRecipientsWithDistribution (distribution) {
  isLoading.value = true
  isNotSpam.value = await spamChecker({ ...transactionRef.value, deliveryDetails: null, deliveryDistribution: distribution }).then(res => res.data)

  if (!isNotSpam.value) {
    const matchingTransactions = transactions.value.filter(it => {
      return it.deliveryDetails === transactionRef.value.deliveryDetails &&
        it.deliveryMethod === transactionRef.value.deliveryMethod &&
        it.messageTemplateId === transactionRef.value.messageTemplateId
    })

    const filteredMatchTransactions = matchingTransactions && matchingTransactions.filter(it => it.id !== transactionRef.value.id)

    // condition for converting to next sequence
    let highestSequenceNumber = -Infinity
    let latestTransaction = null

    for (const trans of filteredMatchTransactions) {
      if (trans.sequenceNumber > highestSequenceNumber) {
        highestSequenceNumber = trans.sequenceNumber
        latestTransaction = trans
      }
    }

    if (latestTransaction && latestTransaction.status === 5) {
      const isConverted = await convertToNextSequence({ ...latestTransaction, deliveryDistribution: distribution }, transactionRef.value.id)

      if (isConverted) {
        isLoading.value = false
        return
      }
    }

    isLoading.value = false

    // if latestTransaction status !== 5 or UNDELIVERED, then set status to 8 or INVALID RECIPIENT
    saveTransaction({
      ...transactionRef.value,
      deliveryDetails: null,
      deliveryDistribution: distribution,
      messageLabel: 'DL - ' + distribution.name,
      status: 8
    })

    return
  }

  isLoading.value = false

  saveTransaction({
    ...transactionRef.value,
    deliveryDetails: null,
    deliveryDistribution: distribution,
    messageLabel: 'DL - ' + distribution.name,
    status: 3
  })
}

function saveTransaction (trans) {
  if (trans.deliveryDistribution) {
    trans.deliveryDetails = null
  }

  if (trans.deliveryMethod === 'Email' && !trans.deliveryDistribution) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    isValidEmail.value = emailPattern.test(trans.deliveryDetails)

    if (!isValidEmail.value) return
  }

  emit('saveTransaction', trans)
}

function saveVariables () {
  saveTransaction(transactionRef.value)
  document.getElementById('modalVariablesClose').click()
}

function selectParagraph (paragraph) {
  if (paragraph.vars) {
    selectedParagraph.value = {
      ...paragraph,
      vars: paragraph.vars.map(it => {
        if (it.name.substring(0, 4).toLowerCase() === 'time' && !it.selected) {
          return { ...it, selected: '10:00 AM' }
        }

        if (it.selected && it.selected.includes('&lt;&lt;')) {
          return { ...it, selected: '' }
        }

        return it
      })
    }
  } else {
    selectedParagraph.value = paragraph
  }
}

function selectVariableOption (selectedVariable) {
  const paragraphs = transactionRef.value.paragraphs
  const paragraphIdx = paragraphs.findIndex((it) => it.id === selectedParagraph.value.id)
  const variableIdx = paragraphs[paragraphIdx].vars.findIndex((it) => it.id === selectedVariable.id)
  let preview
  if (transactionRef.value.deliveryMethod === 'SMS') {
    preview = addVarSelectStyling(paragraphs[paragraphIdx].text).split((/(<<+[\w\s\S]+?>>)/g))
  } else {
    preview = addVarSelectStyling(paragraphs[paragraphIdx].text).split((/(&lt;&lt;+[\w\s\S]+?&gt;&gt;)/g))
  }

  const result = preview.map((it) => {
    let findVariable
    if (transactionRef.value.deliveryMethod === 'SMS') {
      findVariable = paragraphs[paragraphIdx].vars.find((v) => `<<${v.name}>>` === it)
    } else {
      findVariable = paragraphs[paragraphIdx].vars.find((v) => `&lt;&lt;${v.name}&gt;&gt;` === it)
    }

    if (!findVariable) {
      return it
    } else if (findVariable.id === selectedVariable.id) {
      return selectedVariable.selected
    }

    let noSelected
    if (transactionRef.value.deliveryMethod === 'SMS') {
      noSelected = `<<${findVariable.name}>>`
    } else {
      noSelected = `&lt;&lt;${findVariable.name}&gt;&gt;`
    }

    return findVariable.selected || noSelected
  })

  // will update transactionRef.value.paragraphs because of reference
  paragraphs[paragraphIdx].preview = result.join('')
  paragraphs[paragraphIdx].vars[variableIdx].selected = selectedVariable.selected

  if (transactionRef.value.file) {
    transactionRef.value.isFileOutdated = true
  }
}

function updateTransactionContactDetails (contactDetails) {
  if (transactionRef.value.file) {
    transactionRef.value.isFileOutdated = true
  }

  saveTransaction({ ...transactionRef.value, contactDetails })
}

function uploadAttachment (e) {
  transactionRef.value.attachment = { name: '', url: '' }
  const file = e.target.files[0]

  const fileSizeLimitInMB = 15
  const fileSizeInMB = file.size / (1024 * 1024)

  if (fileSizeInMB > fileSizeLimitInMB) {
    uploadStatus.value = 'failed. Exceeds 15MB.'
    return
  }

  if (!file) {
    return
  }

  const convertToDecimalDegress = (val, ref) => {
    if (!val || (val && val.length === 0)) {
      return null
    }

    const degrees = val[0]
    const minutes = val[1]
    const seconds = val[2]

    const decimalDegrees = degrees + (minutes / 60) + (seconds / 3600)

    if (ref === 'S' || ref === 'W') {
      return decimalDegrees * -1
    }

    return decimalDegrees
  }

  Exif.getData(file, () => {
    const gpsInfo = Exif.getAllTags(file)
    const latRef = gpsInfo.GPSLatitudeRef
    const lat = gpsInfo.GPSLatitude
    const lngRef = gpsInfo.GPSLongitudeRef
    const lng = gpsInfo.GPSLongitude

    if (!lat || !lng || !latRef || !lngRef) {
      attachmentCoordinates.value = null
    } else {
      attachmentCoordinates.value = {
        lat: convertToDecimalDegress(lat, latRef),
        lng: convertToDecimalDegress(lng, lngRef)
      }
    }
  })

  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    const attachmentsRef = sRef(storage, `attachments/${user.value.uniqueID}/${transactionRef.value.id}`)
    const uploadTask = uploadBytesResumable(attachmentsRef, file)

    return uploadTask.on('state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log('Upload is ' + progress + '% done')
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused')
            break
          case 'running':
            uploadStatus.value = 'uploading'
            console.log('Upload is running')
            break
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log('error upload: ', error)
        uploadStatus.value = 'failed'
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          transactionRef.value.attachment = {
            coordinates: attachmentCoordinates.value,
            mapUrl: null,
            name: file.name,
            url: downloadURL
          }

          uploadStatus.value = 'done'
          if (attachmentCoordinates.value) {
            getMapImage()
          } else {
            saveTransaction(transactionRef.value)
          }
        })
      }
    )
  }
}

function getMapImage () {
  const mapZoom = '13'
  if (!attachmentCoordinates.value) return

  const mapCenter = `${attachmentCoordinates.value.lat},${attachmentCoordinates.value.lng}`
  const markerPosition = mapCenter

  const mapApiUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${mapCenter}&zoom=${mapZoom}&size=500x500&maptype=roadmap&markers=color:red%7C${markerPosition}&key=${process.env.VUE_APP_GOOGLE_API_KEY}`

  axios.get(mapApiUrl, { responseType: 'blob' })
    .then(response => {
      // const imageUrl = URL.createObjectURL(new Blob([response.data]))
      const mapImageBlob = new Blob([response.data])

      // Upload the Blob to Firebase Storage
      const attachmentsRef = sRef(storage, `attachments/${user.value.uniqueID}/map_${transactionRef.value.id}`)
      uploadBytes(attachmentsRef, mapImageBlob, { contentType: 'image/jpeg' })
        .then(uploadTaskSnapshot => {
          getDownloadURL(uploadTaskSnapshot.ref).then((downloadURL) => {
            transactionRef.value.attachment = {
              ...transactionRef.value.attachment,
              isMap: true,
              mapUrl: downloadURL
            }

            saveTransaction(transactionRef.value)
          })
        })
        .catch(error => {
          console.error('Error uploading image:', error)
        })
    })
    .catch(error => {
      console.error('Error fetching map image:', error)
    })
}
const handleResize = () => {
    windowWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<style lang="scss">
.edit-base__attachment {
  display: inline-block;
  position: relative;

  &-input {
    opacity: 0;
    position: absolute;
    width: 0;
    z-index: 0;
  }
}

.edit-message__paragraph {
  p {
    margin-bottom: 0;
  }

  &-var {
    color: #2a2a2a;
    font-weight: bold;
    text-shadow: 1px 1px 4px #0c63e4;

    &--selected {
      text-shadow: unset;
    }
  }
}

.invalid-email {
  &:focus {
    border-color: #dc3545 !important;
    box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%) !important;
  }
}
</style>

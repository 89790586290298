ery<template>
  <div class="d-flex justify-content-between mb-3">
    <small class="text-muted mb-3">
      {{ sequences.length }} Items
    </small>
  </div>
  <div class="receiver-list align-content-start d-grid pe-2">
    <div class="mb-3">
      <div v-if="isResolveSuccess" class="alert alert-success fade show m-0" role="alert">
        Successfully updated.
      </div>
      <div v-if="isResolveError" class="alert alert-danger fade show m-0" role="alert">
        <strong>Failed to update.</strong>
      </div>
    </div>
    <div class="accordion">
      <div v-for="(sequence, sequenceIdx) in sequences" :key="sequence.id" class="accordion-item">
        <h2 class="accordion-header" :id="'panelsStayOpen-heading' + sequenceIdx">
          <button
            :data-bs-target="'#panelsStayOpen-collapse' + sequenceIdx"
            aria-expanded="false"
            class="accordion-button collapsed fs-14 d-flex gap-4"
            :class="{'text-success': sequence.isResolve}"
            data-bs-toggle="collapse"
            type="button"
            style="padding-left: 32px;"
            :style="{
              'background-color': sequence.isResolve ? '#e7fff1' : 'inherit',
            }"
          >
            <VTooltip :triggers="['hover', 'click']">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center gap-2">
                  <label :for="`checkbox-resolve-${sequence.id}`">Resolved ?</label>
                  <input
                    v-model="sequence.isResolve"
                    :id="`checkbox-resolve-${sequence.id}`"
                    type="checkbox"
                    @change="onChangeResolve(sequence)"
                  >
                </div>
              </div>
              <template #popper>
                Check the tickbox if the issue has been completed to your satisfaction
              </template>
            </VTooltip>
            <VTooltip :triggers="['hover']">
              <img :src="getDeliveryMethodIcon(sequence.deliveryMethod)">
              <template #popper>
                {{ sequence.deliveryMethod }}
              </template>
            </VTooltip>
            <div class="d-flex gap-2">
              <strong>
                {{ typeof sequence.receiver === 'object' ? sequence.receiver.name : sequence.receiver }}
              </strong>
              <template v-if="typeof sequence.receiver === 'object'">
                <VTooltip :triggers="['hover']">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" fill="currentColor" viewBox="0 0 640 512"><path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"/></svg>
                  <template #popper>
                    Distribution List
                  </template>
                </VTooltip>
              </template>
            </div>
          </button>
        </h2>
        <div
          :id="'panelsStayOpen-collapse' + sequenceIdx"
          :aria-labelledby="'panelsStayOpen-heading' + sequenceIdx"
          class="accordion-collapse collapse"
        >
          <div class="accordion-body bg-light bg-gradient">
            <div v-for="message in sequence.messages" :key="message.messageId">
              <router-link :to="{ name: 'MessageEdit', params: { id: message.messageId } }">
                <span class="text-capitalize">
                  {{ sequence.category }} |
                  {{ sequence.useCase }} |
                  {{ sequence.deliveryMethod }} |
                  {{ message.sequenceNumber || 1 }} -
                  {{ message.sentAt ? `Sent ${formatDateTime(message.sentAt)}` : `Created ${formatDateTime(message.createdAt)}`}}
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/*eslint-disable*/
import { getFunctions, httpsCallable } from 'firebase/functions'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import IconMail from '@/assets/icons/delivery-mail.svg'
import IconEmail from '@/assets/icons/delivery-email.svg'
import IconSms from '@/assets/icons/delivery-sms.svg'

const functions = getFunctions()
const store = useStore()
const isResolveSuccess = ref(false)
const isResolveError = ref(false)

const sequences = computed(() => {
  const sequences = store.state.sequences.data
  return sequences
    .sort((a, b) => {
      const receiverA = typeof a.receiver === 'object' ? a.receiver.name.toLowerCase() : a.receiver.toLowerCase()
      const receiverB = typeof b.receiver === 'object' ? b.receiver.name.toLowerCase() : b.receiver.toLowerCase()
      if (receiverA < receiverB) return -1
      if (receiverA > receiverB) return 1
      return 0
    })
})

function autoHideAlerts () {
  setTimeout(() => {
    isResolveSuccess.value = false
    isResolveError.value = false
  }, 3000)
}

function formatDateTime (dateTime) {
  const date = new Date(dateTime)
  return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().slice(2)}`
}

function getDeliveryMethodIcon (method) {
  switch (method) {
    case 'Mail':
      return IconMail
    case 'Email':
      return IconEmail
    default:
      return IconSms
  }
}

function onChangeResolve (sequence) {
  const resolveSequence = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_resolveSequence`)
  resolveSequence(sequence)
    .then(res => {
      if (res.data) {
        isResolveSuccess.value = true
        isResolveError.value = false
      } else {
        isResolveSuccess.value = false
        isResolveError.value = true
      }
      autoHideAlerts()
    })
    .catch(() => {
      isResolveSuccess.value = false
      isResolveError.value = true
      autoHideAlerts()
    })
}
</script>

<style lang="scss" scoped>

.receiver-list {
  max-height: calc(100vh - 300px);
  overflow: auto;
}

.accordion-button::after {
  content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%230d6efd" viewBox="0 0 448 512"><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>'); /* Chevron-up icon */
  height: 12px;
  left: 10px;
  position: absolute;
  width: 12px;
}

.accordion-button.collapsed::after {
  background-image: none;
  content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>'); /* Chevron-up icon */
  top: 17px;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
}

@media only screen and (max-width: 430px) {
  .receiver-list__title {
    max-width: 210px;
  }
}
</style>

// useGoogleRecaptcha.js
import { ref } from 'vue'

export default function useGoogleRecaptcha () {
  const recaptchaResponse = ref(null)
  let isRecaptchaLoaded = false

  const loadRecaptcha = () => {
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_RECAPTCHA_SITE_KEY}`
    script.async = true
    script.defer = true

    script.onload = () => {
      isRecaptchaLoaded = true
    }

    document.head.appendChild(script)
  }

  const executeRecaptcha = () => {
    return new Promise((resolve, reject) => {
      if (!isRecaptchaLoaded) {
        reject(new Error('reCAPTCHA not loaded yet'))
        return
      }

      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'signup' }).then((token) => {
          recaptchaResponse.value = token
          resolve(token)
        })
      })
    })
  }

  return { recaptchaResponse, loadRecaptcha, executeRecaptcha }
}

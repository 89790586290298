<template>
  <div class="modal fade" id="modalTopup" tabindex="-1" aria-labelledby="modalTopupLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalTopupLabel">Purchase Credits</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="props.selectedCredits" class="mb-3">
            <div>
              Credited amount to your account: <strong>${{ props.selectedCredits }}</strong>
            </div>
            <div>
              Total Cost: <strong>${{ props.selectedCredits }}</strong>
            </div>
          </div>
          <div v-if="props.isCustomCredits" class="mb-3">
            <div
              v-if="props.selectedCredits && errorDueToMinimum"
              class="alert alert-danger alert-dismissible fade show" role="alert"
            >
              Minimum amount is ${{ props.minimumTopupAmount }}
            </div>
            <label for="topupAmount">Custom Amount:</label>
            <input class="form-control" placeholder="00.00" type="text" @input="formatTopupAmount">
          </div>
          <div id="card-container"></div>
          <button
            id="card-button"
            class="btn btn-primary rounded-pill w-100"
            :disabled="!props.selectedCredits || errorDueToMinimum"
            data-bs-dismiss="modal"
            type="button"
          >
            Top Up
          </button>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue'

const emit = defineEmits(['setCustom'])
const props = defineProps(['selectedCredits', 'minimumTopupAmount', 'isCustomCredits'])
const topupAmount = ref('')

const errorDueToMinimum = computed(() => {
  return props.isCustomCredits && parseFloat(props.selectedCredits) < props.minimumTopupAmount
})

function formatTopupAmount (evt) {
  topupAmount.value = evt.target.value.replace(/[^0-9.]/g, '')

  // Remove leading zeros
  topupAmount.value = topupAmount.value.replace(/^0+/, '')

  // Format the input to have two decimal places
  const parts = topupAmount.value.split('.')
  if (parts.length > 1) {
    parts[1] = parts[1].substring(0, 2)
    topupAmount.value = parts.join('.')
  }

  // Ensure no negative numbers
  if (parseFloat(topupAmount.value) < 0) {
    topupAmount.value = ''
  }

  const finalValue = parseFloat(topupAmount.value).toFixed(2)
  if (!finalValue || isNaN(finalValue)) {
    emit('setCustom', 0)
  } else {
    emit('setCustom', finalValue)
  }
}
</script>

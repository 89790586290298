<template>
  <div class="text-primary mb-3" @click="back">
    <svg fill="currentColor" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M47 239c-9.4 9.4-9.4 24.6 0 33.9L207 433c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L97.9 256 241 113c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L47 239z"/></svg>
  </div>
  <LoadingScreen v-if="!transaction" />
  <template v-else>
    <div class="d-flex justify-content-between py-3 border-bottom">
      <span>Category</span>
      <span class="text-muted">{{ transaction.category.name }}</span>
    </div>
    <div class="d-flex justify-content-between py-3 border-bottom">
      <span>Use Case</span>
      <span class="text-muted">{{ transaction.useCase.name }}</span>
    </div>
    <div class="d-flex justify-content-between py-3 border-bottom">
      <span>Created At</span>
      <span class="text-muted">{{ formatDateTime(transaction.createdAt) }}</span>
    </div>
    <div class="d-flex justify-content-between py-3 border-bottom">
      <span>Send DateTime</span>
      <span class="text-muted">{{ formatDateTime(transaction.sentAt) }}</span>
    </div>
    <div class="d-flex justify-content-between py-3 border-bottom">
      <span>Delivery Method</span>
      <span class="text-muted">{{ transaction.deliveryMethod }}</span>
    </div>
    <div class="d-flex justify-content-between py-3 border-bottom">
      <template v-if="transaction.deliveryMethod === 'Mail'">
        <span>Delivery Address</span>
        <span class="text-muted">{{ (transaction.deliveryDetails && transaction.deliveryDetails.full) || 'No address provided.' }}</span>
      </template>
      <template v-if="transaction.deliveryMethod === 'Email'">
        <span>Delivery Email</span>
        <span class="text-muted">{{ transaction.deliveryDetails || 'No email provided.' }}</span>
      </template>
      <template v-if="transaction.deliveryMethod === 'SMS'">
        <span>Delivery Phone</span>
        <span class="text-muted">{{ ('+' + transaction.deliveryDetails) || 'No phone number provided.' }}</span>
      </template>
    </div>
    <div class="d-flex justify-content-between py-3 border-bottom">
      <span>Provider Status</span>
      <span class="text-muted">{{ transaction.clicksend_status?.response_code || 'N/A' }}</span>
    </div>
  </template>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
const router = useRouter()
const store = useStore()
const transactionId = route.params.id

const transaction = computed(() => {
  const templates = store.state.templates.tempData
  const transactions = store.state.transactions.data

  if (templates && transactions) {
    const trans = transactions.find((it) => it.id === transactionId)
    const template = templates.find((it) => it.id === trans.messageTemplateId)
    return {
      ...trans,
      ...template
    }
  }

  return null
})

function back () {
  router.push({ name: 'AccountMessages' })
}

function formatDateTime (dateTime) {
  if (!dateTime) {
    return 'N/A'
  }

  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  const date  = new Date(dateTime)
  return date.toLocaleDateString('en-US', options) + ' ' + date.toLocaleTimeString()
}
</script>

<style>

</style>

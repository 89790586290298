<template>
  <div>
    <h1>Validation</h1>
    <div class="d-grid">
      <label for="">addressLine</label>
      <input v-model="address.addressLines" class="form-control" type="text" placeholder="(Example: 17 Oaktree Rise)"><br>

      <label for="">administrativeArea</label>
      <input v-model="address.administrativeArea" class="form-control" type="text" placeholder="(Example: VIC)"><br>

      <label for="">locality</label>
      <input v-model="address.locality" class="form-control" type="text" placeholder="(Example: LYSTERFIELD)"><br>

      <label for="">postalCode</label>
      <input v-model="address.postalCode" class="form-control" type="text" placeholder="(Example: 3156)"><br>

      <label for="">regionCode</label>
      <input v-model="address.regionCode" class="form-control" type="text" placeholder="(Example: AU)"><br>

      <button @click="validate">Validate</button>

      <template v-if="validateResponse">
        <h2>Response: </h2>
        <pre>{{ validateResponse }}</pre>
      </template>
    </div>
  </div>
  <hr>
  <div class="mb-5">
    <h1>Autocomplete</h1>
    <div>(set to minimum of {{ autocomponeteMinChars }} characters to show suggestions)</div>
    <v-select
      v-model="autocompleteInput"
      :clearSearchOnBlur="() => false"
      :options="suggestions"
      @search="onFetchSuggestions"
    >
      <template v-slot:no-options>
        No address found...
      </template>
    </v-select>
    <pre>{{ autocompleteInput }}</pre>
  </div>
</template>

<script setup>
import axios from 'axios'
import { ref } from 'vue'
import _ from 'lodash'

const autocompleteInput = ref('')
const autocomponeteMinChars = ref(3)
const suggestions = ref([])

const address = ref({
  addressLines: '', // ['Oaktree Rise']
  administrativeArea: '',
  locality: '',
  postalCode: '',
  regionCode: ''
})

const validateResponse = ref(null)

async function validate () {
  try {
    validateResponse.value = null
    const url = 'https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyAsVVvaRPvYRKCZY0-CENtvmaCDNMcoIys'
    const res = await axios.post(url, { address: address.value })

    if (res.status === 200) {
      validateResponse.value = res.data.result
    }
  } catch (err) {
    validateResponse.value = 'Error fetching API!!!'
  }
}

function onFetchSuggestions (searchInput, loading) {
  if (searchInput.length < autocomponeteMinChars.value) return

  if (searchInput.length) {
    loading(true)
    fetchSuggestions(loading, searchInput)
  }
}

const fetchSuggestions = _.debounce(async (loading, searchInput) =>  {
  const url = 'https://places.googleapis.com/v1/places:autocomplete?key=AIzaSyAsVVvaRPvYRKCZY0-CENtvmaCDNMcoIys'
  const res = await axios.post(url, { input: searchInput })

  if (res.status === 200) {
    suggestions.value = res.data.suggestions.map(suggestion => ({
      label: suggestion.placePrediction.text.text,
      value: suggestion
    }))
    loading(false)
  }
}, 500)
</script>

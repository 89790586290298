<template>
  <div class="modal fade" id="modalSearchFilters" tabindex="-1" aria-labelledby="modalSearchFiltersLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalSearchFiltersLabel">Search Filters</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label class="mb-1">Delivery Method</label>
            <v-select
              v-model="filter.deliveryMethod"
              class="bg-white rounded"
              :options="optionsDeliveryMethod"
            />
          </div>
          <div class="mb-3">
            <label class="mb-1">Category</label>
            <v-select
              v-model="filter.category"
              class="bg-white rounded"
              :options="optionsCategory"
            />
          </div>
          <div class="mb-3">
            <label class="mb-1">Use Case</label>
            <v-select
              v-model="filter.useCase"
              class="bg-white rounded"
              :options="optionsUseCase"
            />
          </div>
          <div class="mb-3">
            <label class="mb-1">Status</label>
            <v-select
              v-model="filter.status"
              class="bg-white rounded"
              :options="optionsStatus"
            />
          </div>
          <div class="mb-3">
            <label class="mb-1">No. of Days Past</label>
            <v-select
              v-model="filter.days"
              class="bg-white rounded"
              :options="optionsDays"
            />
          </div>
          <button type="button" class="btn btn-light" @click="clearFilters">
            Clear Filters
          </button>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">
            Close
          </button>
          <button class="btn btn-secondary" data-bs-dismiss="modal" type="button" @click="save">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const emit = defineEmits(['setFilter'])
const props = defineProps(['filters'])
const transactions = computed(() => store.state.transactions.tempData)

const optionsCategory = computed(() => {
  if (transactions.value) {
    return [...new Set(transactions.value.map(it => it.category.name).filter(it => it !== undefined))]
  }

  return []
})

const optionsStatus = computed(() => {
  if (transactions.value) {
    return [...new Set(transactions.value.map(it => it.status).filter(it => it !== undefined))]
  }

  return []
})

const optionsUseCase = computed(() => {
  if (transactions.value) {
    return [...new Set(transactions.value.map(it => {
      if (it.category.name === filter.value.category) {
        return it.useCase.name
      }
    }).filter(it => it !== undefined))]
  }

  return []
})

const optionsDays = ref([1, 7, 14, 30, 60])
const optionsDeliveryMethod = ref(['Email', 'Mail', 'SMS'])

const filter = ref(props.filters)

function clearFilters () {
  filter.value = {
    deliveryMethod: null,
    category: '',
    days: null,
    status: null,
    useCase: null
  }
}

function save () {
  emit('setFilter', filter.value)
}
</script>

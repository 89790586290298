<template>
  <div class="messages">
    <div class="mb-3"><strong class="text-primary">History of Messages</strong></div>
    <LoadingScreen v-if="!transactions" />
    <template v-else>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="messages-tab" data-bs-toggle="tab" data-bs-target="#messages" type="button" role="tab" aria-controls="messages" aria-selected="true">
            Messages
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="receivers-tab" data-bs-toggle="tab" data-bs-target="#receivers" type="button" role="tab" aria-controls="receivers" aria-selected="false">
            Receivers
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active pt-3" id="messages" role="tabpanel" aria-labelledby="messages-tab">
          <MessageList :key="transactions" :transactions="transactions" />
        </div>
        <div class="tab-pane fade pt-3" id="receivers" role="tabpanel" aria-labelledby="receivers-tab">
          <TabReceivers />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import MessageList from '@/components/MessageList.vue'
import TabReceivers from '@/components/AccountMessages/TabReceivers.vue'

const store = useStore()

const transactions = computed(() => {
  return store.state.transactions.tempData &&
    store.state.transactions.tempData.filter(it => it.status === 'Delivered')
})
</script>

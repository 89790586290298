<template>
  <LoadingScreen v-if="isLoading" />
  <template v-if="mode === 'resetPassword'">
    <div class="mb-3">
      <strong class="text-primary">Set New Password</strong>
    </div>
    <form v-if="!isSuccess" class="mb-3" @submit.prevent="setPassword">
      <input
        v-model="newPassword"
        class="form-control mb-3"
        placeholder="New password"
        required
        type="password"
      >
      <button class="btn btn-primary" type="submit">Set Password</button>
    </form>
    <div v-if="isSuccess" class="alert alert-success">
      {{ isSuccess }}
      <router-link class="btn btn-primary" :to="{ name: 'Login', params: { email: userEmail } }">
        Go to Login
      </router-link>
    </div>
    <div v-if="isError" class="alert alert-danger">
      {{ isError }}
    </div>
  </template>
</template>

<script setup>
import { getAuth, confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth'
import { onMounted, ref } from 'vue'

const auth = getAuth()
const code = ref(null)
const lang = ref(null)
const isSuccess = ref(false)
const isLoading = ref(false)
const isError = ref(false)
const newPassword = ref('')
const userEmail = ref('')
const mode = ref(null)

function handleErrorPassword (code) {
  switch (code) {
    case 'auth/weak-password':
      return 'Password should be at least 6 characters.'
    default:
      return 'Something went wrong with resetting password.'
  }
}

function setPassword () {
  isError.value = false
  isLoading.value = true
  const url = new URL(window.location.href)
  code.value = url.searchParams.get('oobCode')
  lang.value = url.searchParams.get('lang')

  verifyPasswordResetCode(auth, code.value)
    .then((email) => {
      userEmail.value = email

      confirmPasswordReset(auth, code.value, newPassword.value)
        .then(() => {
          isLoading.value = false
          isSuccess.value = 'Reset password successful.'
        })
        .catch((err) => {
          isLoading.value = false
          isError.value = handleErrorPassword(err.code)
        })
      })
    .catch(() => {
      isError.value = 'Link is invalid or expired. Please reset password again.'
      isLoading.value = false
    })
}

onMounted(() => {
  const url = new URL(window.location.href)
  mode.value = url.searchParams.get('mode')
})
</script>

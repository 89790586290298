/* eslint-disable */
import { initializeApp } from 'firebase/app'
import firebaseConfig from './firebaseConfig'

export const app = initializeApp(firebaseConfig)





/*
  Comment everything below to connect to PROD database
*/
// import { getApp } from 'firebase/app'

// import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
// const functions = getFunctions(getApp())
// connectFunctionsEmulator(functions, 'localhost', 5001)

// import { getDatabase, connectDatabaseEmulator } from 'firebase/database'
// const db = getDatabase()
// connectDatabaseEmulator(db, 'localhost', 9000)

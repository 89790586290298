import {
  EmailAuthProvider,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
  updateEmail
} from 'firebase/auth'
import { get, getDatabase, ref, set, onValue } from 'firebase/database'
import router from '@/router'

const auth = getAuth()
const db = getDatabase()
const dbRef = ref(db, 'account')

import { getFunctions, httpsCallable } from 'firebase/functions'
const functions = getFunctions()

export default {
  namespaced: true,
  state: () => ({
    currentUser: null
  }),
  getters: {},
  mutations: {
    SET_CURRENT_USER (state, payload) {
      console.log('SET_CURRENT_USER: ', payload)
      state.currentUser = payload

      if (!payload) {
        localStorage.removeItem('sendamessage_email')
      } else {
        localStorage.setItem('sendamessage_email', payload.email)
      }
    }
  },
  actions: {
    getCurrentUser ({ commit, dispatch }) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          onValue(dbRef, (snapshot) => {
            const users = snapshot.val()
            commit('SET_CURRENT_USER', users[user.uid])
            dispatch('categories/get', null, { root: true })
            dispatch('help/get', null, { root: true })
            dispatch('settings/get', null, { root: true })
            dispatch('sequences/get', null, { root: true })
            dispatch('templates/get', null, { root: true })
            dispatch('topups/get', null, { root: true })
            dispatch('transactions/get', null, { root: true })
          })
        } else {
          commit('SET_CURRENT_USER', null)
        }
      })
    },
    async resetPassword (_, email) {
      return await sendPasswordResetEmail(auth, email)
        .then(() => {
          return { success: true }
        })
        .catch((error) => {
          return { success: false, code: error.code }
        })
    },
    async signin (_, data) {
      const snapshot = await get(dbRef)

      if (!snapshot ||  (snapshot && !snapshot.val())) {
        return { code: '', status: false }
      }

      const users = Object.values(snapshot.val())
      const isUserInDatabase = users.some(user => user.email === data.email)

      if (!isUserInDatabase) {
        return { code: 'auth/user-not-found', status: false }
      }

      return await signInWithEmailAndPassword(auth, data.email, data.password)
        .then(() => {
          localStorage.setItem('sendamessage_email', data.email)

          // set delay for route.beforeEach
          setTimeout(() => {
            router.push({ name: 'Messages' })
          }, 500)

          return { status: true }
        })
        .catch((error) => {
          return { code: error.code, status: false }
        })
    },
    async signinWithGoogle () {
      const provider = new GoogleAuthProvider()
      return await signInWithPopup(auth, provider)
        .then((result) => {
          const id = result.user.uid

          onValue(dbRef, (snapshot) => {
            const users = snapshot.val()

            if (!users[id]) {
              console.log(result)
              set(ref(db, 'account/' + id), {
                avatar: '',
                country: '',
                createdAt: new Date().toISOString(),
                credits: 0,
                creditsTotal: 0,
                creditsUsed: 0,
                email: result.user.email,
                firstName: result.user.displayName,
                gender: '',
                isAdmin: false,
                lastName: '',
                name: result.user.displayName,
                uniqueID: id,
              })
            }
          })

          localStorage.setItem('sendamessage_email', result.user.email)

          // set delay for route.beforeEach
          setTimeout(() => {
            router.push({ name: 'Messages' })
          }, 500)

          return { status: true }
        }).catch((error) => {
          return { code: error.code, status: false }
        })
    },
    async signout ({ commit }) {
      await signOut(auth)
        .then(() => {
          commit('SET_CURRENT_USER', null)
          router.push({ name: 'Login' })
        })
        .catch((error) => {
          console.error('Signout Error: ', error)
        })
    },
    async updateEmail ({ dispatch, state }, data) {
      try {
        const newEmail = data.email

        const credential = EmailAuthProvider.credential(
          auth.currentUser.email,
          data.password
        )

        // Reauthenticate the user before updating the email
        await reauthenticateWithCredential(auth.currentUser, credential)

        // Update the email after successful reauthentication
        await updateEmail(auth.currentUser, newEmail)

        // Update the email in our database
        await dispatch('updateProfile', { ...state.currentUser, email: newEmail })

        return { status: true }
      } catch (error) {
        console.log('updateEmail error: ', error)
        return { message: error.message, status: false }
      }
    },
    async updatePassword (_, data) {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        data.oldPassword
      )

      return await reauthenticateWithCredential(auth.currentUser, credential)
        .then(async () => {
          return updatePassword(auth.currentUser, data.newPassword)
            .then(() => {
              return { status: true }
            }).catch((error) => {
              return { message: error.message, status: false }
            })
        })
        .catch((error) => {
          return { message: error.message, status: false }
        })
    },
    async updateProfile (_, data) {
      const updateProfile = httpsCallable(functions, `${process.env.VUE_APP_FUNCTIONS}_updateProfile`)

      try {
        const res = await updateProfile(data)
        console.log('updateProfile: ', res.data)
        return res.data
      } catch (error) {
        console.error('Error updating profile:', error)
        throw error
      }
    }
  }
}

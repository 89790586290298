<template>
  <LoadingScreen v-if="isSaving" />
  <form @submit.prevent="saveNewDist">
    <div class="modal fade" id="modalDistNew" tabindex="-1" aria-labelledby="modalDistNewLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalDistNewLabel">Create Distribution List</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="method" class="form-label">Method</label>
              <select v-model="distribution.method" id="method" class="form-select mb-3" required>
                <option value="sms">SMS</option>
                <option value="email">Email</option>
              </select>
            </div>
            <template v-if="distribution.method">
              <div class="mb-3">
                <label for="distName" class="form-label">Distribution List Name</label>
                <input
                  v-model="distribution.name"
                  id="distName"
                  class="form-control"
                  :class="{ 'is-invalid': isDistExist }"
                  required
                  placeholder="Distribution List Name"
                  type="text"
                >
              </div>
              <div class="mb-3" style=" max-height: calc(100vh - 500px); overflow: auto;">
                <div class="d-flex align-items-center gap-2 mb-2">
                  <label class="form-label mb-0">Receivers</label>
                  <VTooltip :triggers="['hover']">
                    <button class="btn btn-success rounded px-1 py-0 text-white" type="button" @click="addRecipient">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="white" height="14" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
                    </button>
                    <template #popper>
                      Add new receiver
                    </template>
                  </VTooltip>
                  <VTooltip :triggers="['hover']">
                    <input type="file" accept=".csv" @change="onFileUpload">
                    <template #popper>
                      Select *.csv file from your device
                    </template>
                  </VTooltip>
                </div>
                <input
                  v-model="distribution.recipients[0]"
                  class="form-control mb-2"
                  :class="{ 'is-invalid': hasDuplicateRecipient(distribution.recipients[0]) || hasInvalidRecipient(distribution.recipients[0]) }"
                  required
                  :placeholder="inputPlaceholder"
                  :type="distribution.method === 'sms' ? 'text' : 'email'"
                  @input="validateInput(0)"
                >
                <div
                  v-for="(recipient, recipientIdx) in distribution.recipients"
                  :key="recipientIdx"
                  class="d-flex align-items-center gap-2 mb-2"
                >
                  <template v-if="recipientIdx !== 0">
                    <input
                      v-model="distribution.recipients[recipientIdx]"
                      class="form-control"
                      :class="{ 'is-invalid': hasDuplicateRecipient(distribution.recipients[recipientIdx]) || hasInvalidRecipient(distribution.recipients[recipientIdx]) }"
                      required
                      :placeholder="inputPlaceholder"
                      :type="distribution.method === 'sms' ? 'text' : 'email'"
                      @input="validateInput(recipientIdx)"
                    >
                    <button class="btn btn-danger rounded px-1 py-0 text-white" type="button" @click="removeRecipient(recipientIdx)">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="white" height="14" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                    </button>
                  </template>
                </div>
              </div>
            </template>
            <div v-if="duplicateRecipients.length > 0" class="alert alert-danger">
              Duplicate receiver: <strong>{{ duplicateRecipients.join(', ') }}</strong>.
            </div>
            <div v-if="invalidRecipients.length > 0" class="alert alert-danger">
              Invalid receiver: <strong>{{ invalidRecipients.join(', ') }}</strong>.
            </div>
            <div v-if="isDistExist" class="alert alert-danger">
              Distribution List name already exists.
            </div>
            <div v-if="isError" class="alert alert-danger">
              Invalid file content. Must be *.csv<br>
              <span data-bs-dismiss="modal">
                <router-link :to="{ name: 'FAQ' }">Click here</router-link>
              </span>
               to learn how.
            </div>
          </div>
          <div class="modal-footer">
            <button id="modalNewDistClose" class="btn btn-secondary" data-bs-dismiss="modal" type="button">
              Close
            </button>
            <button class="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref, onMounted } from 'vue'
import { v4 as uuidv4 } from 'uuid'

const emit = defineEmits(['saveNewDist'])
const props = defineProps(['user'])
const isError = ref(false)
const isSaving = ref(false)
const isDistExist = ref(false)
const duplicateRecipients = ref([])
const invalidRecipients = ref([])

const inputPlaceholder = computed(() => {
  if (distribution.value.method === 'sms') return 'Phone Number'
  if (distribution.value.method === 'email') return 'Email Address'

  return 'Recipient'
})

const distribution = ref({
  name: '',
  recipients: [],
  method: ''
})

function addRecipient () {
  checkDuplicateRecipient()
  checkInvalidRecipient()

  if (duplicateRecipients.value.length > 0) return
  if (invalidRecipients.value.length > 0) return

  distribution.value.recipients.push('')
}

function checkDuplicateRecipient () {
  const recipients = distribution.value.recipients
  duplicateRecipients.value = [...new Set(recipients.filter(it => it.trim() !== '').filter((value, index) => recipients.indexOf(value) !== index))]
}

function checkInvalidRecipient () {
  const recipients = distribution.value.recipients

  if (distribution.value.method === 'email') {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    invalidRecipients.value = recipients.filter(it => it.trim() !== '').filter(it => !emailPattern.test(it))
  }

  // if (distribution.value.method === 'sms') {
  //   const smsPattern = /^\d*$/
  //   invalidRecipients.value = recipients.filter(it => it.trim() !== '').filter(it => !smsPattern.test(it))
  // }
}

function hasDuplicateRecipient (recipient) {
  return duplicateRecipients.value.includes(recipient)
}

function hasInvalidRecipient (recipient) {
  return invalidRecipients.value.includes(recipient)
}

function onFileUpload (event) {
  isSaving.value = true
  isError.value = false
  distribution.value.recipients = []
  const file = event.target.files[0]
  const reader = new FileReader()

  reader.onload = () => {
    const csvData = reader.result.replace(/\r/g, '')
    const lines = csvData.split('\n')
    const headers = lines[0].split(',')

    const data = []
    for (let i = 1; i < lines.length; i++) {
      const values = lines[i].split(',')
      if (values.length === headers.length) {
        const entry = {}
        for (let j = 0; j < headers.length; j++) {
          entry[headers[j].trim()] = values[j].trim()
        }
        data.push(entry)
      }
    }

    if (data) {
      data.forEach(it => {
        it.Recipient && distribution.value.recipients.push(it.Recipient)
      })

      checkDuplicateRecipient()
      checkInvalidRecipient()

      if (distribution.value.recipients.length === 0) {
        isError.value = true
      }

      isSaving.value = false
    }
  }

  reader.readAsText(file)
}

function removeRecipient (recipientIdx) {
  distribution.value.recipients.splice(recipientIdx, 1)
  checkDuplicateRecipient()
  checkInvalidRecipient()
}

function saveNewDist () {
  isDistExist.value = false
  duplicateRecipients.value = []

  if (props.user.distributions) {
    isDistExist.value = props.user.distributions.some(it => it.name === distribution.value.name)
    if (isDistExist.value) return
  }

  checkDuplicateRecipient()
  checkInvalidRecipient()

  if (duplicateRecipients.value.length > 0) return
  if (invalidRecipients.value.length > 0) return

  const uuid = uuidv4()

  emit('saveNewDist', {
    ...distribution.value,
    id: uuid
  })
}

function validateInput (idx) {
  checkDuplicateRecipient()

  if (distribution.value.method === 'sms') {
    const isValid = /^\d*$/.test(distribution.value.recipients[idx]) // Allow only digits

    if (!isValid) {
      distribution.value.recipients[idx] = distribution.value.recipients[idx].replace(/\D/g, '') // Remove non-digit characters
    }
  } else {
    checkInvalidRecipient()
  }
}

onMounted(() => {
  const myModalElement = document.getElementById('modalDistNew')
  myModalElement.addEventListener('hidden.bs.modal', () => {
    isError.value = false
    duplicateRecipients.value = []
    invalidRecipients.value = []

    distribution.value = {
      name: '',
      recipients: [],
      method: ''
    }
  })
})
</script>

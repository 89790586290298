<template>
  <div class="tab-messages d-flex justify-content-between flex-wrap border-bottom py-3">
    <div>
      <div class="text-muted text-center">Today</div>
      <div class="tab-messages__chart">
        <div class="tab-messages__chart-label text-success fw-bold">
          {{ thisDay(transactions) }}
        </div>
        <DoughnutChart
          :chartData="doughnutChartData(
            thisDay(transactions),
            transactions,
            '#198754'
          )"
        />
      </div>
    </div>
    <div>
      <div class="text-muted text-center">This Week</div>
      <div class="tab-messages__chart">
        <div class="tab-messages__chart-label text-danger fw-bold">
          {{ thisWeek(transactions) }}
        </div>
        <DoughnutChart
          :chartData="doughnutChartData(
            thisWeek(transactions),
            transactions,
            '#dc3545'
          )"
        />
      </div>
    </div>
    <div>
      <div class="text-muted text-center">This Month</div>
      <div class="tab-messages__chart">
        <div class="tab-messages__chart-label text-primary fw-bold">
          {{ thisMonth(transactions) }}
        </div>
        <DoughnutChart
          :chartData="doughnutChartData(
            thisMonth(transactions),
            transactions,
            '#0d6efd'
          )"
        />
      </div>
    </div>
  </div>
  <div class="py-3 d-flex justify-content-between">
    <router-link class="btn btn-secondary w-100 rounded-pill" :to="{ name: 'AccountMessages' }">
      View Message History
    </router-link>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { DoughnutChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

defineProps(['transactions'])

function thisDay (trans) {
  const date = new Date()
  date.setHours(0,0,0,0)
  const subtractDate = date.setDate(date.getDate() - 0)
  const fromDate = new Date(subtractDate).toISOString()

  const date2 = new Date()
  date2.setHours(0,0,0,0)
  const subtractDate2 = date2.setDate(date2.getDate() + 1)
  const toDate = new Date(subtractDate2).toISOString()

  return trans?.filter((it) => {
    return it.createdAt >= fromDate && it.createdAt <= toDate
  }).length
}

function thisWeek (trans) {
  const date = new Date()
  date.setHours(0,0,0,0)
  const subtractDate = date.setDate(date.getDate() - 7)
  const fromDate = new Date(subtractDate).toISOString()

  const date2 = new Date()
  date2.setHours(0,0,0,0)
  const subtractDate2 = date2.setDate(date2.getDate() - 0)
  const toDate = new Date(subtractDate2).toISOString()

  return trans?.filter((it) => {
    return it.createdAt >= fromDate && it.createdAt <= toDate
  }).length
}

function thisMonth (trans) {
  const date = new Date()
  date.setHours(0,0,0,0)
  const subtractDate = date.setDate(date.getDate() - 30)
  const fromDate = new Date(subtractDate).toISOString()

  const date2 = new Date()
  date2.setHours(0,0,0,0)
  const subtractDate2 = date2.setDate(date2.getDate() - 0)
  const toDate = new Date(subtractDate2).toISOString()

  return trans?.filter((it) => {
    return it.createdAt >= fromDate && it.createdAt <= toDate
  }).length
}

function doughnutChartData (transCount, trans, color) {
  const transTotalCount = trans && thisMonth(trans)
  const diff = transTotalCount - transCount

  return {
    labels: [],
    datasets: [
      {
        data: [transCount, diff],
        backgroundColor: [color, '#e9e9e9'],
      }
    ]
  }
}
</script>

<style lang="scss" scoped>
.tab-messages {
  &__chart {
    position: relative;
    width: 100px;

    &-label {
      align-items: center;
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      top: 10px;
    }
  }
}
</style>
